import React from 'react';
import { Bar } from 'react-chartjs-2';
import zoomPlugin from 'chartjs-plugin-zoom';
import { Chart } from 'chart.js';

Chart.register(zoomPlugin);

const BarTimeValue = ({ chartData, title }) => {
    const decimation = {
        enabled: true,
        algorithm: 'min-max',
      };
      const options =  {
          responsive: true,
          plugins: {
            legend: {
              position: 'top',
            },
            title: {
              display: true,
              text: title
            },
            zoom: {
              pan: {
                enabled: true,
                mode: "x", // Enable panning in the x-direction
              },
              zoom: {
                wheel: {
                  enabled: true, // Enable zooming with the mouse wheel
                },
                pinch: {
                  enabled: true, // Enable zooming with pinch gesture
                },
                drag:{
                  enabled: true,
                },
                mode: "x", // Zoom in the x-direction
              }
            }
          },
          scales: {
            x: {
              type: "category", // Use the formatted dates as categories
              title: {
                display: true,
                text: "Timestamps",
              },
              ticks: {
                  maxTicksLimit: 10, // Limits the number of labels shown on the x-axis
              },
            },
            y: {
              type: "linear",
              title: {
                display: true,
                text: "Completeness (%)",
              },
              beginAtZero: true,
            },
          }
      };
        console.log(chartData, options)
    return (
        <div>
            <Bar data={chartData} options={options} />
        </div>
    );
};

export default BarTimeValue;