import axios from "axios";

// import config from "../../../../config";
import config from "../../../config";

const API_URL = config.TRAFFIC;

export const routeFlowsData = async (endpoint) => {
  try {
    const response = await axios.get(`${API_URL}${endpoint.endPoint}`, {
      headers: { "Accept-Profile": "traffic_monitoring" },
    });

    const jsonData = response.data;
    // Step 1: Extract Labels (Location Name)
    const labels = jsonData.map((entry) => entry.name);

    // Step 2: Extract Values Based on Available Types
    const inValues = endpoint.type.includes("in")
      ? jsonData.map((entry) => entry.value_in ?? 0)
      : [];
    const outValues = endpoint.type.includes("out")
      ? jsonData.map((entry) => entry.value_out ?? 0)
      : [];

    // Step 3: Construct Datasets
    const datasets = [];

    if (inValues.length > 0) {
      datasets.push({
        label: "Incoming",
        data: inValues,
        backgroundColor: "rgba(75, 192, 192, 0.5)", // Always pink for outgoing
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 2,
      });
    }

    if (outValues.length > 0) {
      datasets.push({
        label: "Outgoing",
        data: outValues,
        backgroundColor: "rgba(255, 99, 132, 0.5)", // Always pink for outgoing
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 2,
      });
    }

    // Step 4: Construct Chart Options (Include Dynamic Title)
    const options = {
      responsive: true,
      maintainAspectRatio: false, // This allows chart to resize properly
      plugins: {
        legend: { position: "top" },
        title: { display: true, text: `Route Flows - ${endpoint.name}`, font:{size: 16}, color:'#fff' }, // Dynamically set title
      },
      scales: {
        y: { beginAtZero: true },
      },
    };

    return {
      id: endpoint.name, // Identify dataset by endpoint name
      data: { labels, datasets },
      options,
    };
  } catch (error) {
    console.error(`Error fetching data from ${endpoint.name}:`, error);
    return null; // Return null if fetch fails
  }
};
