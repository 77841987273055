// @mui material components
import { Children, useEffect, useState } from "react";
import { Card, CircularProgress, Grid } from "@mui/material";
// Otis Admin PRO React components
import Icon from "@mui/material/Icon";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import EventsMap from "./components/eventsMap";
import EventList from "./components/eventsList";
import MapComponent from "./components/MapComponent";
import useEventsData from "./data/useEventsData";
import useFieldsData from "../create/components/EventForm/data/useFieldsData";


function filterSchemaUIData(filterFields) {
  if (!filterFields) return;

  const statusTree = {
    label: "Status", // Top-level filter category
    value: "status", // Key to identify the filter
    children: filterFields.statuses.map((status) => ({
      label: status.id === 1 ? "active" : status.name, // Change label if id is 1
      value: `status-${status.id}`,
    })),
  };

  const typeTree = {
    label: "Type",
    value: "type",
    children: filterFields.typeOfEvents.map((type) => ({
      label: type.name,
      value: `type-${type.id}`,
    })),
  };

  // Perpare categories and nest subcategories inside them
  const categoryTree = {
    label: "Category",
    value: "category",
    children: filterFields.categories.map((category) => ({
      label: category.name,
      value: `category-${category.id}`,
      children: filterFields.subCategories
        .filter((sub) => sub.category_id === category.id)
        .map((sub) => ({
          label: sub.name,
          value: `subcategory-${sub.id}`,
        })),
    })),
  };

  const sourceTree = {
    label: "Source",
    value: "source",
    children: filterFields.sourceOfEvents.map((source) => ({
      label: source.name,
      value: `source-${source.id}`,
    })),
  };

  return [statusTree, typeTree, categoryTree, sourceTree];
}

function Events() {
  const { events, loading, error } = useEventsData();
  const { fields: filterFields, loading: filterLoading, error: filterError } = useFieldsData(); // Fetch fields data


  // console.log("events index ", events);

  const [displayedEvents, setDisplayedEvents] = useState([]); // state for event where displayed on list and map
  // const [originalEvents, setOriginalEvents] = useState(events); // State for original event
  const [selectedEvent, setSelectedEvent] = useState(null); // State for selected event (when I click an event on list I want to show it on map)
  const [deleteEvent, setDeleteEvent] = useState(null); // When I delete an event from the list I want to remove it from the list without calling event
  const [updateEvent, setUpdateEvent] = useState(null); // When I update an event from the list I want to update the list without calling event
  const [refreshList, setRefreshList] = useState(false); 
  const [clearMap, setClearMap] = useState(false); // Track if map should be cleared
  const [filterTreeData, setFilterTreeData] = useState([]); // ✅ Store processed filter data

  // Take filters for UI 
  useEffect(() => {  
    if (!filterFields || Object.keys(filterFields).length === 0) {
      // console.log("Skipping function: filterFields is missing or empty");
      return;
    }
    const processedFilters = filterSchemaUIData(filterFields);
    setFilterTreeData(processedFilters);
  }, [filterFields]);
  

  // init the displayed Events
  useEffect(() => {
    if (!events || events.length === 0) return; // Ensure `events` exist before filtering
    // console.log("events", events)

    setDisplayedEvents(
      events.filter((event) => event.check_status.id === 1)
    );
    // setOriginalEvents(events);
  }, [events, refreshList]);

  const resetMap = () => {
    setSelectedEvent(null);
    setClearMap(false); // Reset map state after clearing
  };

  if (loading) {
    return (
      <DashboardLayout>
        <MDBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="80vh"
        >
          <CircularProgress size={50} color="primary" />
        </MDBox>
      </DashboardLayout>
    );
  }

  if (error) {
    return (
      <DashboardLayout>
        <MDBox p={3}>
          <MDTypography variant="h6" color="error" gutterBottom>
            Error: {error}
          </MDTypography>
        </MDBox>
      </DashboardLayout>
    );
  }

  // console.log("displayedEvents", displayedEvents);

  return (
    <DashboardLayout>
      <Card>
        <Grid container>
          <Grid item xs={12} md={3} pt={3} px={3}>
            <EventList
              noGutter={false}
              displayedEvents={displayedEvents}
              setDisplayedEvents={setDisplayedEvents}
              // originalEvents={originalEvents}
              // setOriginalEvents={setOriginalEvents}
              selectedEvent={selectedEvent}
              setSelectedEvent={setSelectedEvent}
              deleteEvent={deleteEvent}
              setDeleteEvent={setDeleteEvent}
              updateEvent={updateEvent}
              setUpdateEvent={setUpdateEvent}
              filterTreeData={filterTreeData} // Pass processed filter tree
            />
          </Grid>
          <Grid item xs={12} md={9}>
            <MapComponent
              events={displayedEvents}
              selectedEvent={selectedEvent}
              clearMap={clearMap}
              setClearMap={resetMap} // Reset map state when cleared
            />
          </Grid>
        </Grid>
      </Card>
    </DashboardLayout>
  );
}

export default Events;
