import { useEffect, useRef } from "react";
import "mapbox-gl/dist/mapbox-gl.css";
import mapboxgl from "mapbox-gl";
import config from "../../../../config";

mapboxgl.accessToken = config.MAPBOX_ACCESS_TOKEN;

function MapComponent({ events, selectedEvent, clearMap, setClearMap }) {
  const mapContainerRef = useRef(null);
  const mapRef = useRef(null);
  const hoverPopupRef = useRef(
    new mapboxgl.Popup({ closeButton: false, closeOnClick: false })
  );
  const clickPopupRef = useRef(
    new mapboxgl.Popup({ closeButton: true, closeOnClick: true })
  );

  // Helper function to set cursor style
  const setCursor = (style) => {
    if (mapRef.current) {
      mapRef.current.getCanvas().style.cursor = style;
    }
  };

  // Helper function to handle hover interaction
  const onHover = (layerId) => {
    if (!mapRef.current) return;

    mapRef.current.on("mousemove", layerId, (e) => {
      setCursor("pointer");

      const feature = e.features[0];
      if (feature) {
        try {
          console.log("onHover Event", feature.properties);
          const category = JSON.parse(feature.properties.category);

          const source = feature.properties.source
            ? JSON.parse(feature.properties.source)
            : { name: "" };

          const showMessage = `<strong>${category.name}</strong><br /> ${source.name}`;

          hoverPopupRef.current
            .setLngLat(e.lngLat)
            .setHTML(showMessage)
            .addTo(mapRef.current);
        } catch (error) {
          console.error("Error parsing category properties:", error);
        }
      }
    });

    mapRef.current.on("mouseleave", layerId, () => {
      setCursor("");
      hoverPopupRef.current.remove();
    });
  };

  // Helper function to handle click interaction
  const onClick = (layerId) => {
    if (!mapRef.current) return;

    mapRef.current.on("click", layerId, (e) => {
      const feature = e.features[0];
      if (feature) {
        try {
          // Ensure JSON parsing is safe
          const category = feature.properties.category
            ? JSON.parse(feature.properties.category)
            : { name: "" };

          const type = feature.properties.type_of_event
            ? JSON.parse(feature.properties.type_of_event)
            : { name: "" };

          const status = feature.properties.check_status
            ? JSON.parse(feature.properties.check_status)
            : { name: "" };

          const clickEvent = { category, type, status };
          const showMessage = `<div>
          <h6>${clickEvent.category.name}</h6>        
          <p>${clickEvent.status.name}</p>
          <p>${clickEvent.type.name}</p>
          </div>`;

          clickPopupRef.current
            .setLngLat(e.lngLat)
            .setHTML(showMessage)
            .addTo(mapRef.current);

          // Modify the close button directly
          setTimeout(() => {
            const closeButton = document.querySelector(
              ".mapboxgl-popup-close-button"
            );
            if (closeButton) {
              closeButton.style.fontSize = "1.3rem";
            }
          }, 100);
        } catch (error) {
          console.error("Error parsing category properties:", error);
        }
      }
    });
  };

  // Initialize the map when the component mounts
  useEffect(() => {
    if (!mapRef.current) {
      mapRef.current = new mapboxgl.Map({
        container: mapContainerRef.current,
        style: "mapbox://styles/mapbox/dark-v11",
        center: [22.9527, 40.6253],
        zoom: 12,
      });
    }
  }, []);

  // Fly to the selected event & change layer color when 'selectedEvent' is updated
  useEffect(() => {
    // if (!mapRef.current || !selectedEvent) return;
    if (!mapRef.current) return;

    if (!selectedEvent || !selectedEvent.id || !selectedEvent.geometry) {
      return;
    }

    const selectedEventId = selectedEvent?.id ?? null; // Use `null` if `undefined`

    const { geometry } = selectedEvent;
    if (!geometry || !geometry.coordinates) return;

    // Fly to selected event
    if (geometry.type === "Point") {
      mapRef.current.flyTo({
        center: geometry.coordinates[0], // Fly to point coordinates
        zoom: 14,
        speed: 1.2, // Increase speed for smooth animation
      });
    } else if (geometry.type === "LineString") {
      const bounds = new mapboxgl.LngLatBounds();
      geometry.coordinates.forEach((coord) => bounds.extend(coord));

      mapRef.current.fitBounds(bounds, { padding: 20, duration: 1000 });
    }

    // Change color of selected event in layers
    if (mapRef.current.getLayer("events-points")) {
      mapRef.current.setPaintProperty("events-points", "circle-color", [
        "case",
        ["==", ["get", "eventId"], selectedEventId],
        "#ff0000", // Highlight selected event in Red
        "#007cbf", // Default blue for others
      ]);
    }

    if (mapRef.current.getLayer("events-lines")) {
      mapRef.current.setPaintProperty("events-lines", "line-color", [
        "case",
        ["==", ["get", "eventId"], selectedEventId],
        "#ff0000", // Highlight selected event in Red
        "#007cbf", // Default blue for others
      ]);
    }
  }, [selectedEvent]);

  // Show events on the map
  useEffect(() => {
    if (!mapRef.current || !events || events.length === 0 || clearMap) return;

    if (!mapRef.current.isStyleLoaded()) {
      mapRef.current.once("load", () => updateMap(events));
    } else {
      updateMap(events);
    }
  }, [events, clearMap]); // Depend on `clearMap` to reset when needed

  const updateMap = (eventsData) => {
    // Remove existing layers & sources before adding new ones
    removeAllLayersAndSources();

    const geojsonData = {
      type: "FeatureCollection",
      features: eventsData
        .filter((event) => event.geometry && event.geometry.coordinates)
        .map((event, index) => {
          const geometry =
            event.geometry.type === "Point"
              ? { type: "Point", coordinates: event.geometry.coordinates[0] }
              : event.geometry;

          return {
            type: "Feature",
            id: `event-${index}`,
            geometry,
            properties: { ...event, eventId: event.id },
          };
        }),
    };

    if (!mapRef.current.getSource("events-source")) {
      mapRef.current.addSource("events-source", {
        type: "geojson",
        data: geojsonData,
      });
    } else {
      mapRef.current.getSource("events-source").setData(geojsonData);
    }

    const selectedEventId = selectedEvent?.id ?? null;

    // Add a layer for points
    if (!mapRef.current.getLayer("events-points")) {
      mapRef.current.addLayer({
        id: "events-points",
        type: "circle",
        source: "events-source",
        filter: ["==", ["geometry-type"], "Point"],
        paint: {
          "circle-radius": 8,
          "circle-color": [
            "case",
            [
              "==",
              ["get", "eventId"],
              selectedEventId && selectedEventId !== null
                ? selectedEventId
                : false,
            ],
            "#ff0000", // Highlight selected event in RED
            "#007cbf", // Default blue for non-selected events
          ],
          "circle-opacity": 0.8,
        },
      });

      onHover("events-points");
      onClick("events-points");
    }

    // Add a layer for lines
    if (!mapRef.current.getLayer("events-lines")) {
      mapRef.current.addLayer({
        id: "events-lines",
        type: "line",
        source: "events-source",
        filter: ["==", ["geometry-type"], "LineString"],
        paint: {
          "line-color": [
            "case",
            [
              "==",
              ["get", "eventId"],
              selectedEventId && selectedEventId !== null
                ? selectedEventId
                : false,
            ],
            "#ff0000", // Highlight selected event in RED
            "#007cbf", // Default blue for non-selected events
          ],
          "line-width": 3,
        },
      });

      onHover("events-lines");
      onClick("events-lines");
    }

    // Fit the map only if there is a single event**
    // if (geojsonData.features.length === 1) {
    //   const bounds = new mapboxgl.LngLatBounds();
    //   const feature = geojsonData.features[0];

    //   if (feature.geometry.type === "Point") {
    //     bounds.extend(feature.geometry.coordinates);
    //   } else if (feature.geometry.type === "LineString") {
    //     feature.geometry.coordinates.forEach((coord) => bounds.extend(coord));
    //   }

    //   if (!bounds.isEmpty()) {
    //     mapRef.current.fitBounds(bounds, { padding: 20, duration: 1000 });
    //   }
    // }
  };

  // Function to remove all layers and sources
  const removeAllLayersAndSources = () => {
    if (!mapRef.current) return;

    if (mapRef.current.getLayer("events-points")) {
      mapRef.current.removeLayer("events-points");
    }
    if (mapRef.current.getLayer("events-lines")) {
      mapRef.current.removeLayer("events-lines");
    }
    if (mapRef.current.getSource("events-source")) {
      mapRef.current.removeSource("events-source");
    }

    if (hoverPopupRef.current) hoverPopupRef.current.remove();
    if (clickPopupRef.current) clickPopupRef.current.remove();
  };

  // Handle "Clear Layers" button click
  const handleClearLayers = () => {
    removeAllLayersAndSources();
    // Reset the map center and zoom
    if (mapRef.current) {
      mapRef.current.flyTo({
        center: [22.9527, 40.6253],
        zoom: 12,
      });
    }

    setClearMap(true); // Notify Events.js that map is cleared
  };

  // console.log("MapComponent selected", selectedEvent);
  return (
    <div style={{ position: "relative", width: "100%", height: "90vh" }}>
      <div
        ref={mapContainerRef}
        style={{
          width: "100%",
          height: "100%",
        }}
      />

      {/* Button for clearing layers */}
      {/* <div style={{ position: "absolute", top: 10, left: 10, zIndex: 1000 }}>
        <MDButton color="secondary" onClick={handleClearLayers}>
          Clear Map
        </MDButton>
      </div> */}
    </div>
  );
}

export default MapComponent;
