import ReportsOverviewTable from "./ReportsOverviewTable";
import { CircularProgress } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import { useNavigate } from "react-router-dom";

function Reports({userId}) {
  const navigate = useNavigate();
    const { tableData, loading, error } = ReportsOverviewTable(userId);
  
    if (loading) {
      return (
        <MDBox p={3}>
          <MDTypography variant="h6" fontWeight="medium" gutterBottom>
            <CircularProgress />
          </MDTypography>
        </MDBox>
      );
    }
  
    if (error) {
      return (
        <MDBox p={3}>
          <MDTypography variant="h6" color="error" gutterBottom>
            Error: {error}
          </MDTypography>
        </MDBox>
      );
    }
  
    return (
      <div className="diagramContainer">
        <MDTypography variant="h6" fontWeight="medium" gutterBottom>
          Reports List
        </MDTypography>
        <div className="scrollable-100">
          <DataTable
            table={tableData}
            onRowClick={(row) => {
              console.log("Row clicked:", row);
              navigate("/trajectories/result", { state: { rowData: row } });
         
            }}
            canSearch
          />
        </div>
      </div>
    );
  }
  
  
  export default Reports;