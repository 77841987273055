import useDataQualityData from "../../data/useDataQualityData";

const useDataQualityDetectorsOverviewTable = (params) => {
  const { data, loading, error } = useDataQualityData(params);

  // Define the table headers
  const columns = [
    { Header: "Id", accessor: "detector_id" },
    { Header: "Date", accessor: "date" },
    { Header: "Intersection", accessor: "intersection" },
    { Header: "Vendor", accessor: "vendor" },
    { Header: "Flow", accessor: "flow" },
    { Header: "Link", accessor: "link" },
    { Header: "Detector", accessor: "detector" },
    { Header: "Expected records", accessor: "expected_records" },
    { Header: "Retrieved records", accessor: "retrieved_records" },
    { Header: "Completeness %", accessor: "percentage_completeness" },
    { Header: "Invalid count", accessor: "invalid_count" },
    { Header: "Invalid %", accessor: "percentage_invalid" },
    { Header: "Flow 0 count", accessor: "zero_flow_count" },
    { Header: "Flow 0 %", accessor: "percentage_zeroes" },
  ];

  function filterByVendor(value) {
    const upperValue = value.toUpperCase();
    if (upperValue === "YUNEX") {
      return data.filter(item => item.vendor.toUpperCase() === "SIEMENS");
    }
    return data.filter(item => item.vendor.toUpperCase() === upperValue);
  }
  function filterByDetector(value) {
    return data.filter(item => item.detector_id === value);
  }
  
  
  let showData = [];
  if (params.selectedDetectors.length > 0) {
    showData = filterByDetector(params.selectedDetectors[0].id);
  } else if(params.location === "Network"){
    showData = filterByVendor(params.detector);
  }else {
    showData = data;
  }

  const rows = showData.map((item, index) => ({
    detector_id: item.detector_id || "Unknown",
    date: item.report_date,
    intersection: item.intersection || "Unknown",
    vendor: item.vendor || "Unknown",
    flow: item.flow || "Unknown",
    link: item.link || "Unknown",
    detector: item.detector || "Unknown",
    expected_records: item.expected_records || "0",
    retrieved_records: item.retrieved_records || "0",
    percentage_completeness: item.percentage_completeness || "0",
    invalid_count: item.invalid_count || "0",
    percentage_invalid: item.percentage_invalid || "0",
    zero_flow_count: item.zero_flow_count || "0",
    percentage_zeroes: item.percentage_zeroes || "0",
  }));

  // Return the table-ready data
  return {
    tableData: { columns, rows },
    loading,
    error,
  };
};

export default useDataQualityDetectorsOverviewTable;
