import { useEffect, useState } from "react";
import "../../assets/Css/notifications.css";
import CloseIcon from "../../assets/images/Synchro/close_24.svg";
import ReportIcon from "../../assets/images/Synchro/report_icon.svg";
import io from "socket.io-client";
import config  from "../../config";

// console.log("KAFKA",process.env.REACT_APP_KAFKA_CONNECTION);
// const socket =  io(String(process.env.REACT_APP_KAFKA_CONNECTION), {
//     path: "/kafkaservice"
//   });
console.log("KAFKA",config.KAFKA_CONNECTION);

function NotificationsHolder() {

    const [notifications, setNotifications] = useState([]);
    const [topic, setTopic] = useState("travel_time_alerts");

    useEffect(() => {
        const socket =  io(String(config.KAFKA_CONNECTION), {
            path: "/kafkawebproxy",  
            transports: ["websocket", "polling"],  // 🔹 Allow WebSockets first, then fall back to polling
        });
        // Send topic subscription request to the backend
        socket.emit("subscribe", topic);

        // Listen for messages from the topic
        const messageHandler = (msg) => {
            console.log(msg);
            setNotifications((prevMessages) => [...prevMessages, msg]);
        };
        console.log(`🟢 Listening for: message_${topic}`);
        socket.on(`message_${topic}`,  (msg) => {
            setNotifications((prevMessages) => [...prevMessages, msg]);
        });

        socket.on("connect", () => {
            console.log("✅ WebSocket connected:", socket.id);
        });
    
        socket.on("disconnect", () => {
            console.warn("❌ WebSocket disconnected");
        });

        // return () => {
        //     socket.off(`message_${topic}`, messageHandler);
        // };
    }, [topic]);

    const dismissNotification = (position) => {
        console.log(position);
        setNotifications(prevItems => prevItems.filter((_, index) => index !== position));
    };
    return (
        <div className="notifications-container">
            {notifications.map((notif, key) => (
                <div className="notification-card" key={key}>
                    <div className="notification-header">
                        <img src={ReportIcon} />
                        <h4 className="notification-title">{notif.title}</h4>
                        <img src={CloseIcon} className="dismiss-btn" onClick={() => dismissNotification(key)} />
                    </div>
                    <p className="notification-body">{notif.body}</p>
                    <div className="notification-footer">
                        <span className="notification-date">{new Date(notif.date).toLocaleString('el-GR')}</span>
                        <div className="notification-actions">
                            <button className="action-btn">View</button>
                            <button className="action-btn-dissmiss" onClick={() => dismissNotification(key)}>Ignore</button>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default NotificationsHolder;