import React, { useEffect } from 'react';
import DetectorComp from './diagrams/DetectorComp';
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import SpeedTravelComp from './diagrams/SpeedTravelComp';
import PublicTransportComp from './diagrams/PublicTransportComp';


const HandlerComp = ({type , params}) => {

    useEffect(()=>{

    },[type, params])
    function CaseRender() {
        switch (type) {
            case "detectorComp":
                console.log("render Comp", params)
                return <DetectorComp params={params}/>;
            case "speedTravelComp":
                console.log("render Comp", params)
                return <SpeedTravelComp params={params}/>;
            case "publicTransportComp":
                console.log("render Comp", params)
                return <PublicTransportComp params={params}/>;
            default:
                return <></>
        }
    }
    return (
        <Card style={{ marginTop: '3px',padding:'12px', height: '100%' }}>
            <Grid container item xs={12} md={12}>
                { <CaseRender/> }
            </Grid>
        </Card>
    );
};

export default HandlerComp;