import { useEffect, useState } from "react";
import { Card, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import { Bubble } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  Tooltip,
  Legend,
} from "chart.js";
ChartJS.register(LinearScale, PointElement, Tooltip, Legend);

import { speedFlowsMFDData } from "./speedFlowsMFDData";

function MFD() {
  const [dataMFD, setDataMFD] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const data = await speedFlowsMFDData();
      if (data) setDataMFD(data);
      setLoading(false);
    };

    fetchData();
  }, []);

  console.log("dataMFD", dataMFD);

  // ✅ Prevent accessing undefined data
  if (loading) {
    return (
      <DashboardLayout>
        <Card>
          <MDBox p={3} lineHeight={1}>
            <MDTypography variant="h5" fontWeight>
              MFD
            </MDTypography>
            <MDTypography>Loading...</MDTypography>
          </MDBox>
        </Card>
      </DashboardLayout>
    );
  }

  if (!dataMFD.length) {
    return (
      <DashboardLayout>
        <Card>
          <MDBox p={3} lineHeight={1}>
            <MDTypography variant="h5" fontWeight>
              MFD
            </MDTypography>
            <MDTypography>No data available</MDTypography>
          </MDBox>
        </Card>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout>
      <Card>
        <MDBox p={3} lineHeight={1}>
          <MDTypography variant="h5" fontWeight>
            MFD
          </MDTypography>
        </MDBox>
      </Card>

      <Grid container spacing={2} sx={{marginTop: "0.5rem" }}>
        {dataMFD.map((area) => {
          if (!area.speedflow || area.speedflow.length === 0) return null; // Skip empty speedflow data

          const chartData = {
            datasets: [
              {
                label: `Flow for Area ${area.area_id}`,
                data: area.speedflow.map((item) => ({
                  id: item.id,
                  x: item.speed, // ✅ Speed on X-axis
                  y: item.flow, // ✅ Flow on Y-axis
                  r: Math.sqrt(item.flow) / 10, // ✅ Bubble size (scaled)
                })),
                backgroundColor: "rgba(0, 117, 31, 0.8)",
              },
            ],
          };

          const chartOptions = {
            responsive: true,
            plugins: {
              legend: { display: true },
              tooltip: {
                callbacks: {
                  label: function (context) {
                    const idValue = context.raw.id;
                    const xValue = context.raw.x; // Speed
                    const yValue = context.raw.y; // Flow
                    return `[${idValue}]: Speed: ${xValue}, Flow: ${yValue}`;
                  },
                },
              },
            },
            scales: {
              x: {
                title: { display: true, text: "Speed (km/h)" },
                beginAtZero: true,
              },
              y: {
                title: { display: true, text: "Flow (vehicles/hour)" },
                beginAtZero: true,
              },
            },
          };

          return (
            <Grid item xs={12} md={6} lg={4} key={area.area_id}>
              <Card sx={{ padding: "1rem" }}>
                <MDBox sx={{ padding: "0.5rem" }}>
                  <MDTypography variant="h6" textAlign="center">
                    {area.name}
                  </MDTypography>
                  <Bubble data={chartData} options={chartOptions} />
                </MDBox>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </DashboardLayout>
  );
}

export default MFD;
