import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types"; // Import PropTypes for validation
import "mapbox-gl/dist/mapbox-gl.css";
import mapboxgl from "mapbox-gl";
import MDBox from "components/MDBox";
import config  from "../../../../../config";

mapboxgl.accessToken = config.MAPBOX_ACCESS_TOKEN;

function EventsMap({ selectedEvent, justDeleted }) {
 
  const mapContainerRef = useRef(null); // Reference for the map container
  const mapRef = useRef(null); // Reference to store the Mapbox map instance
  const hoverPopupRef = useRef(
    new mapboxgl.Popup({ closeButton: false, closeOnClick: false })
  );
  const clickPopupRef = useRef(
    new mapboxgl.Popup({ closeButton: true, closeOnClick: true })
  );

  // change the cursor
  const setCursor = (style) => {
    mapRef.current.getCanvas().style.cursor = style;
  };

  // Helper function to remove a layer and source
  const resetMap = (id) => {
    if (mapRef.current.getLayer(id)) mapRef.current.removeLayer(id);
    if (mapRef.current.getSource(id)) mapRef.current.removeSource(id);
    if (hoverPopupRef.current) hoverPopupRef.current.remove();
    if (clickPopupRef.current) clickPopupRef.current.remove();
  };

  // Helper function to handle hover layer interaction
  const onHover = (layerId) => {
    mapRef.current.on("mousemove", layerId, (e) => {
      setCursor("pointer");
      const feature = e.features[0];
      if (feature) {
        const category = JSON.parse(feature.properties.category);
        const showMessage = `<strong>${category.name}</strong>`;
        hoverPopupRef.current
          .setLngLat(e.lngLat)
          .setHTML(showMessage)
          .addTo(mapRef.current);
      }
    });

    mapRef.current.on("mouseleave", layerId, () => {
      setCursor("");
      hoverPopupRef.current.remove();
    });
  };

  // Helper function to hanlde click layer interaction
  const onClick = (layerId) => {
    mapRef.current.on("click", layerId, (e) => {
      const feature = e.features[0];
      if (feature) {        
        const category = JSON.parse(feature.properties.category);
        const showMessage = `<div>
        <h6>${category.name}</h6>        
        </div>`;
        clickPopupRef.current
          .setLngLat(e.lngLat)
          .setHTML(showMessage)
          .addTo(mapRef.current);

        // Modify the close button directly
        document.querySelector(".mapboxgl-popup-close-button").style.fontSize =
          "1.3rem";
      }
    });
  };

  // Initialize the map when the component mounts
  useEffect(() => {    

    if (!mapRef.current) {
      mapRef.current = new mapboxgl.Map({
        container: mapContainerRef.current,
        style: "mapbox://styles/mapbox/dark-v11",
        center: [22.9527, 40.6253], // Default center
        zoom: 12,
      });
    }
  }, []);

  // Log the selected event whenever it changes
  useEffect(() => {
    // if (selectedEvent && mapRef.current) {
    if (!selectedEvent || !mapRef.current) {
            
      if (justDeleted) {
        const justID = `layer-${justDeleted.id}`;
        resetMap(justID);
      }

      return;
    }
    const sourceId = `layer-${selectedEvent.id}`;
    // Clean up previous layers and sources
    mapRef.current.getStyle().layers.forEach((layer) => {
      if (layer.id.startsWith("layer-")) {
        resetMap(layer.id);
      }
    });

    // check type of geometry
    const geometry =
      selectedEvent.geometry.type === "Point"
        ? { type: "Point", coordinates: selectedEvent.geometry.coordinates[0] }
        : selectedEvent.geometry;

    // Add a new source and layer
    mapRef.current.addSource(sourceId, {
      type: "geojson",
      data: {
        type: "Feature",
        geometry, // Use geometry from the selectedEvent
        properties: { ...selectedEvent },
      },
    });

    if (selectedEvent.geometry.type === "Point") {
      mapRef.current.addLayer({
        id: sourceId,
        type: "circle",
        source: sourceId,
        paint: {
          "circle-radius": 8,
          "circle-color": "#007cbf",
        },
      });

      // Fly to the point
      mapRef.current.flyTo({
        center: selectedEvent.geometry.coordinates[0],
        zoom: 14,
      });
    } else if (selectedEvent.geometry.type === "LineString") {
      mapRef.current.addLayer({
        id: sourceId,
        type: "line",
        source: sourceId,
        paint: {
          "line-color": "#007cbf",
          "line-width": 5,
        },
      });

      // Fit the map to the bounds of the line
      const bounds = selectedEvent.geometry.coordinates.reduce(
        (bbox, coord) => bbox.extend(coord),
        new mapboxgl.LngLatBounds(
          selectedEvent.geometry.coordinates[0],
          selectedEvent.geometry.coordinates[0]
        )
      );
      mapRef.current.fitBounds(bounds, { padding: 20 });

      // Fly to the point
      mapRef.current.flyTo({
        center: selectedEvent.geometry.coordinates[0],
        zoom: 14,
      });
    }

    onHover(sourceId);
    onClick(sourceId);
  }, [selectedEvent]);

  return (
    <MDBox
      id="map-preview-event"
      ref={mapContainerRef}
      sx={{
        width: "100%",
        height: "90vh",
        my: 2,      
      }}
    />
  );
}

// Add PropTypes validation for selectedEvent
EventsMap.propTypes = {
  selectedEvent: PropTypes.shape({
    id: PropTypes.string.isRequired, // Unique layer ID
    number: PropTypes.number.isRequired,
    type_of_event: PropTypes.string,
    category: PropTypes.string,
    subcategory: PropTypes.string,
    valid_from: PropTypes.string,
    valid_until: PropTypes.string,
    geometry: PropTypes.shape({
      type: PropTypes.string.isRequired, // Geometry type
      coordinates: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.number), // Point coordinates
        PropTypes.arrayOf(PropTypes.array), // LineString coordinates
      ]).isRequired,
    }).isRequired,
    link_id: PropTypes.oneOfType([
      PropTypes.string, // For raw JSON strings or simple strings
      PropTypes.number,
      PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      ), // For parsed arrays
    ]),
    from_node: PropTypes.string,
    to_node: PropTypes.string,
    description: PropTypes.string,
    created: PropTypes.string,
    source: PropTypes.string,
    status: PropTypes.string,
  }),
};

export default EventsMap;
