import React, { useState, useEffect } from 'react';
import Card from "@mui/material/Card";
import axios from "axios";
import { CircularProgress } from "@mui/material";
import Grid from "@mui/material/Grid";
import config from '../../../../config';
import BarTimeValue from '../Charts/BarTimeValue';
import { namedColor, transparentize } from 'Chart/ChartUtils';

const SpeedTravelNetwork = ({params}) => {


    const [chartData, setChartData] = useState()
    const [error, setError] = useState(false)
    const [noRouteData, setNoRouteData] = useState(false)

    useEffect(() => {
        async function fetchData() {
            try {
                let getUrl = "";
                let header = "open_imet";
                if (params.detector === "tomtomfcd") {
                    getUrl = config.ENDPOINTS_URL + `/rpc/th_tomtom_completeness_per_network?start_ts=${params.dates.from}&end_ts=${params.dates.to}`
                    header = "route_monitoring";
                } else if (params.detector === "taxifcd") {
                    getUrl = config.ENDPOINTS_URL + `/rpc/th_fcd_competeness_per_network?start_time=${params.dates.from}&end_time=${params.dates.to}`
                } else {
                    getUrl = config.ENDPOINTS_URL + `/rpc/th_bt_competeness_per_network?start_time=${params.dates.from}&end_time=${params.dates.to}`
                }
                const response = await axios(getUrl, {
                    headers: {
                        "Accept-Profile": header
                    }
                });
                if (!response.data) {
                    setNoRouteData(true)
                } else {
                    setNoRouteData(false);
                    let data;
                    console.log(params)
                    if (params.detector === "tomtomfcd") {
                        data = response.data.map((item) => ({
                            x: new Date(item.ts).toLocaleString('el-GR'), // Format date to be readable
                            y: item.completeness,
                        }));
                    } else if (params.detector === "taxifcd") {
                        data = response.data.map((item) => ({
                            x: new Date(item.ts).toLocaleString('el-GR'), // Format date to be readable
                            y: item.completeness,
                        }));
                    } else {
                        data = response.data.map((item) => ({
                            x: new Date(item.ts).toLocaleString('el-GR'), // Format date to be readable
                            y: item.completeness,
                        }));
                    }
                    const chartD = {
                        datasets: [
                            {
                                label: "Completeness per network",
                                data: data,
                                backgroundColor: transparentize(namedColor(0), 0.5),
                                borderWidth: 0, // Removes the connecting line                            
                                tension: 0.4, // Line smoothing
                                minBarLength: 7, // Ensures bars are visible even when value is 0
                            }
                        ]
                    };
                    setChartData(chartD);
                }
            } catch (error) {
                console.log(error)
            }
        }
        fetchData();
    }, [params])




    return (
        <Card style={{ marginTop: '3px', padding: '12px', height: '100%' }}>
            <Grid container item xs={12} md={12}>
                <Grid container={true} p={2}>
                    {
                        (params.location === "Network") &&
                        <div className='rawContainerDiagrams'>
                            <div className='diagrammHeadTitle'>Completeness per Data Source</div>
                            {chartData ? <div className='detectorDiagramsContainer'><BarTimeValue chartData={chartData} title="Completeness over Time" /></div> : <div className='rawContainerCenteredH'><CircularProgress /></div>}
                        </div>
                    }
                </Grid>
            </Grid>
        </Card>
    );
};

export default SpeedTravelNetwork;