// src/components/StepperNav.js
import React from "react";
import { Stepper, Step, StepLabel, Box } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";

const steps = ["Dates", "Times", "Link", "Summary"];

function StepperNav() {
  const navigate = useNavigate();
  const location = useLocation();
  const currentStep = steps.findIndex((step) => location.pathname.includes(step.toLowerCase()));

  const handleStepChange = (index) => {
    navigate(`/trajectories/${steps[index].toLowerCase()}`);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <Box sx={{ width: "100%", p: 2, paddingLeft: 3, paddingRight: 3 }}>
        <Stepper activeStep={currentStep} style={{ backgroundColor: "transparent" }}>
          {steps.map((label, index) => (
            <Step key={label} onClick={() => handleStepChange(index)}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
    </Box>
  );
}

export default StepperNav;
