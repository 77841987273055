/**
=========================================================
* Otis Admin PRO - v2.0.2
=========================================================

* Product Page: https://material-ui.com/store/items/otis-admin-pro-material-dashboard-react/
* Copyright 2024 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useContext } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
// import Grid from "@mui/material/Grid";
// import MuiLink from "@mui/material/Link";

// @mui icons
// import FacebookIcon from "@mui/icons-material/Facebook";
// import GitHubIcon from "@mui/icons-material/GitHub";
// import GoogleIcon from "@mui/icons-material/Google";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";


// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/highway.jpg";
import logoImage from "assets/images/logo.png";
import config from "../../../../config";
import { useNavigate,useLocation } from "react-router-dom";

const KEYCLOAK_URL = config.KEYCLOAK_URL;
const KEYCLOAK_REALM = config.KEYCLOAK_REALM;
const KEYCLOAK_CLIENT_ID = config.KEYCLOAK_CLIENT_ID;
const KEYCLOAK_CLIENT_SECRET = config.KEYCLOAK_CLIENT_SECRET;
function Basic() {


// console.log("KEYCLOAK_URL", KEYCLOAK_URL);
// console.log("KEYCLOAK_REALM", KEYCLOAK_REALM);
// console.log("KEYCLOAK_CLIENT_ID", KEYCLOAK_CLIENT_ID);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  console.log("location", location.pathname);
  const handleLogin = async () => {
    
    const formData = new URLSearchParams();
    formData.append("client_id", KEYCLOAK_CLIENT_ID);
    formData.append("grant_type", "password");
    formData.append("username", username);
    formData.append("password", password);
    formData.append("client_secret", KEYCLOAK_CLIENT_SECRET);
    try {
      const response = await fetch(`${KEYCLOAK_URL}/realms/${KEYCLOAK_REALM}/protocol/openid-connect/token`, {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Invalid username or password");
      }

      const data = await response.json();
      console.log("data", data);
      localStorage.setItem("keycloak-token", data.access_token);
      if(location.pathname === "/authentication/sign-in/basic"){
        navigate("/landing");
      }else{
      window.location.reload();  
    }  
    } catch (err) {
      setError(err.message);
    }
  };
  return (
    <BasicLayout image={bgImage}>
      
      <Card>
      <MDBox display="flex"  justifyContent="center" mt={2} mb={5}>
      <img src={logoImage} alt="Logo" width="100" height="100" />
    </MDBox>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign in
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput type="email" label="Email" value={username} fullWidth onChange={(e) => setUsername(e.target.value)}/>
            </MDBox>
            <MDBox mb={2}>
              <MDInput type="password" label="Password" value={password}  fullWidth onChange={(e) => setPassword(e.target.value)}/>
            </MDBox>

            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" fullWidth onClick={handleLogin}>
                sign in
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              {error && <p className="text-red-500">{error}</p>}
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
/*
<Grid container spacing={3} justifyContent="center" sx={{ mt: 1, mb: 2 }}>
  <Grid item xs={2}>
    <MDTypography component={MuiLink} href="#" variant="body1" color="white">
      <FacebookIcon color="inherit" />
    </MDTypography>
  </Grid>
  <Grid item xs={2}>
    <MDTypography component={MuiLink} href="#" variant="body1" color="white">
      <GitHubIcon color="inherit" />
    </MDTypography>
  </Grid>
  <Grid item xs={2}>
    <MDTypography component={MuiLink} href="#" variant="body1" color="white">
      <GoogleIcon color="inherit" />
    </MDTypography>
  </Grid>
</Grid>
*/
