
import React, { useEffect, useState } from 'react';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDSlider from "components/MDSlider";
import MDButton from 'components/MDButton';
import { Box, Grid, Typography, Card, CardContent, CardHeader, ToggleButton, ToggleButtonGroup } from '@mui/material';
import MapViewResult from '../map/MapViewResult';
import SelectionService from "../../../services/SelectionService";
import GradientLegend from '../../../components/GradientLegend/GradientLegend';
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import axios from "axios";
import config from "../../../config";

const API_TOKEN = config.MAP_MATCHING_API_TOKEN;
const API_URL = config.MAP_MATCHING_API_URL;

function Results() {
  const [direction, setDirection] = useState('Outgoing');
  const [valueType, setValueType] = useState('Percent');
  const [minPercent, setMinPercent] = useState(4);
  const [percent, setPercent] = useState(4);
  const [name, setName] = useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const rowData = location.state?.rowData;
  useEffect(() => {
    setName(SelectionService.getReportName());

  }, []);
  const handleDirectionChange = (_, newDirection) => {
    if (newDirection !== null) {
      setDirection(newDirection);
    }
  };

  const handleValueTypeChange = (_, newValue) => {
    if (newValue !== null) {
      setValueType(newValue);
    }
  };
  const handleProceedReset = () => {
    SelectionService.resetSelection();
    navigate("/trajectories"); 
  };
  const handleSaveReport = async () => {
    setLoading(true); // Start loading state
    try {
      var url =`${API_URL}/report`;
      const response = await axios.post(url,SelectionService.getReportData(), {
        headers: {
          'X-API-Key': API_TOKEN,
          'Content-Type': 'application/json'
        }
      });

      if (response.status === 201) {
        handleProceedReset(); // Navigate after success
      } else {
        console.error("Failed to save report:", response);
      }
    } catch (error) {
      console.error("Error saving report:", error);
    } finally {
      setLoading(false); // Stop loading state
    }
  };
  return (
    <DashboardLayout>
      <Grid container direction="row" sx={{ alignItems: "stretch", height: "95vh" }}>
        {/* Sidebar */}
        <Box sx={{ width: "30%", height: "95vh", display: "flex", flexDirection: "column" }}>
          <Grid container direction="column" sx={{ flex: 1 }}>
            <Grid item xs={8} sx={{ flexGrow: 1 }}>
              <Typography variant="h5" sx={{ mb: 1 }}>{!rowData ?name:rowData.name}</Typography>
              <Card sx={{ mb: 2 }}>
                <CardHeader title="Filters" />
                <CardContent>
                  <Typography variant="subtitle1" gutterBottom color="HighlightText">Values</Typography>
                  <ToggleButtonGroup
                    value={valueType}
                    exclusive
                    onChange={handleValueTypeChange}
                    fullWidth
                    sx={{ mb: 2 }}
                    color="white"
                  >
                    <ToggleButton value="Percent" >Percent</ToggleButton>
                    <ToggleButton value="Trips">Trips</ToggleButton>
                  </ToggleButtonGroup>
                  {!rowData && (<Typography variant="subtitle1" gutterBottom color="HighlightText">Cut off</Typography>)}
                  {!rowData && (
                    
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <MDSlider
                      defaultValue={0}
                      primaryColor="#1A73E8" // Custom primary color
                      thumbColor="#1A73E8"
                      value={minPercent}
                      onChange={(e, newValue) => setMinPercent(newValue)}
                      onChangeCommitted={(e, newValue) => setPercent(newValue)}
                      min={0}
                      max={15}
                      step={1}
                     
                    />
                    <Typography color="HighlightText">{minPercent}</Typography>
                  </Box>
                  )}
                </CardContent>
              </Card>


              <GradientLegend />
            </Grid>
            <Grid item
              xs="auto"
              sx={{
                mt: "auto", // Pushes the grid to the bottom of the container
                textAlign: "right", // Aligns buttons to the right
              }}>

            </Grid>
            <Grid container justifyContent="center" spacing={2}>
              <Grid item>
                <MDButton
                  variant="outlined"
                  color="info"
                  size="medium" onClick={handleProceedReset}>
                  Start over
                </MDButton>
              </Grid>
              {!rowData && (
              <Grid item>
                <MDButton
                  variant="contained"
                  color="info"
                  size="medium" 
                  disabled={loading} 
                  onClick={handleSaveReport}>
                 {loading ? "Saving..." : "Save report"}
                </MDButton>
              </Grid>
              )}
            </Grid>


          </Grid>
        </Box>

        <Grid container item sx={{ width: "70%", height: "95vh", padding: 0.5 }} direction="column">
          <Grid item sx={{ flex: 1 }}>
            <MapViewResult incomingPercent={percent} incomingValueType={valueType} rowData={rowData} />
          </Grid>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
}

export default Results;