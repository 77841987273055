import axios from "axios";
import config from "../../../config";

const API_URL = config?.TRAFFIC || "";
const SCENARIOMANAGER_URL = config?.SCENARIOMANAGER_URL;
const AUTH_ELASTIC = config?.AUTH_ELASTIC;

// Function to transform area data
const transformAreaData = (data) => {
    if (!Array.isArray(data)) return []; // Prevent errors if data is null
    return data.map((area) => ({
        area_id: Number(area.area_id),
        name: area.name,
        name_el: area.name_el,
        crsName: area.geom?.crs?.properties?.name || "",
    }));
};

// Function to fetch and merge MFD data
export const speedFlowsMFDData = async () => {

    console.log("SCENARIOMANAGER_URL", SCENARIOMANAGER_URL);
    console.log("AUTH_ELASTIC", AUTH_ELASTIC);
    try {
        // Fetch area data
        const areaResponse = await axios.get(`${API_URL}/id_areas`, {
            headers: { "Accept-Profile": "traffic_monitoring" },
        });

        console.log("areaResponse", areaResponse);

        // Fetch speedflow data
        const speedFlowResponse = await axios.get(`${SCENARIOMANAGER_URL}/speedflow/_search?size=10000`, {
            headers: { Authorization: AUTH_ELASTIC },
        });

        console.log("speedFlowResponse", speedFlowResponse);

        // Transform area data
        const areas = transformAreaData(areaResponse.data || []);

        // Transform speedflow data
        const speedflowData = (speedFlowResponse.data?.hits?.hits || []).map((item) => ({
            id: Number(item._source?.id) || 0,
            flow: parseFloat(item._source?.flow) || 0,
            speed: parseFloat(item._source?.speed) || 0,
            area_id: Number(item._source?.area) || 0, // Ensure numeric value
        }));

        // Group speedflow data by area_id
        const groupedSpeedflow = speedflowData.reduce((acc, sf) => {
            const key = String(sf.area_id); // Ensure consistent key type
            acc[key] = acc[key] || [];
            acc[key].push(sf);
            return acc;
        }, {});

        

        // Merge speedflow data with areas
        const mergedAreas = areas.map((area) => ({
            ...area,
            speedflow: groupedSpeedflow[String(area.area_id)] || [],
        }));

        return mergedAreas;

    } catch (error) {
        console.error("Error fetching MFD data:", error.message);
        return null; // Ensure function always returns a predictable value
    }
};
