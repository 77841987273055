import axios from "axios";

import config from "../../../../config";
const API_URL = config.TRAFFIC;

const getUniqueIntersections = (data) => {
  return data.map((d) => ({
    id: d.id,
    match_id: d.id,
    name: d.name,
    name_en: d.name_en,
    geom: d.geom,
  }));
};

// // convert Data to geoJSON
const convertToGeoJSON = (data) => {
  return {
    type: "FeatureCollection",
    features: data.flatMap((feature, index) => [
      {
        type: "Feature",
        properties: {
          id: index + 1,
          match_id: feature.intersection_id,
          intersection_id: feature.intersection_id,
          link_id_full: feature.link_id_full,
          flow: feature.flow,
          speed: feature.speed,
          occupancy: feature.occupancy,
          type: "line",
          color: "#f22470",
        },
        geometry: feature.geom_json.geometry, // Keep geometry as is
      },
      // Text Feature (Label)
      {
        type: "Feature",
        properties: {
          id: index + 1,
          match_id: feature.intersection_id,
          intersection_id: feature.intersection_id,
          link_id_full: feature.link_id_full,
          flow: feature.flow,
          speed: feature.speed,
          occupancy: feature.occupancy,
          // value: Math.round(feature.occupancy),
          value: `${feature.flow} vehicles/hr`,
          type: "text",
          color: "#ffffff",
        },
        geometry: {
          type: "Point",
          coordinates: getMidpoint(feature.geom_json.geometry.coordinates),
        },
      },
    ]),
  };
};

// find the center
const getMidpoint = (coordinates) => {
  const coords = Array.isArray(coordinates[0][0]) ? coordinates[0] : coordinates;
  const midIndex = Math.floor(coords.length / 2);
  return coords[midIndex];
};
export const fetchRcmData = async () => {
  
  try {
    // console.log("listJunctionDataJSON", listJunctionDataJSON);

    const responseList = await axios.get(`${API_URL}/intersections`,
      {
        headers: { "Accept-Profile": "detectors" },
      }
    );

    const responseData = await axios.get(`${API_URL}/rpc/intersection_rcm`,
      {
        headers: { "Accept-Profile": "detectors" },
      }
    );

    console.log('response list', responseList.data);
    console.log('response data', responseData.data);

    const listJunctions = getUniqueIntersections(responseList.data);
    const intersectionData = convertToGeoJSON(responseData.data);

    return [listJunctions, intersectionData];
  } catch (error) {
    console.error("Error fetching Junctions Data:", error);
    return [[], []];
  }
};
