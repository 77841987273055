import { useEffect, useState } from "react";
import { Card } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { routeFlowsData } from "./routeFlowsData";

import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const routeFlowsSchema = [
  { id: 1, name: "Egnatia West", endPoint: "/latest_route_flows_egnatia_transformed", type: ["in"] },
  { id: 1, name: "Egnatia East", endPoint: "/latest_route_flows_egnatia_transformed", type: ["out"] },
  { id: 2, name: "Olgas", endPoint: "/latest_route_flows_olgas_transformed", type: ["in"] },
  { id: 3, name: "Nikis", endPoint: "/latest_route_flows_nikis_transformed", type: ["out"] },
];

function RouteFlows() {
  const [chartData, setChartData] = useState([]);
  const [loading, setLoading] = useState(true);

  // Fetch Data Function
  const fetchData = async () => {
    setLoading(true);
    const results = await Promise.all(
      routeFlowsSchema.map(async (endpoint) => {
        const data = await routeFlowsData(endpoint);
        return data ? data : null;
      })
    );

    setChartData(results.filter((data) => data !== null));
    setLoading(false);
  };

  // useEffect Calls fetchData on Component Mount
  useEffect(() => {
    fetchData();
    console.log("chartData", chartData);
  }, []);

  return (
    <DashboardLayout>
      <Card>
        <MDBox p={3} lineHeight={1}>
          <MDTypography variant="h5" fontWeight>
            Route Flows
          </MDTypography>
        </MDBox>
      </Card>

      {loading ? (
        <MDBox p={3} textAlign="center">
          <MDTypography variant="h6">Loading...</MDTypography>
        </MDBox>
      ) : chartData.length > 0 ? (
        chartData.map((chart, index) => (
            <Card key={index} sx={{ marginTop: "0.5rem", padding: "0.5rem", minHeight: "250px" }}>
            {/* <MDBox p={3} lineHeight={1}>
              <MDTypography variant="h5" fontWeight>
                {chart.options.plugins.title.text}
              </MDTypography>
            </MDBox> */}
            <MDBox sx={{ padding: "0.5rem", height: "300px" }}>
              <Bar data={chart.data} options={chart.options} />
            </MDBox>
          </Card>
        ))
      ) : (
        <MDBox p={3} textAlign="center">
          <MDTypography variant="h6">No Data Available</MDTypography>
        </MDBox>
      )}
    </DashboardLayout>
  );
}

export default RouteFlows;
