import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

const listItemBox = { padding: "1rem", backgroundColor: "#1a2035" };
const listItemTitleStyle = { textAlign: "center", marginBottom: "0.5rem" };
const listItemStyle = { display: "flex", gap: "1rem", alignItems: "center" };

function TrafficListItem({ title, values }) {
  return (
    <MDBox borderRadius="lg" sx={listItemBox} mb={1}>
      <MDTypography variant="h6" sx={listItemTitleStyle}>
        {title}
      </MDTypography>

      {values.map((item, idx) => (
        <MDBox key={idx} sx={listItemStyle}>
          <MDTypography variant="body2">{item.label}:</MDTypography>
          <MDTypography
            variant="body2"
            color={
              item.status === "pass"
                ? "success"
                : item.status === "notPass"
                ? "error"
                : "white"
            }
          >
            {item.value}
          </MDTypography>
        </MDBox>
      ))}
    </MDBox>
  );
}

export default TrafficListItem;
