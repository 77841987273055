// Function to decode JWT
export function decodeJwt(token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
        atob(base64)
            .split('')
            .map(c => `%${('00' + c.charCodeAt(0).toString(16)).slice(-2)}`)
            .join('')
    );
    return JSON.parse(jsonPayload);
}

export const getTokenExpiration = (token) => {
    try {
      const decodedToken = JSON.parse(atob(token.split(".")[1])); // Decode JWT
      return decodedToken.exp; // Return expiration timestamp
    } catch (error) {
      console.error("Error decoding token:", error);
      return null;
    }
  };
export const startTokenRefresh = (tokenRefreshInterval) => {
    if (!tokenRefreshInterval.current) {
      tokenRefreshInterval.current = setInterval(() => {
        keycloakRef.current
          .updateToken(30)
          .then((refreshed) => {
            if (refreshed) {
              console.log("Token refreshed");
            }
          })
          .catch(() => {
            console.warn("Token refresh failed, logging out");
            keycloakRef.current.logout();
          });
      }, 60000); // Check every 60 seconds
    }
  }
// Function to check if the token is expired
export function isTokenExpired(exp) {
    const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
    return exp < currentTime;
}

// Function to get roles starting with admin, editor, or viewer
export function getFilteredRoles(realmAccess) {
    const roles = realmAccess?.roles || [];
    return roles.filter(role => /^(admin|editor|viewer)/.test(role));
}

// Function to check if the token is about to expire in the next 3 minutes
export function isTokenExpiringSoon(exp) {
    const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
    const threeMinutesInSeconds = 3 * 60;
    return exp >= currentTime && exp <= currentTime + threeMinutesInSeconds;
}