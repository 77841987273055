import { useEffect, useState } from "react";

import { Card, CircularProgress, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import TrafficList from "./TrafficList";
import useNetworkData from "../data/useNetworkData";
import useEventsData from "layouts/events/show/data/useEventsData";
import TrafficMonitoringMap from "./trafficMonitoringMap/TrafficMonitoringMap";
import useTrafficMonitoringGeom from "../data/useTrafficMonitoringGeom";

function NetworkView() {
  const { data, loading } = useNetworkData();
  const { events, loading: eventsLoading, error } = useEventsData();
  const { data: networkData, loading: networkLoading } = useTrafficMonitoringGeom();
  

  const [onlyActive, setOnlyActive] = useState([]);
  const [onlyJam, setOnlyJam] = useState([]);
  const [networkSource, setNetworkSource] = useState([]);

  useEffect(() => {
    if (!Array.isArray(events) || events.length === 0) return; // ✅ Prevents filtering null/empty data
    const activeEvents = events.filter((event) => event.check_status?.id === 1);
    const jamEvents = activeEvents.filter((event) => event.category?.id === 10);

    setOnlyActive(activeEvents);
    setOnlyJam(jamEvents);
  }, [events]);

  useEffect(() => {
    if (!networkData) return;
    setNetworkSource(networkData);
  }, [networkData]);

  const dataOfEvents = [{
    "name": "no. of events",
    "values": [
      {
        "label": "Total",
        "value": onlyActive.length,
        "status": "neutral"
      }
    ]
  }];

  const dataOfJams = [{
    "name": "no. of traffic jams",
    "values": [
      {
        "label": "Total",
        "value": onlyJam.length,
        "status": "neutral"
      }
    ]
  }];

  if (loading || eventsLoading || networkLoading) {
    return (
      <Grid container justifyContent="center" alignItems="center" sx={{ height: "100%" }}>
        <CircularProgress />
      </Grid>
    );
  }



  return (
    <Grid container spacing={2} sx={{ flexGrow: 1 }}>
      {/* Statistic List */}
      <Grid item xs={12} md={3}>
        {/* Render all staistics */}

        <TrafficList data={data} />
        <TrafficList data={dataOfEvents} />
        <TrafficList data={dataOfJams} />
      </Grid>

      {/* Map Display */}
      <Grid item xs={12} md={9}>
        <MDBox sx={{ height: "100%" }}>
          <TrafficMonitoringMap onlyActive={onlyActive} networkSource={networkSource}  areaRectSource={[]}/>
        </MDBox>       
      </Grid>
    </Grid>
  );
}

export default NetworkView;


