

export const formatDateDQ = (date) => {
    const yyyy = date.getFullYear();
    const MM = String(date.getMonth() + 1).padStart(2, '0');
    const dd = String(date.getDate()).padStart(2, '0');
    const hh = String(date.getHours()).padStart(2, '0');
    const mm = String(date.getMinutes()).padStart(2, '0');
    return `${yyyy}-${MM}-${dd}T${hh}:${mm}:00`;
};


export function getUTCDatesFromTo(hoursAgo) {
    // Get the current date and time
    const now = new Date();

    // Create a new date for 1 hour ago
    const oneHourAgo = new Date(now.getTime() - hoursAgo * 60 * 60 * 1000);

    // Function to format a date to UTC string in the desired format
    function formatToUTC(date) {
        return date.toISOString().split('Z')[0] + 'Z';
    }

    // Format both dates to UTC
    const nowUTC = formatToUTC(now);
    const hourAgoUTC = formatToUTC(oneHourAgo);

    return { hourAgoUTC, nowUTC };
}

export function getUTCDatesFromParams(from, to) {
    // Function to format a date to UTC string in the desired format
    function formatToUTC(date) {
        return date.toISOString().split('Z')[0] + 'Z';
    }

    // Parse the input date strings
    const date1 = new Date(from);
    const date2 = new Date(to);

    // Format both dates to UTC
    const date1UTC = formatToUTC(date1);
    const date2UTC = formatToUTC(date2);

    return { date1UTC, date2UTC };
}


export function adjustDatesLast24Table(fromDate, toDate) {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const to = new Date(toDate);
    
    // Check if 'to' date is today
    if (to.toDateString() === today.toDateString()) {
        const from = new Date(fromDate);
        const diff = to - from;
        const hoursDifference = diff / (1000 * 60 * 60);
        
        // If from date is less than 24 hours before to date, adjust it
        if (hoursDifference < 24) {
            from.setTime(to.getTime() - 24 * 60 * 60 * 1000);
        }
        
        return {
            from: from.toISOString().split('T')[0],
            to: to.toISOString().split('T')[0]
        };
    }
    
    return {
        from: new Date(fromDate).toISOString().split('T')[0],
        to: new Date(toDate).toISOString().split('T')[0]
    };
}