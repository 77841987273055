import axios from 'axios';
import React, { useEffect, useState } from 'react';
import BarTimeValue from '../Charts/BarTimeValue';
import { namedColor, transparentize } from 'Chart/ChartUtils';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from "@mui/material/Grid";
import config from '../../../../config';
import DecimationTimeValue from '../Charts/DecimetionTimeValue';
import ChartTimeValue from '../Charts/ChartTimeValue';
import { borderColor } from '@mui/system';

const SpeedTravelComp = ({ params }) => {
    console.log("SpeedTravelComp", params)

    const getNameById = (id) => {
        const item = params.selectedDetectors.find(item => item.id === id);
        return item ? item.name : null; // Return name if found, otherwise null
    };

    const [chartData, setChartData] = useState();
    const [accurasyChart, setAccurasyChart] = useState();

    const [noRouteData, setNoRouteData] = useState(false)

    useEffect(() => {
        async function fetchData() {
            try {
                let getUrl = "";
                let header = "open_imet";
                if (params.detector === "tomtomfcd") {
                    getUrl = config.ENDPOINTS_URL + `/rpc/th_tomtom_completeness_per_route?start_ts=${params.dates.from}&end_ts=${params.dates.to}&route_id=${params.selectedDetectors[0].id.toString()}`
                    header = "route_monitoring";
                } else if (params.detector === "taxifcd") {
                    getUrl = config.ENDPOINTS_URL + `/rpc/th_taxi_competeness_per_route?start_time=${params.dates.from}&end_time=${params.dates.to}&route_id=${params.selectedDetectors[0].id.toString()}`
                } else {
                    getUrl = config.ENDPOINTS_URL + `/rpc/th_bt_competeness_per_route?start_time=${params.dates.from}&end_time=${params.dates.to}&route_id=${params.selectedDetectors[0].id.toString()}`
                }
                const response = await axios(getUrl, {
                    headers: {
                        "Accept-Profile": header
                    }
                });
                if (!response.data) {
                    setNoRouteData(true)
                } else {
                    setNoRouteData(false);
                    let data;
                    if (params.detector === "tomtomfcd") {
                        data = response.data.map((item) => ({
                            x: new Date(item.ts).toLocaleString('el-GR'), // Format date to be readable
                            y: item.completeness,
                        }));
                    } else if (params.detector === "taxifcd") {
                        data = response.data.map((item) => ({
                            x: new Date(item.time_slot).toLocaleString('el-GR'), // Format date to be readable
                            y: item.competeness,
                        }));
                    } else {
                        data = response.data.map((item) => ({
                            x: new Date(item.time_slot).toLocaleString('el-GR'), // Format date to be readable
                            y: item.competeness,
                        }));
                    }
                    const chartD = {
                        datasets: [
                            {
                                label: params.selectedDetectors[0].name,
                                data: data,
                                backgroundColor: transparentize(namedColor(0), 0.5),
                                borderWidth: 0, // Removes the connecting line                            
                                tension: 0.4, // Line smoothing
                                minBarLength: 7, // Ensures bars are visible even when value is 0
                            }
                        ]
                    };
                    setChartData(chartD);
                }
            } catch (error) {
                console.log(error)
            }
        }
        async function fetchDataCompare() {
            try {
                let getUrl = "";
                let header = "open_imet";
                if (params.detector === "tomtomfcd") {
                    getUrl = config.ENDPOINTS_URL + `/rpc/th_tomtom_completeness_per_route?p_start_time=${params.dates.from}&p_end_time=${params.dates.to}&p_path_id=${params.selectedDetectors[0].id.toString()}`
                    header = "route_monitoring";
                } else if (params.detector === "taxifcd") {
                    getUrl = config.ENDPOINTS_URL + `/rpc/th_realtime_fcd_vs_tomtom?p_start_time=${params.dates.from}&p_end_time=${params.dates.to}&p_path_id=${params.selectedDetectors[0].id.toString()}`
                } else {
                    getUrl = config.ENDPOINTS_URL + `/rpc/th_realtime_bt_vs_tomtom?p_start_time=${params.dates.from}&p_end_time=${params.dates.to}&p_path_id=${params.selectedDetectors[0].id.toString()}`
                }
                const response = await axios(getUrl, {
                    headers: {
                        "Accept-Profile": header
                    }
                });
                if (!response.data) {
                    setNoRouteData(true)
                } else {
                    setNoRouteData(false);
                    let data1;
                    let data2;
                    let title = "";

                    if (params.detector === "tomtomfcd") {
                        
                    } else if (params.detector === "taxifcd") {
                        title = "Taxi fcd";
                        data1 = response.data.filter(item => item.source === "tt_fcd")
                        //.sort((a, b) => new Date(a.ts) - new Date(b.ts)) // Sort by timestamp, oldest first
                        .map(item => ({
                          x: new Date(item.ts).toLocaleString('el-GR'), // Format date to be readable
                          y: item.duration,
                        }));
                        data2 = response.data.filter(item => item.source === "outputs_route_monitoring_path_bt")
                        //.sort((a, b) => new Date(a.ts) - new Date(b.ts)) // Sort by timestamp, oldest first
                        .map(item => ({
                          x: new Date(item.ts).toLocaleString('el-GR'), // Format date to be readable
                          y: item.duration,
                        }));
                    } else {
                        title = "Bluetooth";
                        data1 = response.data.filter(item => item.source === "tt_bluetooth")
                        //.sort((a, b) => new Date(a.ts) - new Date(b.ts)) // Sort by timestamp, oldest first
                        .map(item => ({
                          x: new Date(item.ts).toLocaleString('el-GR'), // Format date to be readable
                          y: item.duration,
                        }));
                      
                      data2 = response.data.filter(item => item.source === "outputs_route_monitoring_path_bt")
                        .sort((a, b) => new Date(a.ts) - new Date(b.ts)) // Sort by timestamp, oldest first
                        .map(item => ({
                          x: new Date(item.ts).toLocaleString('el-GR'), // Format date to be readable
                          y: item.duration,
                        }));
                      
                    }
                    console.log("datas", data1, data2)
                    const chartD = {
                        datasets: [
                            {
                                label: "TomTom",
                                data: data2,
                                backgroundColor: transparentize(namedColor(1), 0.5),
                                pointBorderColor: transparentize(namedColor(1), 0.5),
                                borderColor: transparentize(namedColor(1), 0.5),
                                borderWidth: 2, // Removes the connecting line                            
                                tension: 0, // Line smoothing
                                pointRadius: 4, // Adds visible points on the line
                                pointHoverRadius: 6, // Increases size on hover
                                spanGaps: true // Ensure line connects all points
                            },
                            {
                                label: title,
                                data: data1,
                                backgroundColor: transparentize(namedColor(0), 0.5),
                                pointBorderColor: transparentize(namedColor(0), 0.5),
                                borderColor: transparentize(namedColor(0), 0.5),
                                borderWidth: 2, // Removes the connecting line                            
                                tension: 0, // Line smoothing
                                pointRadius: 4, // Adds visible points on the line
                                pointHoverRadius: 6, // Increases size on hover
                                spanGaps: true // Ensure line connects all points
                            }
 
                        ]
                    };
                    setAccurasyChart(chartD);
                }
            } catch (error) {
                console.log(error)
            }
        }
        fetchData();
        fetchDataCompare();
    }, [params])

    return (
        <Grid container={true} p={2}>
            {
                params.location === "Routes" && params.selectedDetectors.length > 0 &&
                <div className='rawContainerDiagrams'>
                    <div className='diagrammHeadTitle'>Completeness per Route</div>
                    {chartData ? <div className='detectorDiagramsContainer'><BarTimeValue chartData={chartData} title="Completeness per route" /></div> : <div className='rawContainerCenteredH'>{noRouteData ? <span>No data for this combination of model-detector or timeframe</span> : <CircularProgress />}</div>}
                </div>
            }
            <div className='rawContainerDiagrams'>
                <div className='diagrammHeadTitle'>Accuracy</div>
                <div className="percentage-container">
                    <span className="percentage-value">{5}%</span>
                    <span className="percentage-label">The value from the route deviated by approximately 5% from the ground truth value (TomTom data) at that specific time interval</span>
                </div>
                {
                    params.detector !== "tomtomfcd" &&
                    <>{accurasyChart ? <div className='detectorDiagramsContainer'><ChartTimeValue chartData={accurasyChart} title="Accuracy" /></div> : <div className='rawContainerCenteredH'>{noRouteData ? <span>No data for this combination of model-detector or timeframe</span> : <CircularProgress />}</div>}</>
                }
            </div>
        </Grid>
    );
};

export default SpeedTravelComp;