import Box from '@mui/material/Box';
import React, { useEffect, useState } from "react";
import axios from 'axios';
import config from "../config";
import Modal from '@mui/material/Modal';
import SelectFromMapData from './selectFromMapData';
import { globalUrls } from 'modules/globals';
import { detectorModelCodes } from 'modules/globals';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "100%",
    maxWidth: 1400,
    bgcolor: '#1a2035',
    border: '2px solid #1a2035',
    borderRadius: "5px",
    boxShadow: 24,
    p: 4,
};




export default function SelectFromMap({ open, handleClose, title, dataSubSource, type, selected, setSelected, fetchedData = [] }) {

    const [allData, setAllData] = useState();

    useEffect(() => {
        let castedData = {
            type: "FeatureCollection",
            features: []
        };
        async function fetchData(type) {
            let url = "";
            let response = null;
            const subSourceCode = detectorModelCodes[dataSubSource] || null;
            console.log(subSourceCode)
            try {
                switch (type) {
                    case "route":
                        url = config.ENDPOINTS_URL + '/route_geom';
                        response = await axios.get(url, {
                            headers: {
                                "Accept-Profile": "detectors"
                            }
                        });
                        response.data = response.data.filter(item => item.detector_model_id === subSourceCode);
                        castedData.features = response.data.map(item => ({
                            type: "Feature",
                            properties: {
                                id: item.route_id,
                                name: item.name_en || "Unknown",
                            },
                            geometry: {
                                type: "LineString",
                                coordinates: item.geom
                            }
                        }));
                        break;
                    case "intersection":
                        url = config.ENDPOINTS_URL + "/intersection_geom";
                        response = await axios.get(url, {
                            headers: {
                                "Accept-Profile": "detectors"
                            }
                        });
                        response.data = response.data.filter(item => item.detector_model_id === subSourceCode);
                        castedData.features = response.data.map(item => ({
                            type: "Feature",
                            properties: {
                                id: item.intersection_id,
                                name: item.name_en
                            },
                            geometry: {
                                type: "Point",
                                coordinates: item.geom
                            }
                        }));
                        break;
                    case "speedRoutesBTTaxi":
                        castedData.features = fetchedData.map(item => ({
                            type: "Feature",
                            properties: {
                                id: item.id_path,
                                name: item.path_name || "Unknown",
                            },
                            geometry: {
                                type: "MultiLineString",
                                coordinates: item.merged_geom.coordinates
                            }
                        }));
                        break;
                    case "speedRoutesTomTom":
                        castedData.features = fetchedData.map(item => ({
                            type: "Feature",
                            properties: {
                                id: item.id_long_paths,
                                name: item.name || "Unknown",
                            },
                            geometry: {
                                type: "MultiLineString",
                                coordinates: item.merged_geom.coordinates
                            }
                        }));
                        break;
                    case "oasthRoutes":
                        castedData.features = fetchedData.map(item => ({
                            type: "Feature",
                            properties: {
                                id: item.bus_stop_id,
                                name: item.busstop || "Unknown",
                            },
                            geometry: {
                                type: "Point",
                                coordinates: item.geom
                            }
                        }));
                        break;
                    default:
                        url = config.ENDPOINTS_URL + "/detector_geom";
                        response = await axios.get(url, {
                            headers: {
                                "Accept-Profile": "detectors"
                            }
                        });
                        response.data = response.data.filter(item => item.detector_model_id === subSourceCode);
                        castedData.features = response.data.map(item => ({
                            type: "Feature",
                            properties: {
                                id: item.detector_id,
                                name: item.detector_name
                            },
                            geometry: {
                                type: "Point",
                                coordinates: item.geom
                            }
                        }));
                        break;
                }
                console.log(castedData)
                setAllData(castedData);
            } catch (error) {
                console.log(error);
            }
        }
        fetchData(type);
    }, [])
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
            style={{ borderRadius: "5px", color: "white" }}
        >
            {
                allData
                    ? <Box sx={style}>
                        <div className='modal-container'>
                            <span style={{ marginBottom: "10px" }}>
                                Selected {title}: {selected[0] !== -1 && selected[0]}
                            </span>
                            <SelectFromMapData castedData={allData} type={type} selected={selected} setSelected={setSelected} />
                        </div>
                    </Box>
                    : <span>Loading...</span>
            }

        </Modal>
    )
}