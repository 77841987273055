import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Grid from "@mui/material/Grid";
// import data from "../../comp.json";
import ChartTimeValue from '../Charts/ChartTimeValue';
import DecimationTimeValue from '../Charts/ChartTimeValue';
import CircularProgress from '@mui/material/CircularProgress';
import { namedColor, transparentize } from 'Chart/ChartUtils';
import DetectorsOverview from '../tables/DetectorsOverview';
import { detectorModelCodes } from 'modules/globals';
import ReactSpeedometer from "react-d3-speedometer";
import BarTimeValue from '../Charts/BarTimeValue';
import config from '../../../../config';

const DetectorComp = ({ params }) => {
    const [error, setError] = useState(false)
    const [noIntersectionData, setNoIntersectionData] = useState(false)
    const [noRouteData, setNoRouteData] = useState(false)
    const [noDetData, setNoDetData] = useState(false)

    const [chartData, setChartData] = useState()
    const [chartDataPerIntersection, setChartDataPerIntersection] = useState()
    const [chartDataPerRoute, setChartDataPerRoute] = useState()
    const [chartDataPerDet, setChartDataPerDet] = useState()
    const [detectorStats, setDetectorStats] = useState()

    const [validityGause, setValidityGause] = useState()

    const getNameById = (id) => {
        const item = params.selectedDetectors.find(item => item.id === id);
        return item ? item.name : null; // Return name if found, otherwise null
    };

    const flagsTitle = {
        "1": "occupancy=255",
        "2": "flow<=-1",
        "3": "speed<=-1",
        "4": "occupancy<=-1",
        "5": "flow=0 & occupancy>0",
        "6": "flow>0 & occupancy=0",
    }
    useEffect(() => {
        async function fetchData() {
            try {
                let getUrl = "";
                if (params.detector === "all") {
                    getUrl = config.ENDPOINTS_URL + `/rpc/th_completeness_per_provider_materialized_dynamic?start_time=${params.dates.from}&end_time=${params.dates.to}&&provider_filter={Siemens,SCAE,Swarco}`
                } else if (params.detector === "yunex") {
                    getUrl = config.ENDPOINTS_URL + `/rpc/th_completeness_per_provider_materialized_dynamic?start_time=${params.dates.from}&end_time=${params.dates.to}&&provider_filter={Siemens}`
                } else if (params.detector === "scae") {
                    getUrl = config.ENDPOINTS_URL + `/rpc/th_completeness_per_provider_materialized_dynamic?start_time=${params.dates.from}&end_time=${params.dates.to}&&provider_filter={SCAE}`
                } else {
                    getUrl = config.ENDPOINTS_URL + `/rpc/th_completeness_per_provider_materialized_dynamic?start_time=${params.dates.from}&end_time=${params.dates.to}&&provider_filter={Swarco}`
                }
                const response = await axios(getUrl, {
                    headers: {
                        "Accept-Profile": "detectors"
                    }
                });
                if (params.detector === "all") {
                    const Siemens = response.data.Siemens.map((item) => ({
                        x: new Date(item.ets).toLocaleString('el-GR'), // Format date to be readable
                        y: item.completeness,
                    }));

                    const SCAE = response.data.SCAE.map((item) => ({
                        x: new Date(item.ets).toLocaleString('el-GR'),
                        y: item.completeness,
                    }));
                    const Swarco = response.data.Swarco.map((item) => ({
                        x: new Date(item.ets).toLocaleString('el-GR'), // Format date to be readable
                        y: item.completeness,
                    }));
                    const chartD = {
                        datasets: [
                            {
                                label: "Siemens Completeness",
                                data: Siemens,
                                borderWidth: 0, // Removes the connecting line                            
                                backgroundColor: "rgba(194, 91, 235, 0.85)", // Point fill color
                                tension: 0.4, // Line smoothing
                            },
                            {
                                label: "SCAE Completeness",
                                data: SCAE,
                                borderWidth: 0, // Removes the connecting line                            
                                backgroundColor: "rgba(255, 99, 132, 1)", // Point fill color
                                tension: 0.4, // Line smoothing
                            },
                            {
                                label: "Swarco Completeness",
                                data: Swarco,
                                borderWidth: 0, // Removes the connecting line                            
                                backgroundColor: "rgba(75, 192, 192, 1)", // Point fill color
                                tension: 0.4, // Line smoothing
                            }
                        ],
                    };
                    setChartData(chartD)

                } else if (params.detector === "yunex") {
                    const Siemens = response.data.Siemens.map((item) => ({
                        x: new Date(item.ets).toLocaleString('el-GR'), // Format date to be readable
                        y: item.completeness,
                    }));
                    const chartD = {
                        datasets: [
                            {
                                label: "Siemens Completeness",
                                data: Siemens,
                                borderWidth: 0, // Removes the connecting line                            
                                backgroundColor: "rgba(75, 192, 192, 0.2)", // Point fill color
                                tension: 0.4, // Line smoothing
                            }
                        ]
                    };
                    setChartData(chartD)
                } else if (params.detector === "scae") {
                    const SCAE = response.data.SCAE.map((item) => ({
                        x: new Date(item.ets).toLocaleString('el-GR'), // Format date to be readable
                        y: item.completeness,
                    }));
                    const chartD = {
                        datasets: [
                            {
                                label: "SCAE Completeness",
                                data: SCAE,
                                borderWidth: 0, // Removes the connecting line                            
                                backgroundColor: "rgba(75, 192, 192, 0.2)", // Point fill color
                                tension: 0.4, // Line smoothing
                            }
                        ]
                    };
                    setChartData(chartD)
                } else {
                    const Swarco = response.data.Swarco.map((item) => ({
                        x: new Date(item.ets).toLocaleString('el-GR'), // Format date to be readable
                        y: item.completeness,
                    }));
                    const chartD = {
                        datasets: [
                            {
                                label: "Swarco Completeness",
                                data: Swarco,
                                borderWidth: 0, // Removes the connecting line                            
                                backgroundColor: "rgba(75, 192, 192, 0.2)", // Point fill color
                                tension: 0.4, // Line smoothing
                            }
                        ]
                    };
                    setChartData(chartD)
                }
            } catch (err) {
                console.log(err)
                setError(true)
            }
        }
        async function fetchPerIntersection() {
            let getUrl = "";
            if (params.detector === "yunex") {
                getUrl = config.ENDPOINTS_URL + `/rpc/th_completeness_per_intersection_materialized_dynamic?in_start_time=${params.dates.from}&in_end_time=${params.dates.to}&in_detector_model_id=2&in_intersection_ids={`
            } else if (params.detector === "scae") {
                getUrl = config.ENDPOINTS_URL + `/rpc/th_completeness_per_intersection_materialized_dynamic?in_start_time=${params.dates.from}&in_end_time=${params.dates.to}&in_detector_model_id=1&in_intersection_ids={`
            } else {
                getUrl = config.ENDPOINTS_URL + `/rpc/th_completeness_per_intersection_materialized_dynamic?in_start_time=${params.dates.from}&in_end_time=${params.dates.to}&in_detector_model_id=3&in_intersection_ids={`
            }
            params.selectedDetectors.map((detector) => {
                getUrl = getUrl + detector.id.toString() + ','
            })
            let newUrl = getUrl.substring(0, getUrl.length - 1);

            newUrl = newUrl + "}";
            let tempDataVals
            try {
                const response = await axios(newUrl, {
                    headers: {
                        "Accept-Profile": "detectors"
                    }
                });
                console.log(response.data)
                if (!response.data) {
                    setNoIntersectionData(true)
                } else {
                    setNoIntersectionData(false)
                    let dataset = [];
                    params.selectedDetectors.map((detector, key) => {
                        tempDataVals = response.data[detector.id].map((item) => ({
                            x: new Date(item.ets).toLocaleString('el-GR'), // Format date to be readable
                            y: item.completeness,
                        }));
                        const dsColor = namedColor(key);
                        dataset.push({
                            label: getNameById(detector.id),
                            data: tempDataVals,
                            backgroundColor: transparentize(dsColor, 0.5),
                            borderWidth: 0, // Removes the connecting line                            
                            tension: 0.4, // Line smoothing
                        })
                    })
                    const chartD = {
                        datasets: dataset
                    }
                    setChartDataPerIntersection(chartD)
                }
            } catch (err) {
                console.log(err)
            }

        }
        async function fetchPerRoute() {
            let getUrl = "";
            if (params.detector === "yunex") {
                getUrl = config.ENDPOINTS_URL + `/rpc/th_completeness_per_route_dynamic_materialized_dynamic?in_start_time=${params.dates.from}&in_end_time=${params.dates.to}&in_detector_model_id=2&in_route_ids={`
            } else if (params.detector === "scae") {
                getUrl = config.ENDPOINTS_URL + `/rpc/th_completeness_per_route_dynamic_materialized_dynamic?in_start_time=${params.dates.from}&in_end_time=${params.dates.to}&in_detector_model_id=1&in_route_ids={`
            } else {
                getUrl = config.ENDPOINTS_URL + `/rpc/th_completeness_per_route_dynamic_materialized_dynamic?in_start_time=${params.dates.from}&in_end_time=${params.dates.to}&in_detector_model_id=3&in_route_ids={`
            }
            params.selectedDetectors.map((detector) => {
                getUrl = getUrl + detector.id.toString() + ','
            })
            let newUrl = getUrl.substring(0, getUrl.length - 1);

            newUrl = newUrl + "}";
            let tempDataVals
            try {
                const response = await axios(newUrl, {
                    headers: {
                        "Accept-Profile": "detectors"
                    }
                });
                if (!response.data) {
                    setNoRouteData(true)
                } else {
                    setNoRouteData(false)
                    let dataset = [];
                    params.selectedDetectors.map((detector, key) => {
                        tempDataVals = response.data[detector.id].map((item) => ({
                            x: new Date(item.ets).toLocaleString('el-GR'), // Format date to be readable
                            y: item.completeness,
                        }));
                        const dsColor = namedColor(key);
                        dataset.push({
                            label: getNameById(detector.id),
                            data: tempDataVals,
                            backgroundColor: transparentize(dsColor, 0.5),
                            borderWidth: 0, // Removes the connecting line                            
                            tension: 0.4, // Line smoothing
                        })
                    })
                    const chartD = {
                        datasets: dataset
                    }
                    setChartDataPerRoute(chartD)
                }
            } catch (err) {
                console.log(err)
            }

        }
        async function fetchPerDet() {
            let getUrl = "";
            if (params.detector === "yunex") {
                getUrl = config.ENDPOINTS_URL + `/rpc/th_completeness_per_detector_materialized_dynamic?in_start_time=${params.dates.from}&in_end_time=${params.dates.to}&in_detector_model_id=2&in_detector_ids={`
            } else if (params.detector === "scae") {
                getUrl = config.ENDPOINTS_URL + `/rpc/th_completeness_per_detector_materialized_dynamic?in_start_time=${params.dates.from}&in_end_time=${params.dates.to}&in_detector_model_id=1&in_detector_ids={`
            } else {
                getUrl = config.ENDPOINTS_URL + `/rpc/th_completeness_per_detector_materialized_dynamic?in_start_time=${params.dates.from}&in_end_time=${params.dates.to}&in_detector_model_id=3&in_detector_ids={`
            }
            params.selectedDetectors.map((detector) => {
                getUrl = getUrl + detector.id.toString() + ','
            })
            let newUrl = getUrl.substring(0, getUrl.length - 1);

            newUrl = newUrl + "}";
            let tempDataVals
            try {
                const response = await axios(newUrl, {
                    headers: {
                        "Accept-Profile": "detectors"
                    }
                });
                if (!response.data) {
                    setNoDetData(true)
                } else {
                    setNoDetData(false)
                    let dataset = [];
                    params.selectedDetectors.map((detector, key) => {
                        tempDataVals = response.data[detector.id].map((item) => ({
                            x: new Date(item.ets).toLocaleString('el-GR'), // Format date to be readable
                            y: item.completeness,
                        }));
                        const dsColor = namedColor(key);
                        dataset.push({
                            label: getNameById(detector.id),
                            data: tempDataVals,
                            backgroundColor: transparentize(dsColor, 0.5),
                            borderWidth: 0, // Removes the connecting line                            
                            tension: 0.4, // Line smoothing
                        })
                    })
                    const chartD = {
                        datasets: dataset
                    }
                    setChartDataPerDet(chartD)
                }
            } catch (err) {
                console.log(err)
            }

        }

        async function fetchValidityData(detectorModel, validityUrl, idsUrl) {
            try {
                let getUrl = "";
                const modelCode = detectorModelCodes[detectorModel];
                getUrl = config.ENDPOINTS_URL + `/rpc/${validityUrl}?in_start_timestamp=${params.dates.from}&in_end_timestamp=${params.dates.to}&in_detector_model_id=${modelCode}&${idsUrl}=`
                params.selectedDetectors.map((detector) => {
                    getUrl = getUrl + detector.id.toString() + ','
                })
                let newUrl = getUrl.substring(0, getUrl.length - 1);

                console.log(newUrl)
                const response = await axios(newUrl, {
                    headers: {
                        "Accept-Profile": "detectors"
                    }
                });
                setValidityGause(response.data)
            } catch (err) {
                console.log(err)
            }
        }

        async function detectorStatistics() {
            try {
                let newUrl = config.ENDPOINTS_URL + `/detector_statistics`;
                const response = await axios(newUrl, {
                    headers: {
                        "Accept-Profile": "detectors"
                    }
                });
                const filtered = response.data.filter((item) => (
                    item.id === params.selectedDetectors[0].id
                ))
                console.log(filtered)
                setDetectorStats(filtered)
            } catch (err) {
                console.log(err)
            }
        }

        fetchData();
        if (params.detector !== "all" && params.location === "Intersections" && params.selectedDetectors.length > 0) {
            fetchPerIntersection();
            fetchValidityData(params.detector, "th_validity_per_intersection_materialized", "in_intersection_ids")
        }
        if (params.detector !== "all" && params.location === "Routes" && params.selectedDetectors.length > 0) {
            fetchPerRoute();
            fetchValidityData(params.detector, "th_validity_per_route_materialized", "in_route_ids");
        }
        if (params.detector !== "all" && params.location === "Detectors" && params.selectedDetectors.length > 0) {
            fetchPerDet();
            fetchValidityData(params.detector, "th_validity_per_detector_materialized", "in_detector_ids");
            detectorStatistics();
        }
    }, [params])
    return (
        <Grid container={true} p={2}>
            {
                params.detector !== "all" && params.location === "Intersections" && params.selectedDetectors.length > 0 &&
                <div className='rawContainerDiagrams'>
                    <div className='diagrammHeadTitle'>Completeness per Intersection</div>
                    {chartDataPerIntersection ? <div className='detectorDiagramsContainer'><BarTimeValue chartData={chartDataPerIntersection} title="Completeness per Intersection" /> </div> : <div className='rawContainerCenteredH'>{noIntersectionData ? <span>No data for this combination of model-detector or timeframe</span> : <CircularProgress />}</div>}
                </div>
            }
            {
                params.detector !== "all" && params.location === "Routes" && params.selectedDetectors.length > 0 &&
                <div className='rawContainerDiagrams'>
                    <div className='diagrammHeadTitle'>Completeness per Route</div>
                    {chartDataPerRoute ? <div className='detectorDiagramsContainer'><BarTimeValue chartData={chartDataPerRoute} title="Completeness per route" /></div> : <div className='rawContainerCenteredH'>{noRouteData ? <span>No data for this combination of model-detector or timeframe</span> : <CircularProgress />}</div>}
                </div>
            }
            {
                params.detector !== "all" && params.location === "Detectors" && params.selectedDetectors.length > 0 &&
                <div className='rawContainerDiagrams'>
                    <div className='diagrammHeadTitle'>Completeness per Detector</div>
                    {chartDataPerDet ? <div className='detectorDiagramsContainer'><BarTimeValue chartData={chartDataPerDet} title="Completeness per detector" /> </div> : <div className='rawContainerCenteredH'>{noDetData ? <span>No data for this combination of model-detector or timeframe</span> : <CircularProgress />}</div>}
                </div>
            }
            {
                (params.detector === "all" || params.location === "Network") &&
                <div className='rawContainerDiagrams'>
                    <div className='diagrammHeadTitle'>Completeness per Data Source</div>
                    {chartData ? <div className='detectorDiagramsContainer'><BarTimeValue chartData={chartData} title="Completeness over Time" /></div> : <div className='rawContainerCenteredH'><CircularProgress /></div>}
                </div>
            }
            {
                (params.detector !== "all" && params.location !== "Network" && params.selectedDetectors.length > 0) &&
                <div className='rawContainerDiagrams'>
                    <div className='diagrammHeadTitle'>Validity per {params.location.slice(0, -1)}</div>
                    {(validityGause && validityGause.length > 0)
                        ? <div className='rawContainerDiagrams'>
                            {
                                validityGause.map((item, key) => (
                                    <div className='gauseContainer'>
                                        <span className='gauseTitle'>{flagsTitle[String(item.flag)]}</span>
                                        <ReactSpeedometer
                                            maxValue={100}
                                            minValue={0}
                                            value={item.percentage_invalid.toFixed(3)}
                                            needleColor="red"
                                            startColor="green"
                                            segments={10}
                                            endColor="blue"
                                            key={key}
                                            paddingHorizontal={5}
                                            paddingVertical={5}
                                            height={150}
                                            width={200}
                                        />
                                    </div>
                                ))
                            }
                        </div>
                        : (validityGause && validityGause.length === 0) ? <span>No data</span> : <div className='rawContainerCenteredH'><CircularProgress /></div>}
                </div>
            }
            <div className='rawContainerDiagrams'>
                <div className='diagrammHeadTitle'>Accuracy per Detector</div>
                <div className="percentage-container">
                    <span className="percentage-value">{5}%</span>
                    <span className="percentage-label">The value from the detectors deviated by approximately 5% from the ground truth value (TomTom data) at that specific time interval</span>
                </div>
            </div>
            {
                params.location !== "Detectors" &&
                <div className='rawContainerDiagrams'>
                    <div className='diagrammHeadTitle'>Coverage</div>
                    <div className="percentage-container-Coverage">
                        <span className="percentage-value-Coverage">{90}%</span>
                    </div>
                </div>
            }
            {
                (params.location === "Detectors" && detectorStats)
                    ? <div className='rawContainerDiagrams'>
                        <div className='diagrammHeadTitle'>Detector Statistics</div>
                        <div className='rawContainerDiagrams'>
                            <div className="containerDetStats">
                                <span className='containerDetStatsTitle'>General Stats</span>
                                <div className='containerDetStatsBody'>
                                    <div className='statrow'>
                                        <span className="valueDetStats">{detectorStats[0].mean}%</span>
                                        <span className="labelDetStats">Mean</span>
                                    </div>
                                    <div className='statrow'>
                                        <span className="valueDetStats">{detectorStats[0].percentage_of_zero}%</span>
                                        <span className="labelDetStats">Median</span>
                                    </div>
                                    <div className='statrow'>
                                        <span className="valueDetStats">{detectorStats[0].iqr}%</span>
                                        <span className="labelDetStats">Iqr</span>
                                    </div>
                                </div>
                            </div>
                            <div className="containerDetStats">
                                <span className='containerDetStatsTitle'>Percentages of completeness</span>
                                <div className='containerDetStatsBody'>
                                    <div className='statrow'>
                                        <span className="valueDetStats">{detectorStats[0].percentage_of_completeness}%</span>
                                        <span className="labelDetStats">Completeness</span>
                                    </div>
                                    <div className='statrow'>
                                        <span className="valueDetStats">{detectorStats[0].percentage_of_zero}%</span>
                                        <span className="labelDetStats">zeros</span>
                                    </div>
                                    <div className='statrow'>
                                        <span className="valueDetStats">{detectorStats[0].percentage_of_invalid}%</span>
                                        <span className="labelDetStats">Invalid</span>
                                    </div>
                                </div>
                            </div>
                            <div className="containerDetStats">
                                <span className='containerDetStatsTitle'>Percentages of Flags</span>
                                <div className='containerDetStatsBody'>
                                    <div className='statrow'>
                                        <span className="valueDetStats">{detectorStats[0].percentage_flag_1}%</span>
                                        <span className="labelDetStats">Flag 1</span>
                                    </div>
                                    <div className='statrow'>
                                        <span className="valueDetStats">{detectorStats[0].percentage_flag_2}%</span>
                                        <span className="labelDetStats">Flag 2</span>
                                    </div>
                                    <div className='statrow'>
                                        <span className="valueDetStats">{detectorStats[0].percentage_flag_3}%</span>
                                        <span className="labelDetStats">Flag 3</span>
                                    </div>
                                    <div className='statrow'>
                                        <span className="valueDetStats">{detectorStats[0].percentage_flag_4}%</span>
                                        <span className="labelDetStats">Flag 4</span>
                                    </div>
                                    <div className='statrow'>
                                        <span className="valueDetStats">{detectorStats[0].percentage_flag_5}%</span>
                                        <span className="labelDetStats">Flag 5</span>
                                    </div>
                                    <div className='statrow'>
                                        <span className="valueDetStats">{detectorStats[0].percentage_flag_6}%</span>
                                        <span className="labelDetStats">Flag 6</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    : params.location!=="Detectors"?<></>:<div className='rawContainerCenteredH'><CircularProgress /></div>
            }
            {params.location === "Detectors" &&
                <div className='rawContainerDiagrams'>
                    <div className='diagrammHeadTitle'>Detectors List</div>
                    <DetectorsOverview params={params} />
                </div>
            }
        </Grid>
    );
};

export default DetectorComp;