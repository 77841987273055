
import * as React from "react";
import { useState, useEffect } from "react";
import { Map, NavigationControl, Popup, useControl } from "react-map-gl";
import { GeoJsonLayer } from "deck.gl";
import { MapboxOverlay as DeckOverlay } from "@deck.gl/mapbox";
import { CircularProgress, Backdrop } from "@mui/material";
import axios from "axios";
import "mapbox-gl/dist/mapbox-gl.css";
import config from "../../../config";

const MAPBOX_ACCESS_TOKEN = config.MAPBOX_ACCESS_TOKEN;
const API_TOKEN = config.MAP_MATCHING_API_TOKEN;
const API_URL = config.MAP_MATCHING_API_URL;
const INITIAL_VIEW_STATE = {
  latitude: 40.6310772242652,
  longitude: 22.953091034889667,
  zoom: 14,
  bearing: 0,
  pitch: 30,
};
const MAP_STYLE = "mapbox://styles/mapbox/dark-v11";

function DeckGLOverlay(props) {
  const overlay = useControl(() => new DeckOverlay(props));
  overlay.setProps(props);
  return null;
}

function MapView({ onSelect }) {
  const [selected, setSelected] = useState(null);
  const [hoverInfo, setHoverInfo] = useState(null);
  const [data, setData] = useState(null);
  const [zoomLevel, setZoomLevel] = useState(INITIAL_VIEW_STATE.zoom);
  const [cursor, setCursor] = useState("auto");
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Start loading
      try {
       
        const response = await axios.get(`${API_URL}/GetNetwork`, {
          headers: {
            "X-API-Key": API_TOKEN,
          },
        });
        setData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }finally {
        setLoading(false); // Stop loading
      }
    };

    fetchData();
  }, []);

  const handleLayerClick = (info) => {
    if (info && info.object) {
      if (selected === info.object) {
        setSelected([]);
        onSelect(null);
      } else {
        setSelected(info.object);
        onSelect(info.object);
      }
    }
  };

  const handleZoom = (event) => {
    const newZoomLevel = event.viewState.zoom;
    setZoomLevel(newZoomLevel);
  };

  const handleHover = (info) => {
    if (info && info.object) {
      setHoverInfo(info);
    } else {
      setHoverInfo(null);
    }
  };

  const layers = data
    ? [
        new GeoJsonLayer({
          id: "path-layer",
          data: data,
          filled: true,
          stroked: true,
          lineWidthMinPixels: zoomLevel > 15 ? 4 : 2,
          getLineWidth: zoomLevel > 15 ? 8 : 1,
          getPointRadius: zoomLevel > 15 ? 15 : 10,
          getLineColor: zoomLevel > 15 ? [58, 71, 149] : [135, 142, 189],
          pickable: true,
          autoHighlight: true,
          highlightColor: [0, 0, 0, 80],
          onClick: handleLayerClick,
          onHover: handleHover,
        }),
        selected
          ? new GeoJsonLayer({
              id: "selected-layer",
              data: {
                type: "FeatureCollection",
                features: [selected],
              },
              filled: true,
              stroked: true,
              lineWidthMinPixels: 4,
              getLineWidth: 8,
              getLineColor: [238, 43, 122],
            })
          : null,
      ].filter(Boolean)
    : [];

  return (
    <>
    <Backdrop open={loading} style={{ zIndex: 999, color: "#fff" }}>
    <CircularProgress color="inherit" />
    </Backdrop>
    <Map
      initialViewState={INITIAL_VIEW_STATE}
      mapStyle={MAP_STYLE}
      cursor={cursor}
      mapboxAccessToken={MAPBOX_ACCESS_TOKEN}
      onZoom={handleZoom}
      onViewStateChange={handleZoom}
    >
      {hoverInfo && zoomLevel > 15 && hoverInfo.object && (
        <Popup
          longitude={hoverInfo.coordinate[0]}
          latitude={hoverInfo.coordinate[1]}
          closeButton={false}
          anchor="bottom"
          style={{ zIndex: 10 }}
        >
          <div>
            <strong>{hoverInfo.object.properties.name}</strong> ({hoverInfo.object.properties.wayId}
            )
          </div>
        </Popup>
      )}
      <DeckGLOverlay layers={layers} />
      <NavigationControl position="top-left" />
    </Map>
    </>
  );
}

export default MapView;
