
import { Box, Typography, Paper, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import MDButton from "components/MDButton";
import dayjs from "dayjs";
import Icon from "@mui/material/Icon";

function DatesListView({ dates, selectedDateIndex, onEdit, onDelete, onSelect, OnAddDate }) {
  return (
    <Box p={3}>
      <Typography textAlign="center" variant="h4">Dates</Typography>
      {dates.map((date, index) => (
        <Paper
          key={index}
          sx={{
            p: 2,
            display: "flex",
            justifyContent: "space-between",
            mt: 2,
            cursor: "default",
            backgroundColor: index === selectedDateIndex ? "#E42A78" : "#1A2035", // Highlight selected item
            border: index === selectedDateIndex ? "2px solid #E42A78" : "1px solid lightgray", // Adds border to selected item
          }}
          onClick={() => onSelect(index)}
        >
          <Box>
            <Typography variant="body1" style={{color:"white"}}>
              {dayjs(date.startDate[0]).format("DD/MM/YYYY")} -
              {dayjs(date.endDate[0]).format("DD/MM/YYYY")}
            </Typography>
            <Typography variant="body2" style={{color:"white"}}>
              {date.title}
            </Typography>
          </Box>
          <Box>
            <IconButton color="info" onClick={() => onEdit(index)}>
              <EditIcon />
            </IconButton>
            <IconButton color="info" onClick={() => onDelete(index)}>
              <DeleteIcon />
            </IconButton>
          </Box>
        </Paper>
      ))}
      <MDButton variant="contained" color="info" size="medium" sx={{ mt: 5, ml:9 }} onClick={() => OnAddDate("add")}>
        <Icon>add</Icon>&nbsp;Add new date
      </MDButton>
    </Box>
  );
}

export default DatesListView;
