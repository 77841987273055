export const globalUrls = {
    //db_url: "http://localhost:5000" // development
    db_url: "https://sora.imet.gr/api" // production
}
//    db_url: "https://sora.imet.gr/api"

export const detectorModelCodes = {
    "yunex": 2,
    "scae": 1,
    "swarco": 3
}