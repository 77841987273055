import Box from '@mui/material/Box';
import React, { useEffect, useState } from "react";
import axios from 'axios';
//import config from "../config";
import Modal from '@mui/material/Modal';
import { height } from '@mui/system';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "100%",
    maxWidth: 1400,
    height: "100%",
    maxHeight: 800, 
    bgcolor: '#1a2035',
    border: '2px solid #1a2035',
    borderRadius: "5px",
    boxShadow: 24,
    p: 4,
};

const CreateAlertPanel = ({open, handleClose}) => {
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
            style={{ borderRadius: "5px", color: "white" }}
        >
            <Box sx={style}>
                <span>content</span>
            </Box>

        </Modal>
    );
};

export default CreateAlertPanel;