import { useEffect, useState } from "react";
import axios from "axios";
import config from "../../../../config";

const API_URL = config.TRAFFIC;

function formatTime(seconds) {
  if (!seconds || seconds < 0) return ""; // Handle empty or invalid values
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.round(seconds % 60);
  return minutes > 0 ? `${minutes} min ${remainingSeconds} sec` : `${remainingSeconds} sec`;
}


function transformAPI(apiData) {
  return apiData.map((data) => [
    {
      "id": data.id,
      "route_id": data.id_route,
      "titleID": data.id_route,
      "title": data.name ?? `Route${data.id_route}`,
      "metrics": [
        {
          name: "Average network speed",
          values: [
            { label: "Current", value: `${Math.round(data.avg_speed_current)} km/h`, status: data.avg_speed_current > data.avg_speed_typical ? "pass" : "notPass" },
            { label: "Typical", value: `${Math.round(data.avg_speed_typical)} km/h`, status: "neutral" },
            { label: "Forecasted", value: data.avg_speed_forecasted ? `${Math.round(data.avg_speed_forecasted)} km/h` : "", status: "neutral" }
          ]
        },
        {
          name: "Average network delay",
          values: [
            { label: "Delay", value: data.delay, status: data.delay > 0 ? "pass" : "notPass" },
            { label: "Forecasted", value: data.delay_forecasted ? data.delay_forecasted : "", status: "neutral" }
          ]
        },
        {
          name: "Average Network Travel Time / 10kms",
          values: [
            { label: "Current", value: formatTime(data.tt_current), status: data.tt_current > data.typical ? "pass" : "notPass" },
            { label: "Typical", value: formatTime(data.tt_typical), status: "neutral" },
            { label: "Forecasted", value: data.tt_forecasted ? formatTime(data.tt_forecasted) : "", status: "neutral" }
          ]
        },
        {
          name: "Traffic Congestion (%)",
          values: [
            { label: "Current", value: `${data.congestion_current} %`, status: data.congestion_current < data.congestion_typical ? "pass" : "notPass" },
            { label: "Typical", value: `${Math.round(data.congestion_typical)} %`, status: "neutral" },
            { label: "Forecasted", value: data.congestion_forecasted ? `${Math.round(data.congestion_forecasted)} %` : "", status: "neutral" }
          ]
        }
      ]
    }
  ]).flat();
}

const useRouteData = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        const response = await axios.get(`${API_URL}/latest_traffic_data_per_route`, {
          headers: { "Accept-Profile": "traffic_monitoring" }
        })

        // console.log("response", response);
        const formattedData = transformAPI(response.data);
        
        // const formattedData = transformAPI(routes);
        setData(formattedData);
        setLoading(false);

      } catch (err) {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return { data, loading };
};

export default useRouteData;