import React, { useState } from "react";
import axios from "axios";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import { Modal } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Timeline context
import { useTimeline } from "examples/Timeline/context";
// Otis Admin PRO React context
import { useMaterialUIController } from "context";

// Custom styles for the TimelineItem
import FilterLayout from "./filter/FilterLayout";
import UpdateEvent from "./update/updateEvent";
import config from "../../../../../config";

const API_TOKEN = config.MAP_MATCHING_API_TOKEN;
const API_URL = config.MAP_MATCHING_API_URL;

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#fff",
  boxShadow:
    "0rem 0.25rem 0.375rem -0.0625rem rgba(0, 0, 0, 0.1), 0rem 0.125rem 0.25rem -0.0625rem rgba(0, 0, 0, 0.06)",
  p: 4,
  border: "0 solid rgba(0, 0, 0, 0.125)",
  borderRadius: "0.75rem",
};

function EventList({
  noGutter,
  displayedEvents,
  setDisplayedEvents,
  // originalEvents,
  // setOriginalEvents,
  selectedEvent,
  setSelectedEvent,
  deleteEvent,
  setDeleteEvent,
  updateEvent,
  setUpdateEvent,
  filterTreeData,
}) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const isDark = useTimeline();
  // State for the "More" menu
  const [anchorEl, setAnchorEl] = useState(null);
  const [activeMenuId, setActiveMenuId] = useState(null);

  // Menu
  const handleOpenMenu = (event, id) => {
    setAnchorEl(event.currentTarget);
    setActiveMenuId(id);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setActiveMenuId(null);
  };

  const [editModalOpen, setEditModalOpen] = useState(false);

  // State for Confirmation Modal
  const [modalOpen, setModalOpen] = useState(false);

  const handlleModalDelete = (event) => {
    setDeleteEvent(event);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setDeleteEvent(null);
  };

  // Show Event
  const handleEventClick = (event) => {
    setSelectedEvent(event); // Update selected event for the map
  };

  // Edit Event
  const handleEditEvent = (event) => {
    setUpdateEvent(event);
    setEditModalOpen(true);
  };

  const handleCloseEditModal = () => {
    setEditModalOpen(false);
  };

  // Delete Event
  const handleDeleteEvent = async (event) => {
    if (!event) return;

    try {
      const eventID = event.id;

      await axios.delete(`${API_URL}/events/${eventID}`, {
        headers: {
          "X-API-Key": API_TOKEN,
        },
      });

      setDisplayedEvents((prevEvents) =>
        prevEvents.filter((e) => e.id !== eventID)
      );

      // setOriginalEvents((prevEvents) =>
      //   prevEvents.filter((e) => e.id !== eventID)
      // );

      setDeleteEvent(null); // Reset delete event state
    } catch (err) {
      console.error("Error fetching data:", err);
    } finally {
      if (selectedEvent) setSelectedEvent(null); // reset the selected event
      setModalOpen(false);
    }
  };

  return (
    <MDBox
      sx={{
        maxHeight: "80vh", // Set your desired height for the scroll area
        overflowY: "auto", // Enable vertical scrolling
        pr: 1, // Optional: Add some padding for better UX
      }}
    >
      {/* Modal Delete */}
      <Modal
        open={modalOpen}
        onClose={handleCloseModal}
        BackdropProps={{ style: { pointerEvents: "none" } }}
      >
        <MDBox sx={modalStyle}>
          <MDTypography color="secondary" variant="h6">
            Confirm Selection
          </MDTypography>
          <MDTypography color="secondary" sx={{ mt: 2 }}>
            Are you sure you want to delete this event?
          </MDTypography>
          <MDBox mt={3} display="flex" justifyContent="flex-end">
            <MDButton onClick={handleCloseModal} variant="text" color="primary">
              Cancel
            </MDButton>
            <MDButton
              color="secondary"
              onClick={() => handleDeleteEvent(deleteEvent)}
              variant="contained"
            >
              Confirm
            </MDButton>
          </MDBox>
        </MDBox>
      </Modal>

      {/* <FilterEvents /> */}
      <FilterLayout
        displayedEvents={displayedEvents}
        setDisplayedEvents={setDisplayedEvents}
        // originalEvents={originalEvents}
        selectedEvent={selectedEvent}
        setSelectedEvent={setSelectedEvent}
        filterTreeData={filterTreeData}
      />

      {displayedEvents.map((event) => (
        <MDBox key={event.id} position="relative" mb={3}>
          {/* Timeline icon */}
          <MDBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            bgColor={
              event.check_status.id === 1
                ? "success"
                : event.check_status.id === 2
                ? "info"
                : "warning" // Default color for other statuses
            }
            color="white"
            width="2rem"
            height="2rem"
            borderRadius="50%"
            position="absolute"
            top="8%"
            left="2px"
            zIndex={2}
            sx={{ fontSize: ({ typography: { size } }) => size.sm }}
          >
            <Icon fontSize="inherit">{event.icon || "event"}</Icon>
          </MDBox>

          {/* Event content */}
          <MDBox
            ml={5.75}
            pt={event.description ? 0.7 : 0.5}
            lineHeight={0}
            maxWidth="30rem"
          >
            <MDBox
              component="li"
              display="flex"
              justifyContent="space-between"
              alignItems="flex-start"
              bgColor={darkMode ? "transparent" : "grey-100"}
              borderRadius="lg"
              p={1}
              mb={noGutter ? 0 : 1}
            >
              <MDBox width="100%" display="flex" flexDirection="column">
                {/* Title and Actions */}
                <MDBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems={{ xs: "flex-start", sm: "center" }}
                  flexDirection={{ xs: "column", sm: "row" }}
                  mb={2}
                >

                  {/* Category / Subcategory */}
                  <MDBox
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-between"
                    alignItems={{ xs: "flex-start", sm: "start" }}
                  >
                    <MDTypography
                      variant="body2"
                      fontWeight="medium"
                      color={isDark ? "white" : "dark"}
                      component="a"
                      href="#"
                      sx={{ cursor: "pointer", textDecoration: "none" }}
                    >
                      {event.category?.name || ""}
                    </MDTypography>

                    {event.subcategory?.name && (
                      <MDTypography
                        variant="button"
                        fontWeight="light"
                        color={isDark ? "white" : "dark"}
                        sx={{ cursor: "pointer", textDecoration: "none" }}
                      >
                        {event.subcategory?.name || ""}
                      </MDTypography>
                    )}
                  </MDBox>
                 
                  {/* Actions */}
                  <MDBox sx={{ aligtnSelf: "flex-start" }}>
                    <MDButton
                      variant="text"
                      color={darkMode ? "white" : "dark"}
                      onClick={(e) => handleOpenMenu(e, event.id)}
                    >
                      <Icon>more_vert</Icon>
                    </MDButton>
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl) && activeMenuId === event.id}
                      onClose={handleCloseMenu}
                      keepMounted
                    >
                      <MenuItem onClick={() => handleEventClick(event)}>
                        <Icon fontSize="small">visibility</Icon>&nbsp;View
                      </MenuItem>
                      <MenuItem onClick={() => handleEditEvent(event)}>
                        <Icon fontSize="small">edit</Icon>&nbsp;Edit
                      </MenuItem>
                      <MenuItem onClick={() => handlleModalDelete(event)}>
                        <Icon fontSize="small">delete</Icon>&nbsp;Delete
                      </MenuItem>
                    </Menu>
                  </MDBox>
                </MDBox>

                {/* Type and Source on the Same Row */}
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <MDTypography variant="caption" color="text">
                      Type:&nbsp;
                      <MDTypography
                        variant="caption"
                        fontWeight="medium"
                        textTransform="capitalize"
                        color={
                          event.type_of_event?.name === "planned"
                            ? "success"
                            : "light"
                        }
                      >
                        {event.type_of_event?.name || "Unknown"}
                      </MDTypography>
                    </MDTypography>
                  </Grid>
                  {/* Source */}
                  <Grid item xs={6}>
                    <MDTypography variant="caption" color="text">
                      Source:&nbsp;
                      <MDTypography variant="caption" fontWeight="medium">
                        {/* {event.source || "N/A"} */}
                        {event.source?.name ? event.source.name : ""}
                      </MDTypography>
                    </MDTypography>
                  </Grid>
                  {/* Start Date */}
                  <Grid item xs={6}>
                    <MDTypography variant="caption" color="text">
                      Start:&nbsp;
                      <MDTypography variant="caption" fontWeight="medium">
                        {new Date(event.valid_from).toLocaleString("en-GB", {
                          day: "2-digit",
                          month: "2-digit",
                          year: "numeric",
                          hour: "2-digit",
                          minute: "2-digit",
                        })}
                      </MDTypography>
                    </MDTypography>
                  </Grid>
                  {/* End Date */}
                  <Grid item xs={6}>
                    <MDTypography variant="caption" color="text">
                      End:&nbsp;
                      <MDTypography variant="caption" fontWeight="medium">
                        {event.valid_until
                          ? new Date(event.valid_until).toLocaleString(
                              "en-GB",
                              {
                                day: "2-digit",
                                month: "2-digit",
                                year: "numeric",
                                hour: "2-digit",
                                minute: "2-digit",
                              }
                            )
                          : "-"}
                      </MDTypography>
                    </MDTypography>
                  </Grid>

                  {/* Status */}
                  <Grid item xs={6}>
                    <MDTypography variant="caption" color="text">
                      Status:&nbsp;
                      <MDTypography variant="caption" fontWeight="medium">
                        {event.check_status.name || "Default"}
                      </MDTypography>
                    </MDTypography>
                  </Grid>
                  {/* Verification */}
                  <Grid item xs={6}>
                    <MDTypography variant="caption" color="text">
                      Verification:&nbsp;
                      <MDTypography variant="caption" fontWeight="medium">
                        {event.verification === 1
                          ? "Yes"
                          : event.verification === 0
                          ? "No"
                          : ""}
                      </MDTypography>
                    </MDTypography>
                  </Grid>

                  {/* Last Modified */}
                  <Grid item xs={12}>
                    <MDTypography variant="caption" color="text">
                      Last Modify:&nbsp;
                      <MDTypography variant="caption" fontWeight="medium">
                        {new Date(event.updated_at).toLocaleString("en-GB", {
                          day: "2-digit",
                          month: "2-digit",
                          year: "numeric",
                          hour: "2-digit",
                          minute: "2-digit",
                        })}
                      </MDTypography>
                    </MDTypography>
                  </Grid>

                  {/* Description */}
                  <Grid item xs={12}>
                    <MDTypography variant="caption" color="text">
                      <div
                        dangerouslySetInnerHTML={{ __html: event.description }}
                      />
                    </MDTypography>
                  </Grid>
                </Grid>
              </MDBox>
            </MDBox>
          </MDBox>
          <Divider />
        </MDBox>
      ))}

      <UpdateEvent
        open={editModalOpen}
        onClose={handleCloseEditModal}
        updateEvent={updateEvent}
        setUpdateEvent={setUpdateEvent}
        setDisplayedEvents={setDisplayedEvents}
        // setOriginalEvents={setOriginalEvents}
      />
    </MDBox>
  );
}

export default EventList;
