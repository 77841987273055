import Box from '@mui/material/Box';
import MDBox from "components/MDBox";
import React, { useEffect, useRef, useState } from "react";
import "mapbox-gl/dist/mapbox-gl.css";
import mapboxgl from "mapbox-gl";
import config from "../config";



mapboxgl.accessToken = config.MAPBOX_ACCESS_TOKEN;

export default function SelectFromMapData({ castedData, type, selected, setSelected }) {

    const [loader, setLoader] = useState(true);
    const mapContainerRef = useRef(null); // Reference for the map container
    const mapRef = useRef(null); // Reference to store the Mapbox map instance
    const hoverPopupRef = useRef(new mapboxgl.Popup({ closeButton: false, closeOnClick: false }));
    const [allData, setAllData] = useState();

    const navControlAdded = useRef(false); // Track if NavigationControl is already added
    // change the cursor
    // const setCursor = (style) => {
    //     mapRef.current.getCanvas().style.cursor = style;
    // };

    // Helper function to remove a layer and source
    // const removeLayerAndSource = (id) => {
    //     if (mapRef.current.getLayer(id)) {
    //         mapRef.current.removeLayer(id);
    //         mapRef.current.removeSource(id);
    //     }
    // };

    // Helper function to handle hover layer interaction
    // const onHover = (layerId) => {
    //     mapRef.current.on("mousemove", layerId, (e) => {
    //         setCursor("pointer");
    //         const feature = e.features[0];
    //         if (feature) {
    //             const { category } = feature.properties;
    //             const showMessage = `<strong>${category}</strong>`;
    //             hoverPopupRef.current.setLngLat(e.lngLat).setHTML(showMessage).addTo(mapRef.current);
    //         }
    //     });

    //     mapRef.current.on("mouseleave", layerId, () => {
    //         setCursor("");
    //         hoverPopupRef.current.remove();
    //     });
    // };

    // Initialize the map when the component mounts
    useEffect(() => {
        if (mapContainerRef.current && !mapRef.current) {
            mapRef.current = new mapboxgl.Map({
                container: mapContainerRef.current,
                style: "mapbox://styles/mapbox/dark-v11",
                center: [22.9527, 40.6253], // Default center
                zoom: 12,
            });
        }

        if (mapRef.current) {
            // Ensure NavigationControl is added only once
            if (!navControlAdded.current) {
                // Add zoom and rotation controls to the map.
                mapRef.current.addControl(new mapboxgl.NavigationControl());
                navControlAdded.current = true; // Mark as added
            }
            const sourceId = `layer`;
            mapRef.current.on("load", () => {
                // Add a new source and layer
                mapRef.current.addSource(sourceId, {
                    type: "geojson",
                    data: castedData
                });
                if (type === "route") {
                    mapRef.current.addLayer({
                        id: sourceId,
                        type: "line",
                        source: sourceId,
                        layout: {
                            'line-join': 'round',
                            'line-cap': 'round'
                        },
                        paint: {
                            "line-color": [
                                "case",
                                ["in", ["get", "id"], ["literal", selected]], "#FF5733", // Selected color
                                "#007cbf" // Default color
                            ],
                            "line-width": 5,
                        }
                    });
                }else if(type === "speedRoutesTomTom" || type === "speedRoutesBTTaxi"){
                    mapRef.current.addLayer({
                        id: sourceId,
                        type: "line",
                        source: sourceId,
                        layout: {
                            'line-join': 'round',
                            'line-cap': 'round'
                        },
                        paint: {
                            "line-color": [
                                "case",
                                ["in", ["get", "id"], ["literal", selected]], "#FF5733", // Selected color
                                "#007cbf" // Default color
                            ],
                            "line-width": 5,
                        }
                    });
                }else {
                    mapRef.current.addLayer({
                        id: sourceId,
                        type: "circle",
                        source: sourceId,
                        paint: {
                            "circle-color": [
                                "case",
                                ["in", ["get", "id"], ["literal", selected]], "#FF5733", // Selected
                                "#007cbf" // Default
                            ],
                            "circle-radius": 6,
                        },
                    });
                }


                mapRef.current.on("mousemove", sourceId, (e) => {
                    if (e.features.length > 0) {
                        const feature = e.features[0];
                        const coordinates = e.lngLat;
                        const description = `<strong>ID:</strong> ${feature.properties.id}<br>
                                           <strong>Name:</strong> ${feature.properties.name}<br>`

                        hoverPopupRef.current
                            .setLngLat(coordinates)
                            .setHTML(description)
                            .addTo(mapRef.current);
                    }
                    mapRef.current.getCanvas().style.cursor = "pointer";
                });

                mapRef.current.on("mouseleave", sourceId, () => {
                    hoverPopupRef.current.remove();
                    mapRef.current.getCanvas().style.cursor = "";
                });

                mapRef.current.on("click", sourceId, (e) => {
                    const roadId = e.features[0]?.properties?.id;
                    console.log(roadId, "clicked")
                    if (!roadId) return;

                    if (type === "route") {
                        setSelected((prev) => {
                            const newSelection = prev.includes(roadId)
                                ? prev.filter((id) => id !== roadId)
                                : [roadId]; //[...prev, roadId];

                            mapRef.current.setPaintProperty(sourceId, "line-color", [
                                "case",
                                ["in", ["get", "id"], ["literal", newSelection]], "#FF5733",
                                "#007cbf"
                            ]);
                            return newSelection;
                        });
                    } else if(type === "speedRoutesTomTom" || type === "speedRoutesBTTaxi"){
                        setSelected((prev) => {
                            const newSelection = prev.includes(roadId)
                                ? prev.filter((id) => id !== roadId)
                                : [roadId]; //[...prev, roadId];

                            mapRef.current.setPaintProperty(sourceId, "line-color", [
                                "case",
                                ["in", ["get", "id"], ["literal", newSelection]], "#FF5733",
                                "#007cbf"
                            ]);
                            return newSelection;
                        });
                    }else if(type === "detector") {
                        console.log("detector")
                        setSelected((prev) => {
                            const newSelection = prev.includes(roadId)
                                ? prev.filter((id) => id !== roadId)
                                : [roadId];

                            mapRef.current.setPaintProperty(sourceId, "circle-color", [
                                "case",
                                ["in", ["get", "id"], ["literal", newSelection]], "#FF5733",
                                "#007cbf",
                            ]);

                            return newSelection;
                        });
                    }else{
                        console.log("intersection")
                        setSelected((prev) => {
                            const newSelection = prev.includes(roadId)
                                ? prev.filter((id) => id !== roadId)
                                : [roadId]; //[...prev, roadId];

                            mapRef.current.setPaintProperty(sourceId, "circle-color", [
                                "case",
                                ["in", ["get", "id"], ["literal", newSelection]], "#FF5733",
                                "#007cbf",
                            ]);

                            return newSelection;
                        });
                    }

                });

            });

        }
    }, [selected]);


    return (
        <MDBox id="map-preview-event" ref={mapContainerRef} sx={{ width: "100%", height: "500px" }} />
    )
}