import { useEffect, useState } from "react";
import { CircularProgress, Grid } from "@mui/material";
import MDBox from "components/MDBox";

import TrafficList from "./TrafficList";
import TrafficBar from "./TrafficBar";

import useAreaData from "../data/useAreaData";
import TrafficMonitoringMap from "./trafficMonitoringMap/TrafficMonitoringMap";

import useEventsData from "layouts/events/show/data/useEventsData";
import useTrafficMonitoringGeom from "../data/useTrafficMonitoringGeom";
import useAreaGeom from "../data/useAreaGeom";

function AreaView() {
  const { data, loading: areaLoading } = useAreaData(); // Area list
  const { events, loading: eventsLoading } = useEventsData(); // Events
  const { data: networkData, loading: networkDataLoading } = useTrafficMonitoringGeom(); // Road network
  const { data: areaDataPolygon, loading: areaDataPolygonLoading } = useAreaGeom(); // Area polygons

  const [selectedData, setSelectedData] = useState(null);

  useEffect(() => {
    if (!selectedData && data.length > 0) {
      setSelectedData(data[0]); // Set the first area as default
    }
  }, [data]);

  // Compute `networkSource` dynamically (no `useEffect`)
  const networkSource =
    networkData?.features && selectedData
      ? {
          type: "FeatureCollection",
          features: networkData.features.filter(
            (network) => network?.properties?.area_id === selectedData?.area_id
          ),
        }
      : null;

  // Compute `areaRectSource` dynamically (no `useEffect`)
  const areaRectSource =
    areaDataPolygon?.features && selectedData
      ? {
          type: "FeatureCollection",
          features: areaDataPolygon.features.filter(
            (area) => area?.properties?.area_id === selectedData?.area_id
          ),
        }
      : null;

  // Compute active/jam events dynamically
  const onlyActive =
    selectedData && events?.length
      ? events.filter(
          (event) =>
            event.check_status?.id === 1 &&
            (Array.isArray(event.link_id)
              ? event.link_id.includes(selectedData?.id)
              : event.link_id === selectedData?.id)
        )
      : [];

  const onlyJam = onlyActive.filter((event) => event.category?.id === 10);

  // Show loading spinner if data is not ready
  if (areaLoading || eventsLoading || networkDataLoading || areaDataPolygonLoading || !selectedData) {
    return (
      <Grid container justifyContent="center" alignItems="center" sx={{ height: "100%" }}>
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <Grid container spacing={2} sx={{ flexGrow: 1 }}>
      {/* Statistic List */}
      <Grid item xs={12} md={3}>
        <TrafficList data={selectedData?.metrics || []} />
        <TrafficList
          data={[
            { name: "No. of Events", values: [{ label: "Total", value: onlyActive.length, status: "neutral" }] },
            { name: "No. of Traffic Jams", values: [{ label: "Total", value: onlyJam.length, status: "neutral" }] },
          ]}
        />
      </Grid>

      {/* Map Display */}
      <Grid item xs={12} md={9}>
        <MDBox sx={{ height: "100%" }}>
          {/* Area Selection Bar */}
          <TrafficBar data={data} selectedData={selectedData} setSelectedData={setSelectedData} />
          {/* Pass dynamic data to the map */}
          <TrafficMonitoringMap 
            onlyActive={onlyActive} 
            networkSource={networkSource} 
            areaRectSource={areaRectSource} 
          />
        </MDBox>
      </Grid>
    </Grid>
  );
}

export default AreaView;
