/**
=========================================================
* Deck Map 
=========================================================
*/
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import mapboxgl from "mapbox-gl";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import "mapbox-gl/dist/mapbox-gl.css";
import axios from "axios"; // Axios for API requests
import { Modal, CircularProgress } from "@mui/material";
import config from "../../../../config";

mapboxgl.accessToken = config.MAPBOX_ACCESS_TOKEN;
const API_TOKEN = config.MAP_MATCHING_API_TOKEN;
const API_URL = config.MAP_MATCHING_API_URL;

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#fff",
  boxShadow:
    "0rem 0.25rem 0.375rem -0.0625rem rgba(0, 0, 0, 0.1), 0rem 0.125rem 0.25rem -0.0625rem rgba(0, 0, 0, 0.06)",
  p: 4,
  border: "0 solid rgba(0, 0, 0, 0.125)",
  borderRadius: "0.75rem",
};

function MapCreateEvent() {
  const navigate = useNavigate();
  const location = useLocation();
  const { formData } = location.state || {}; // Retrieve formData passed from EventForm
  const mapContainerRef = useRef(null);
  const mapRef = useRef(null);
  const drawRef = useRef(null);
  const [data, setData] = useState(null); // State to hold API data
  const [loading, setLoading] = useState(true); // State for loading
  const hoverPopupRef = useRef(new mapboxgl.Popup({ closeButton: false, closeOnClick: false }));
  const [eventPosition, setEventPosition] = useState(null); // State for storing event position
  const [isModalOpen, setModalOpen] = useState(false);

  const increaseControlSize = () => {
    const controls = document.querySelectorAll(".mapboxgl-ctrl-group button");
    controls.forEach((btn) => {
      btn.style.width = "50px";
      btn.style.height = "50px";
      btn.style.fontSize = "24px";
    });
  };

  const handleOpenModal = () => {
    console.log("Modal open triggered");
    setModalOpen(true);
  };
  const handleCloseModal = () => {
    if (drawRef.current && eventPosition) {
      // Remove the drawn feature
      drawRef.current.deleteAll();
      setEventPosition(null);
    }

    setModalOpen(false);
  };

  const handleConfirm = () => {

    const updatedFormData = {
      ...formData,
      locations: eventPosition,
    };
    navigate("/events/create", { state: { formData: updatedFormData } });
    setModalOpen(false);
  };

  // Helper function to handle hover layer interaction
  const onHover = (layerId) => {
    mapRef.current.on("mousemove", layerId, (e) => {
      const feature = e.features[0];
      if (feature) {
        const { name } = feature.properties;
        const showMessage = `<strong>${name}</strong>`;
        hoverPopupRef.current.setLngLat(e.lngLat).setHTML(showMessage).addTo(mapRef.current);
      }
    });

    mapRef.current.on("mouseleave", layerId, () => {
      hoverPopupRef.current.remove();
    });
  };

  useEffect(() => {
    // Fetch data from API
    const fetchData = async () => {
      try {
        const response = await axios.get(`${API_URL}/GetNetwork`, {
          headers: {
            "X-API-Key": API_TOKEN,
          },
        });
        setData(response.data); // Save API data to state
        setLoading(false); // Data is loaded
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false); // Stop loading even if there's an error
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    mapRef.current = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: "mapbox://styles/mapbox/dark-v11",
      center: [22.9527, 40.6253],
      zoom: 12,
    });

    drawRef.current = new MapboxDraw({
      displayControlsDefault: false,
      controls: {
        point: true,
        line_string: true,
        polygon: false,
        trash: true,
      },
    });

    if (!mapRef.current.hasControl(drawRef.current)) {
      mapRef.current.addControl(drawRef.current);
    }

    // Monitor active draw mode
    let activeMode = null;
    mapRef.current.on("draw.modechange", (e) => {
      activeMode = e.mode; // Update the active draw mode
    });

    mapRef.current.on("load", () => {
      increaseControlSize();
      mapRef.current.addSource("route", {
        type: "geojson",
        data,
      });

      // Add the layer
      if (!mapRef.current.getLayer("route")) {
        mapRef.current.addLayer({
          id: "route",
          type: "line",
          source: "route",
          layout: {
            "line-join": "round",
            "line-cap": "round",
          },
          paint: {
            "line-color": "#888",
            "line-width": 8,
            "line-opacity": 0.5,
          },
        });
      }
      onHover("route");

      // Event listener for cursor updates
      const updateCursor = (e) => {
        const features = mapRef.current.queryRenderedFeatures(e.point, {
          layers: ["route"],
        });

        if (activeMode === "draw_line_string" || activeMode === "draw_point") {
          // Drawing mode is active
          if (features.length) {
            mapRef.current.getCanvas().style.cursor = "crosshair";
          } else {
            mapRef.current.getCanvas().style.cursor = "not-allowed";
          }
        } else {
          // Default cursor when not drawing
          mapRef.current.getCanvas().style.cursor = "";
        }
      };
      // Attach cursor events
      mapRef.current.on("mousemove", updateCursor);
      mapRef.current.on("mouseleave", "route", () => {
        if (activeMode === "draw_line_string" || activeMode === "draw_point") {
          mapRef.current.getCanvas().style.cursor = "not-allowed";
        } else {
          mapRef.current.getCanvas().style.cursor = "";
        }
      });

      mapRef.current.on("draw.create", (e) => {
        const drawnFeature = e.features[0]; // The drawn geometry

        if (drawnFeature.geometry.type === "Point") {
          // Query features at the drawn point's location
          const features = mapRef.current.queryRenderedFeatures(
            mapRef.current.project(drawnFeature.geometry.coordinates), // Convert to screen coordinates
            { layers: ["route"] } // Query the route layer
          );

          if (features.length > 0) {
            const matchedFeature = features[0]; // Take the first matching feature
            const eventPositionDraw = {
              geometry: drawnFeature.geometry, // Geometry of the drawn feature
              PathID: matchedFeature.properties.wayId || "Unknown ID",
            };
            setEventPosition(eventPositionDraw);
            handleOpenModal();
          }
        } else if (drawnFeature.geometry.type === "LineString") {
          // Handle LineString geometry
          const lineCoordinates = drawnFeature.geometry.coordinates; // from draw
          const matchingRoutes = []; // Store matched routes without duplicates

          lineCoordinates.forEach((coordinate) => {
            const features = mapRef.current.queryRenderedFeatures(
              mapRef.current.project(coordinate), // convert to screen coordinates
              { layer: ["route"] } // Query the route layer
            );

            if (features.length > 0) {
              features.forEach((feature) => {
                if (feature.layer.id === "route") {
                  const pathId = feature.properties.wayId;
                  // Check if PathID is already in matchingRoutes

                  // Check if PathID is already in matchingRoutes
                  const existingRoute = matchingRoutes.find((route) => route.PathID === pathId);

                  if (!existingRoute) {
                    const eventPositionDraw = {
                      PathID: pathId || "Unknown ID",
                      geometry: feature.geometry, // Store geometry of the matched route
                    };
                    matchingRoutes.push(eventPositionDraw); // Add to matchingRoutes
                  } else {
                    existingRoute.geometry.coordinates.push(...feature.geometry.coordinates);
                  }
                }
              });
            }
          });

          if (matchingRoutes.length > 0) {
            // Combine PathIDs into a single string
            const combinedPathIDs = matchingRoutes.map((route) => route.PathID);

            // Create a single merged LineString object
            const mergedEventPosition = {
              geometry: {
                type: "LineString",
                coordinates: e.features[0].geometry.coordinates,
              },
              PathID: combinedPathIDs,
            };

            setEventPosition(mergedEventPosition);
            handleOpenModal();
          }
        }
      });
    });

    // clean up on component unmount
    return () => {
      if (mapRef.current) {
        if (mapRef.current.getLayer("route")) {
          mapRef.current.removeLayer("route");
        }
        if (mapRef.current.getSource("route")) {
          mapRef.current.removeSource("route");
        }
        mapRef.current.remove();
      }
    };
  }, [data]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={4}>
        <MDTypography variant="h3">Map Create Event</MDTypography>
      </MDBox>
      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
        BackdropProps={{ style: { pointerEvents: "none" } }}
      >
        <MDBox sx={modalStyle}>
          <MDTypography color="secondary" variant="h6">
            Confirm Selection
          </MDTypography>
          <MDTypography color="secondary" sx={{ mt: 2 }}>
            Are you sure you want to select this geometry?
          </MDTypography>
          <MDBox mt={3} display="flex" justifyContent="flex-end">
            <MDButton onClick={handleCloseModal} variant="text" color="primary">
              Cancel
            </MDButton>
            {/* <Button onClick={handleCloseModal} sx={{ mr: 1 }}>
              Cancel
            </Button> */}
            <MDButton color="secondary" onClick={handleConfirm} variant="contained">
              Confirm
            </MDButton>
          </MDBox>
        </MDBox>
      </Modal>
     
      <MDBox
        id="map-create-event"
        ref={mapContainerRef}
        sx={{
          width: "100%",
          height: "70vh",
          mt: 3,
          position: "relative",
        }}
      >
        {loading && (
          <MDBox
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(255, 255, 255, 0.8)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 1,
            }}
          >
            <CircularProgress />
          </MDBox>
        )}
      </MDBox>
    </DashboardLayout>
  );
}

export default MapCreateEvent;
