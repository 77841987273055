import { useEffect, useState } from "react";
import axios from "axios";
import config from "config";

const API_URL = config.TRAFFIC;

const useAreaGeom = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        const response = await axios.get(`${API_URL}/rpc/area_geom`, {
          headers: { "Accept-Profile": "traffic_monitoring" },
        });

        
        const formattedData = {
          type: "FeatureCollection",
          features: response.data,
        };
        
        setData(formattedData);
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return { data, loading };
};

export default useAreaGeom;
