import { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDBox from "components/MDBox";

import EventForm from "./components/EventForm";
import MapPreviewEvent from "./components/MapPreviewEvent";

function CreateEvent() {
  const [dataPreview, setDataPreview] = useState(null);

  return (
    <DashboardLayout>
      
      <MDBox mt={4} p={0}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={5}>
            <EventForm
              setDataPreview={setDataPreview}
            />
          </Grid>
          <Grid item xs={12} md={7}>
            <MapPreviewEvent
              dataPreview={dataPreview}
            />
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default CreateEvent;
