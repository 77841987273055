const config = {
    MAPBOX_ACCESS_TOKEN: window.env?.REACT_APP_MAPBOX_ACCESS_TOKEN || process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
    MAP_MATCHING_API_TOKEN: window.env?.REACT_APP_MAP_MATCHING_API_TOKEN || process.env.REACT_APP_MAP_MATCHING_API_TOKEN,
    MAP_MATCHING_API_URL: window.env?.REACT_APP_MAP_MATCHING_API_URL || process.env.REACT_APP_MAP_MATCHING_API_URL,
    KAFKA_CONNECTION: window.env?.REACT_APP_KAFKA_CONNECTION || process.env.REACT_APP_KAFKA_CONNECTION,
    MAP_MATCHING_API_URL_LOC: window.env?.REACT_APP_MAP_MATCHING_API_URL_LOC || process.env.REACT_APP_MAP_MATCHING_API_URL_LOC,
    TRAFFIC: window.env?.REACT_APP_TRAFFIC || process.env.REACT_APP_TRAFFIC,
    ENDPOINTS_URL: window.env?.REACT_APP_ENDPOINTS_URL || process.env.REACT_APP_ENDPOINTS_URL,
    MAPBOX_ACCESS_TOKEN_3D: window.env?.REACT_APP_MAPBOX_ACCESS_TOKEN_3D || process.env.REACT_APP_MAPBOX_ACCESS_TOKEN_3D,
    KEYCLOAK_URL: window.env?.REACT_APP_KEYCLOAK_URL || process.env.REACT_APP_KEYCLOAK_URL,
    KEYCLOAK_REALM: window.env?.REACT_APP_KEYCLOAK_REALM || process.env.REACT_APP_KEYCLOAK_REALM,
    KEYCLOAK_CLIENT_ID: window.env?.REACT_APP_KEYCLOAK_CLIENT_ID || process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
    KEYCLOAK_CLIENT_SECRET: window.env?.REACT_APP_KEYCLOAK_CLIENT_SECRET || process.env.REACT_APP_KEYCLOAK_CLIENT_SECRET ,
    SCENARIOMANAGER_URL: window.env?.REACT_APP_SCENARIOMANAGER_URL || process.env.REACT_APP_SCENARIOMANAGER_URL,
    AUTH_ELASTIC: window.env?.REACT_APP_AUTH_ELASTIC || process.env.REACT_APP_AUTH_ELASTIC 
  };
export default config;

