import { useEffect, useState } from "react";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { MenuItem, Select, FormControl, InputLabel, Box } from "@mui/material";
import bg from "../../../assets/images/icons/flags/United-Kingdom.png";
import el from "../../../assets/images/icons/flags/Greece.png";
import { styled } from "@mui/system";
const languageOptions = [
  {
    language: "English",
    code: "en",
    flag: bg
  },
  { language: "Ελληνικά", code: "gr",  flag: el }
];

  
const LanguageSelector = () => {

  const [language, setLanguage] = useState(i18next.language);
  const { t, i18n } = useTranslation();
  const selectedLanguage = languageOptions.find(lang => lang.code === language);
  const CustomSelect = styled(Select)({
    "&::before": {
      borderBottom: "none !important",
    },
    "&::after": {
      borderBottom: "none !important",
    },
    "&:hover:not(.Mui-disabled, .Mui-error):before": {
      borderBottom: "none !important",
    },
    "&:focus, &:active": {
      borderBottom: "none !important",
      outline: "none !important",
    }
  });
  
const CustomFormControl = styled(FormControl)({
    borderBottom: "0px solid #000",
    "&:focus, &:active": {
      borderBottom: "0px solid #000 !important",
      outline: "none !important",
    }
  });
  useEffect(() => {
    document.body.dir = i18n.dir(); //sets the body to ltr or rtl
  }, [i18n, i18n.language]);
  const changeLanguage = (lng) => {
    console.log("Language changed to:", lng);
    i18n.changeLanguage(lng);
  };
  const handleLanguageChange = (event) => {
    const selectedLanguage = event.target.value;
    setLanguage(selectedLanguage);
    i18next.changeLanguage(selectedLanguage);
  };
  return (
    <CustomFormControl variant="standard" size="medium" >
      <CustomSelect
        labelId="language-selector-label"
        value={language}
        onChange={handleLanguageChange}
        displayEmpty
        renderValue={() => (
        <Box display="flex" alignItems="center" gap={1}>
            <img src={selectedLanguage?.flag} alt={`${selectedLanguage?.language} flag`} width="30" height="25" />
        </Box>
        )}
        >
        {languageOptions.map(({ language, code, flag }) => (
        <MenuItem key={code} value={code} sx={{ padding: "10px 20px" }}>
            <Box display="flex" alignItems="center" gap={1}>
            <img src={flag} alt={`${language} flag`} width="30" height="25" />
            <span>{language}</span>
            </Box>
        </MenuItem>
        ))}
      </CustomSelect>
    </CustomFormControl>
  );
};

export default LanguageSelector;