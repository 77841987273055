import { useState } from "react";
import { Tabs, Tab, Box } from "@mui/material";
import MDBox from "components/MDBox";

function TrafficBar({ data, selectedData, setSelectedData }) {
  const sLdata = data.slice(0, 20);

  // console.log("TrafficBar data", sLdata);
  // console.log("TrafficBar selectedData", selectedData);

  const handleChange = (event, newValue) => {
    const selected = data.find((area) => area.id === newValue);
    if (selected) {
      setSelectedData(selected);
    }
  };

  return (
    <MDBox sx={{ width: "100%", overflowX: "auto" }}> 
      <Tabs
        value={selectedData?.id ?? sLdata[0]?.id ?? 0}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto" // Ensures scroll buttons appear when needed
        allowScrollButtonsMobile // Enables mobile scrolling
        aria-label="scrollable tabs"
        sx={{
          width: "100%", //  Makes sure tabs take full width
          "& .MuiTabs-scroller": { overflowX: "auto" }, //  Ensures scrolling
          "& .MuiTabs-flexContainer": { justifyContent: "flex-start" }, //  Prevents text from centering incorrectly
          "& .MuiTabs-scrollButtons": { display: "flex" }, //  Forces scroll buttons to be visible
          "& .Mui-disabled": { opacity: 0.3 }, //  Ensures disabled buttons are visible
        }}
      >
        {sLdata.map((area) => (
          <Tab
            key={area.id}
            label={area.title}
            value={area.id}
            sx={{
              textTransform: "none",
              minWidth: "120px", //  Ensures tabs have enough width
              maxWidth: "200px", //  Prevents excessive stretching
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          />
        ))}
      </Tabs>
    </MDBox>
  );
}

export default TrafficBar;
