import { useEffect, useState } from "react";
import axios from "axios";
// import config from "../../../config";
import config from "../../../../config";
// const API_URL = process.env.REACT_APP_TRAFFIC;
const API_URL = config.TRAFFIC;

const useTrafficMonitoringGeom = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        
        const response = await axios.get(`${API_URL}/rpc/traffic_monitoring_geom`, {
          headers: { "Accept-Profile": "traffic_monitoring" },
        });

        console.log("response data", response.data);
        
        const formattedData = {
          type: "FeatureCollection",
          features: response.data,
          // features: traffic_monitoring,
        };
        
        setData(formattedData);
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return { data, loading };
};

export default useTrafficMonitoringGeom;
