import { useState, useEffect, useRef, useCallback, useMemo } from "react";
import axios from "axios";
import config from "../../../../config";

const API_TOKEN = config.MAP_MATCHING_API_TOKEN;
const API_URL = config.MAP_MATCHING_API_URL;

// Utility function to determine if an event is active
const checkEventStatus2 = (validFrom, validUntil) => {
  // Current, Future, Past
  const currentDate = new Date();
  const startDate = new Date(validFrom);
  const endDate = validUntil ? new Date(validUntil) : null;


 if (endDate && endDate < currentDate) {
   return {
     id: 3,
     name: "Archived"
   };
}

if (startDate > currentDate) {
  return {
    id: 2,
    name: "Upcoming"
  };
}
  return {
    id: 1,
    name: "Active"
  };

};

const checkEventStatus = (validFrom, validUntil) => {
  const currentDate = new Date();
  const startDate = new Date(validFrom);
  const endDate = validUntil ? new Date(validUntil) : null;

  // Archived: If startDate is in the past AND endDate exists AND endDate is also in the past
  if (startDate < currentDate && endDate && endDate < currentDate) {
    return { id: 3, name: "Archived" };
  }

  // Upcoming: If startDate is in the future
  if (startDate > currentDate) {
    return { id: 2, name: "Upcoming" };
  }

  // Active: If startDate is in the past AND (endDate is in the future OR no endDate is provided)
  if (startDate < currentDate && (!endDate || endDate > currentDate)) {
    return { id: 1, name: "Active" };
  }

};


const useEventsData = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        setError(null);

        const response = await axios.get(`${API_URL}/events`, {
          headers: {
            "X-API-Key": API_TOKEN,
          },
        });

       // Add `isActive` property using the helper function
        // const check_status = response.data.map((event) => {          
        //   return {
        //     ...event,
        //     my_check: checkEventStatus(event.valid_from, event.valid_until),
        //   };
        // });

        // console.log("my_check", check_status);
        
        const processedEvents = response.data;
        // console.log("processedEvents", processedEvents);

        setEvents(processedEvents); // Update the ref directly
        setLoading(false); // Data is loaded
      } catch (err) {
        setError("Error loading events data.");
        setLoading(false);
      }
    }; // Empty dependency array ensures the callback is stable

    fetchData();
  }, []);

  return { events, loading, error };
};

export default useEventsData;
