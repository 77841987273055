import React, { useState } from "react";
import Icon from "@mui/material/Icon";
import MDButton from "components/MDButton";
import FilterModal from "./FilterModal";

function FilterLayout({
  displayedEvents,
  setDisplayedEvents,
  // originalEvents,
  selectedEvent,
  setSelectedEvent,
  filterTreeData,
}) {
  const [modalOpen, setModalOpen] = useState(false);
  const [activeFilters, setActiveFilters] = useState(["status-1"]); // Track active filters

  // Handle modal open/close
  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);

  // Handle filters update
  const handleFiltersUpdate = (filters) => {
    setActiveFilters(filters); // Save active filters for display or further processing
  };

  return (
    <>
      {/* Filter Button */}
      <MDButton
        variant="outlined"
        color="dark"
        onClick={handleOpenModal}
        sx={{ textTransform: "none" }}
      >
        <Icon sx={{ fontWeight: "bold", color: "secondary.main" }}>
          filter_alt
        </Icon>{" "}
      </MDButton>

      {/* Filter Modal */}
      {modalOpen && (
        <FilterModal
          displayedEvents={displayedEvents}
          setDisplayedEvents={setDisplayedEvents}          
          // originalEvents={originalEvents} // Original unfiltered events
          onClose={handleCloseModal} // Handle modal close
          onFiltersUpdate={handleFiltersUpdate}
          initialSelectedFilters={activeFilters} // Pass actvie fitlers to modal
          selectedEvent={selectedEvent}
          setSelectedEvent={setSelectedEvent}
          filterTreeData={filterTreeData}
        />
      )}
    </>
  );
}

export default FilterLayout;
