import React, { useEffect, useRef, useState } from "react";

import { Checkbox, FormControlLabel } from "@mui/material";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import config from "../../../../../config";

mapboxgl.accessToken = config.MAPBOX_ACCESS_TOKEN;

function setColorLine(limit, avgSpeed) {
  const rgbColor = {
    green: [18, 181, 26],
    orange: [230, 121, 5],
    red: [230, 35, 5],
    darkRed: [128, 17, 0],
  };
  let color = [];

  if (limit === 90) {
    if (avgSpeed > 60) {
      color = rgbColor.green;
    } else if (avgSpeed > 35) {
      color = rgbColor.orange;
    } else if (avgSpeed > 20) {
      color = rgbColor.red;
    } else {
      color = rgbColor.darkRed;
    }
  }

  if (limit === 50) {
    if (avgSpeed > 30) {
      color = rgbColor.green;
    } else if (avgSpeed > 18) {
      color = rgbColor.orange;
    } else if (avgSpeed > 10) {
      color = rgbColor.red;
    } else {
      color = rgbColor.darkRed;
    }
  }

  if (limit === 30) {
    if (avgSpeed > 22) {
      color = rgbColor.green;
    } else if (avgSpeed > 15) {
      color = rgbColor.orange;
    } else if (avgSpeed > 10) {
      color = rgbColor.red;
    } else {
      color = rgbColor.darkRed;
    }
  }
  return color;
}

function TrafficMonitoringMap({ onlyActive, networkSource, areaRectSource }) {
  const mapContainerRef = useRef(null);
  const mapRef = useRef(null);

  // State to manage checkboxes dynamically
  const [activeLayers, setActiveLayers] = useState({});
  const [dynamicLayers, setDynamicLayers] = useState([]);

  useEffect(() => {
    const layers = [];

    if (networkSource?.features?.length) {
      layers.push({ id: "network-layer", label: "Network" });
    }

    if (onlyActive?.length) {
      layers.push({ id: "events-group", label: "Events" });
    }

    if (areaRectSource?.features?.length) {
      layers.push({ id: "areaRect-layer", label: "Areas" });
    }

    setDynamicLayers(layers);
  }, [networkSource, onlyActive, areaRectSource]);

  useEffect(() => {
    const updatedLayers = dynamicLayers.reduce((acc, layer) => {
      acc[layer.id] = activeLayers[layer.id] ?? true; // Default to true
      return acc;
    }, {});

    setActiveLayers(updatedLayers);
  }, [dynamicLayers]);

  const handleLayerToggle = (layerId) => {
    if (!mapRef.current) return;

    if (layerId === "events-group") {
      // ✅ Toggle both "events-points" and "events-lines"
      const visibility =
        mapRef.current.getLayoutProperty("events-points", "visibility") ===
        "visible"
          ? "none"
          : "visible";

      ["events-points", "events-lines"].forEach((id) => {
        if (mapRef.current.getLayer(id)) {
          mapRef.current.setLayoutProperty(id, "visibility", visibility);
        }
      });

      setActiveLayers((prev) => ({
        ...prev,
        [layerId]: visibility === "visible",
      }));
    } else {
      const visibility =
        mapRef.current.getLayoutProperty(layerId, "visibility") === "visible"
          ? "none"
          : "visible";

      if (mapRef.current.getLayer(layerId)) {
        mapRef.current.setLayoutProperty(layerId, "visibility", visibility);
      }

      setActiveLayers((prev) => ({
        ...prev,
        [layerId]: visibility === "visible",
      }));
    }
  };

  const hoverPopupRef = useRef(
    new mapboxgl.Popup({ closeButton: false, closeOnClick: false })
  );
  const clickPopupRef = useRef(
    new mapboxgl.Popup({ closeButton: true, closeOnClick: true })
  );

  // Helper function to set cursor style
  const setCursor = (style) => {
    if (mapRef.current) {
      mapRef.current.getCanvas().style.cursor = style;
    }
  };

  // Helper function to handle hover interaction
  const onHover = (layerId) => {
    if (!mapRef.current) return;

    mapRef.current.on("mousemove", layerId, (e) => {
      setCursor("pointer");

      const feature = e.features[0];
      if (feature) {
        try {
          let showMessage = "";

          if (layerId === "network-layer") {
            console.log("feture hover", feature.properties);
            const speedLimit = feature.properties.speed_limit;
            const avgSpeed = feature.properties.avg_speed_paths;
            const areaId = feature.properties.area_id;
            const delay = feature.properties.delay;
            const drivethess = feature.properties.id_drivethess_paths;
            const nameCRS = feature.properties.nameCRS;
            const routeID = feature.properties.route_id;
            const time = feature.properties.timestamp;
            const ttPaths = feature.properties.tt_paths;

            showMessage = `
            Speed Limit: ${speedLimit} <br />
            Average Speed: ${avgSpeed.toFixed(0)} <br /> 
            Area: ${areaId} <br />
            Delay:  ${delay} <br />
            DriveThess: ${drivethess} <br />
            NameCRS: ${nameCRS} <br />
            Route: ${routeID} <br />
            Time: ${time} <br />
            TTPaths: ${ttPaths} <br /> 
            `;
          } else {
            const category = JSON.parse(feature.properties.category);
            showMessage = `<strong>${category.name}</strong>`;
          }

          hoverPopupRef.current
            .setLngLat(e.lngLat)
            .setHTML(showMessage)
            .addTo(mapRef.current);
        } catch (error) {
          console.error("Error displaying hover data:", error);
        }
      }
    });

    mapRef.current.on("mouseleave", layerId, () => {
      setCursor("");
      hoverPopupRef.current.remove();
    });
  };

  // Helper function to handle click interaction
  const onClick = (layerId) => {
    if (!mapRef.current) return;

    mapRef.current.on("click", layerId, (e) => {
      const feature = e.features[0];
      if (feature) {
        try {
          // Ensure JSON parsing is safe
          const category = feature.properties.category
            ? JSON.parse(feature.properties.category)
            : { name: "" };

          const type = feature.properties.type_of_event
            ? JSON.parse(feature.properties.type_of_event)
            : { name: "" };

          const status = feature.properties.check_status.name || "No Status";

          const clickEvent = { category, type, status };
          const showMessage = `<div>
          <h6>${clickEvent.category.name}</h6>        
          <p>${clickEvent.status}</p>
          <p>${clickEvent.type.name}</p>
          </div>`;

          clickPopupRef.current
            .setLngLat(e.lngLat)
            .setHTML(showMessage)
            .addTo(mapRef.current);

          // Modify the close button directly
          setTimeout(() => {
            const closeButton = document.querySelector(
              ".mapboxgl-popup-close-button"
            );
            if (closeButton) {
              closeButton.style.fontSize = "1.3rem";
            }
          }, 100);
        } catch (error) {
          console.error("Error parsing category properties:", error);
        }
      }
    });
  };

  // Initialize the map when the component mounts
  useEffect(() => {
    if (!mapRef.current) {
      mapRef.current = new mapboxgl.Map({
        container: mapContainerRef.current,
        style: "mapbox://styles/mapbox/dark-v11",
        center: [22.9527, 40.6253],
        zoom: 12,
      });
    }
  }, []);

  // when new data comes
  useEffect(() => {
    if (!mapRef.current) return;

    if (!mapRef.current.isStyleLoaded()) {
      mapRef.current.on("load", () => {
        if (areaRectSource) updateAreaRectMap(areaRectSource);
        if (networkSource) updateNetworkMap(networkSource);
        if (onlyActive) updateEventsMap(onlyActive);
      });
    } else {
      if (areaRectSource) updateAreaRectMap(areaRectSource);
      if (networkSource) updateNetworkMap(networkSource);
      if (onlyActive) updateEventsMap(onlyActive);
    }
  }, [areaRectSource, networkSource, onlyActive]);

  // areaRect
  const updateAreaRectMap = (updateAreaRectkData) => {
    // if (!activeLayers.area) return;
    if (!updateAreaRectkData?.features) return;

    const geojsonData = {
      type: "FeatureCollection",
      features: updateAreaRectkData.features
        .filter(
          (network) =>
            network.geometry && network.geometry.coordinates.length > 0
        )
        .map((network, index) => {
          const assignedColor = setColorLine(
            network.properties.speed_limit,
            network.properties.avg_speed_paths
          );
          return {
            type: "Feature",
            id: `areaRect-${index}`,
            geometry: network.geometry,
            properties: { ...network.properties, colorLine: assignedColor },
          };
        }),
    };
    // source
    if (!mapRef.current.getSource("areaRect-source")) {
      mapRef.current.addSource("areaRect-source", {
        type: "geojson",
        data: geojsonData,
      });
    } else {
      mapRef.current.getSource("areaRect-source").setData(geojsonData);
    }

    // layer
    if (!mapRef.current.getLayer("areaRect-layer")) {
      mapRef.current.addLayer({
        id: "areaRect-layer",
        type: "line",
        source: "areaRect-source",
        paint: {
          "line-width": 4,
          "line-color": [
            "case",
            ["has", "colorLine"],
            [
              "rgb",
              ["at", 0, ["get", "colorLine"]],
              ["at", 1, ["get", "colorLine"]],
              ["at", 2, ["get", "colorLine"]],
            ],
            ["rgb", 136, 136, 136], // Default to gray if missing
          ],
        },
      });

      onHover("areaRect-layer");
    }
  };
  // network Layer
  const updateNetworkMap = (updateNetworkData) => {
    // if (!activeLayers.network) return;
    if (!updateNetworkData?.features) return;

    const geojsonData = {
      type: "FeatureCollection",
      features: updateNetworkData.features
        .filter(
          (network) =>
            network.geometry && network.geometry.coordinates.length > 0
        )
        .map((network, index) => {
          const assignedColor = setColorLine(
            network.properties.speed_limit,
            network.properties.avg_speed_paths
          );
          return {
            type: "Feature",
            id: `network-${index}`,
            geometry: network.geometry,
            properties: { ...network.properties, colorLine: assignedColor },
          };
        }),
    };
    // source
    if (!mapRef.current.getSource("network-source")) {
      mapRef.current.addSource("network-source", {
        type: "geojson",
        data: geojsonData,
      });
    } else {
      mapRef.current.getSource("network-source").setData(geojsonData);
    }

    // layer
    if (!mapRef.current.getLayer("network-layer")) {
      mapRef.current.addLayer({
        id: "network-layer",
        type: "line",
        source: "network-source",
        paint: {
          "line-width": 4,
          "line-color": [
            "case",
            ["has", "colorLine"],
            [
              "rgb",
              ["at", 0, ["get", "colorLine"]],
              ["at", 1, ["get", "colorLine"]],
              ["at", 2, ["get", "colorLine"]],
            ],
            ["rgb", 136, 136, 136], // Default to gray if missing
          ],
        },
      });

      onHover("network-layer");
    }
  };
  // event layer
  const updateEventsMap = (eventsData) => {
    // if (!activeLayers.event) return;
    if (!eventsData) return;
    // Remove existing layers & sources before adding new ones
    removeAllLayersAndSources();

    const geojsonData = {
      type: "FeatureCollection",
      features: eventsData
        .filter((event) => event.geometry && event.geometry.coordinates)
        .map((event, index) => {
          const geometry =
            event.geometry.type === "Point"
              ? { type: "Point", coordinates: event.geometry.coordinates[0] }
              : event.geometry;

          return {
            type: "Feature",
            id: `event-${index}`,
            geometry,
            properties: { ...event, eventId: event.id },
          };
        }),
    };

    if (!mapRef.current.getSource("events-source")) {
      mapRef.current.addSource("events-source", {
        type: "geojson",
        data: geojsonData,
      });
    } else {
      mapRef.current.getSource("events-source").setData(geojsonData);
    }

    // Add a layer for points
    if (!mapRef.current.getLayer("events-points")) {
      mapRef.current.addLayer({
        id: "events-points",
        type: "circle",
        source: "events-source",
        filter: ["==", ["geometry-type"], "Point"],
        paint: {
          "circle-radius": 8,
          "circle-color": "#007cbf",
          "circle-opacity": 0.8,
        },
      });

      onHover("events-points");
      onClick("events-points");
    }

    // Add a layer for lines
    if (!mapRef.current.getLayer("events-lines")) {
      mapRef.current.addLayer({
        id: "events-lines",
        type: "line",
        source: "events-source",
        filter: ["==", ["geometry-type"], "LineString"],
        paint: {
          "line-color": "#007cbf",
          "line-width": 3,
        },
      });

      onHover("events-lines");
      onClick("events-lines");
    }
  };

  // Function to remove all layers and sources
  const removeAllLayersAndSources = () => {
    if (!mapRef.current) return;

    if (mapRef.current.getLayer("events-points")) {
      mapRef.current.removeLayer("events-points");
    }
    if (mapRef.current.getLayer("events-lines")) {
      mapRef.current.removeLayer("events-lines");
    }
    if (mapRef.current.getSource("events-source")) {
      mapRef.current.removeSource("events-source");
    }

    if (hoverPopupRef.current) hoverPopupRef.current.remove();
    if (clickPopupRef.current) clickPopupRef.current.remove();
  };

  return (
    <div style={{ position: "relative", width: "100%", height: "84vh" }}>
      {/* Checkboxes to Toggle Layers */}
      <div
        style={{
          position: "absolute",
          top: 10,
          right: 10,
          zIndex: 1000,
          backgroundColor: "rgba(32, 41, 64, 0.6)",
          padding: "10px",
          borderRadius: "5px",
        }}
      >
        {dynamicLayers.map((layer) => (
          <FormControlLabel
            key={layer.id}
            control={
              <Checkbox
                checked={!!activeLayers[layer.id]} // ✅ Use layer.id for checked state
                onChange={() => handleLayerToggle(layer.id)} // ✅ Toggle using id
              />
            }
            label={layer.label}
          />
        ))}
      </div>

      <div
        ref={mapContainerRef}
        style={{
          width: "100%",
          height: "100%",
        }}
      />
    </div>
  );
}

export default TrafficMonitoringMap;
