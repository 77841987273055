import { useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Reports from "./reports/Reports";
import { Icon } from "@mui/material";
import {decodeJwt } from "../../authentication/authModules";
import { useTranslation } from 'react-i18next';
function TrajectoriesAnalytics() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const token = localStorage.getItem("keycloak-token");
  let userId = null;
  if (token) {
    try {
      const tokenObject = decodeJwt(token);
      //console.log("Decoded Token:", token);
      userId = tokenObject?.sub || null; // Extract userId from token
    } catch (error) {
      console.error("Error decoding JWT:", error);
    }
  }
  return (
    <DashboardLayout>
    <Card>
        <MDBox p={3} lineHeight={1}>
            <MDTypography variant="h5" fontWeight="medium">
              
                Trajectories analytics
            </MDTypography>
            <MDTypography variant="button" color="text">
            {t('trajectories.subtitle')}
            </MDTypography>
        </MDBox>
    </Card>
    <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2} marginTop={2}>
    <MDButton
        color="info"
        onClick={() => {
          navigate("/trajectories/dates");
        }} > Start
      </MDButton>
          <MDBox display="flex">
            <MDBox ml={1}>
              <MDButton variant="outlined" color="info">
                <Icon>description</Icon>
                &nbsp;export csv
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
    <Card style={{ marginTop: '3px' }}>
      <Reports
      userId={userId}/>
      
    </Card>

</DashboardLayout>
  );
}

export default TrajectoriesAnalytics;
