import { useEffect, useRef } from "react";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import  config  from "../../../../config";

mapboxgl.accessToken = config.MAPBOX_ACCESS_TOKEN;

function MapPreviewEvent({ dataPreview }) {
  const mapContainerRef = useRef(null);
  const mapRef = useRef(null);
  const popupRef = useRef(new mapboxgl.Popup({ closeButton: true }));

  /**
   * Handle Cursor Change on Hover
   * and Enlarge Popup Close Button
   */
  const handleLayerHover = () => {
    if (!mapRef.current) return;

    const changeCursor = (layerId) => {
      // Change cursor to pointer on hover
      mapRef.current.on("mouseenter", layerId, () => {
        mapRef.current.getCanvas().style.cursor = "pointer";
      });

      // Revert cursor on mouse leave
      mapRef.current.on("mouseleave", layerId, () => {
        mapRef.current.getCanvas().style.cursor = "";
      });
    };

    // Apply cursor change on both layers
    changeCursor("preview-event-point");
    changeCursor("preview-event-line");
  };

  /**
   * Enlarge Popup Close Button
   */
  const enlargePopupCloseButton = () => {
    setTimeout(() => {
      const closeButton = document.querySelector(
        ".mapboxgl-popup-close-button"
      );
      if (closeButton) {
        closeButton.style.fontSize = "1.5rem";
        closeButton.style.fontWeight = "bold";
      }
    }, 100);
  };

  /**
   * Construct Popup Message
   * Conditionally add fields to message if they are not empty
   */
  const constructPopupMessage = ({
    categoryName,
    subcategoryName,
    eventType,
  }) => {
    let message = `<strong>Event Type:</strong> ${eventType}<br/>`;

    // Conditionally add category
    if (categoryName) {
      message += `<strong>Category:</strong> ${categoryName}<br/>`;
    }

    // Conditionally add subcategory
    if (subcategoryName) {
      message += `<strong>Subcategory:</strong> ${subcategoryName}<br/>`;
    }

    return message;
  };

  /**
   * Handle Click on Layers
   * This function is defined outside of useEffect for better readability and maintainability.
   */
  const handleLayerClick = () => {
    if (!mapRef.current) return;

    // Function to handle click event on a layer
    const onClickLayer = (layerId) => {
      mapRef.current.on("click", layerId, (e) => {
        const feature = e.features[0];
        if (feature) {
          const { categoryName, subcategoryName, eventType } = feature.properties;

          const message = constructPopupMessage({
            categoryName,
            subcategoryName,
            eventType,
          });

          // Close previous popup
          popupRef.current.remove();

          // Create new popup
          popupRef.current
            .setLngLat(e.lngLat)
            .setHTML(message)
            .addTo(mapRef.current);

          // Enlarge close button for better UX
          enlargePopupCloseButton();
        }
      });
    };

    // Attach click events for both Point and LineString layers
    onClickLayer("preview-event-point");
    onClickLayer("preview-event-line");
  };

  useEffect(() => {
    // Initialize the map only once
    if (!mapRef.current) {
      mapRef.current = new mapboxgl.Map({
        container: mapContainerRef.current,
        style: "mapbox://styles/mapbox/dark-v11",
        center: [22.9527, 40.6253], // Default center: Thessaloniki, Greece
        zoom: 12,
      });

      mapRef.current.on("load", () => {
        console.log("Map Loaded");

        // Add empty GeoJSON source for events
        mapRef.current.addSource("previewData", {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: [],
          },
        });

        // Add layers for points and lines but keep them empty
        mapRef.current.addLayer({
          id: "preview-event-point",
          type: "circle",
          source: "previewData",
          filter: ["==", "$type", "Point"],
          paint: {
            "circle-radius": 10,
            "circle-color": "#007cbf",
          },
        });

        mapRef.current.addLayer({
          id: "preview-event-line",
          type: "line",
          source: "previewData",
          filter: ["==", "$type", "LineString"],
          layout: {
            "line-join": "round",
            "line-cap": "round",
          },
          paint: {
            "line-color": "#007cbf",
            "line-width": 2,
          },
        });

        // Use the external function for handling layer clicks
        handleLayerClick();

        // Use the external function for handling hover cursor
        handleLayerHover();
      });
    }
  }, []);

  useEffect(() => {
    if (!mapRef.current || !mapRef.current.isStyleLoaded()) return;

    // Check if dataPreview has geometry
    if (dataPreview && dataPreview.geometry) {
      const { geometry, categoryName, subcategoryName, eventType } = dataPreview;

      // Prepare GeoJSON for preview
      const locationFeature = {
        type: "Feature",
        geometry: geometry,
        properties: {
          categoryName,
          subcategoryName,
          eventType,
        },
      };

      const geojsonData = {
        type: "FeatureCollection",
        features: [locationFeature],
      };

      // Update the GeoJSON source
      const source = mapRef.current.getSource("previewData");
      if (source) {
        source.setData(geojsonData);
      }

      // Construct the message with dynamic content
      const message = constructPopupMessage({
        categoryName,
        subcategoryName,
        eventType,
      });

      // Show Popup by Default
      if (geometry.type === "Point") {
        popupRef.current
          .setLngLat(geometry.coordinates)
          .setHTML(message)
          .addTo(mapRef.current);

        mapRef.current.flyTo({
          center: geometry.coordinates,
          zoom: 14,
          speed: 1.2,
        });
      } else if (geometry.type === "LineString") {
        const bounds = new mapboxgl.LngLatBounds();
        geometry.coordinates.forEach((coord) => bounds.extend(coord));
        mapRef.current.fitBounds(bounds, { padding: 20, duration: 1000 });

        const midIndex = Math.floor(geometry.coordinates.length / 2);
        const midpoint = geometry.coordinates[midIndex];

        popupRef.current
          .setLngLat(midpoint)
          .setHTML(message)
          .addTo(mapRef.current);
      }

      // Enlarge close button for better UX
      enlargePopupCloseButton();
    }
  }, [dataPreview]); // Re-run when dataPreview changes

  return (
    <div style={{ position: "relative", width: "100%", height: "90vh" }}>
      <div
        ref={mapContainerRef}
        style={{
          width: "100%",
          height: "100%",
        }}
      />
    </div>
  );
}

export default MapPreviewEvent;
