import axios from 'axios';
import React, { useEffect, useState } from 'react';
import BarTimeValue from '../Charts/BarTimeValue';
import { namedColor, transparentize } from 'Chart/ChartUtils';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from "@mui/material/Grid";
import config from '../../../../config';
import DecimationTimeValue from '../Charts/DecimetionTimeValue';
import ChartTimeValue from '../Charts/ChartTimeValue';
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import Icon from "@mui/material/Icon";
// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

const PublicTransportComp = ({ params }) => {



    const [chartData, setChartData] = useState();
    const [expected, setExpected] = useState();
    const [noRouteData, setNoRouteData] = useState(false)

    useEffect(() => {

        async function fetchData() {
            try {
                const start_date = new Date(params.dates.from).toISOString().slice(0, -5) + "Z";
                const end_date = new Date(params.dates.to).toISOString().slice(0, -5) + "Z";
                let getUrl = config.ENDPOINTS_URL + `/rpc/th_eta_per_busstop?start_time=${start_date}&end_time=${end_date}&bus_stops={${params.selectedDetectors[0].id.toString()}}`;
                let header = "oasth";
                const response = await axios(getUrl, {
                    headers: {
                        "Accept-Profile": header
                    }
                });
                if (!response.data || response.data.length === 0) {
                    setNoRouteData(true)
                } else {
                    setNoRouteData(false);
                    let data = response.data.map((item) => ({
                        x: new Date(item.lasttimestamp_rounded).toLocaleString('el-GR'), // Format date to be readable
                        y: item.eta_actual_diff,
                    }));
                    const chartD = {
                        datasets: [
                            {
                                label: params.selectedDetectors[0].name,
                                data: data,
                                backgroundColor: transparentize(namedColor(0), 0.5),
                                borderWidth: 0, // Removes the connecting line                            
                                tension: 0.4, // Line smoothing
                                minBarLength: 7, // Ensures bars are visible even when value is 0
                            }
                        ]
                    };
                    setChartData(chartD);
                }
                getUrl = config.ENDPOINTS_URL + `/rpc/th_eta_busstop_average_eta?start_time=${start_date}&end_time=${end_date}&bus_stops={${params.selectedDetectors[0].id.toString()}}`;
                const response2 = await axios(getUrl, {
                    headers: {
                        "Accept-Profile": header
                    }
                });
                if (response2.data) {
                    console.log(response2.data)
                    setExpected(response2.data.value)
                } else {
                    setExpected(null)
                }
            } catch (error) {
                console.log(error)
            }
        }


        fetchData();
    }, [params])

    return (
        <Grid container={true} p={2}>
            {
                expected ? <MDBox mb={3}>
                    <MiniStatisticsCard
                        title={{ text: "Expected Arrival" }}
                        count={expected + " min"}
                        icon={{ color: "info", component: "train" }}
                        direction="left"
                    />
                </MDBox> : null
            }
            <div className='rawContainerDiagrams'>
                {chartData ? <div className='detectorDiagramsContainer'><BarTimeValue chartData={chartData} title="Expected Arrival" /></div> : <div className='rawContainerCenteredH'>{noRouteData ? <span>No data for this bus stop or timeframe</span> : <CircularProgress />}</div>}
            </div>
        </Grid>
    );
};

export default PublicTransportComp;