import React from "react";
import { Modal } from "@mui/material";
import MDBox from "components/MDBox";
import UpdateForm from "./updateForm"; // Import the new form component
import axios from "axios";
import config from "../../../../../../config";

const API_TOKEN = config.MAP_MATCHING_API_TOKEN;
const API_URL = config.MAP_MATCHING_API_URL;

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "#344767",
  boxShadow: "0px 4px 12px rgba(0,0,0,0.2)",
  p: 4,
  borderRadius: "8px",
};

function UpdateEvent({
  open,
  onClose,
  updateEvent,
  setUpdateEvent,
  setDisplayedEvents,
  // setOriginalEvents,
}) {

  // console.log("inside modal", updateEvent)
  const handleSubmit = async (updatedEvent) => {
    if (!updatedEvent) return;

    // console.log("Before updatedEvent", updatedEvent);

    const updateEventData = {
      type_of_event: updatedEvent.type_of_event?.id,
      category: updatedEvent.category?.id || 1,
      subcategory: updatedEvent.subcategory?.id || 1,
      valid_from: updatedEvent.valid_from ? new Date(updatedEvent.valid_from).toISOString() : null, 
      valid_until: updatedEvent.valid_until ? new Date(updatedEvent.valid_until).toISOString() : null, 
      verification: updatedEvent.verification || 1, // Default to 1 if not provided
      user_id: updateEvent.user_id?.id,
      status: updateEvent.status?.id,
      source_id: updatedEvent.source?.id, // Extract ID      status: 1,
      geometry: updatedEvent.geometry || {}, // Pass geometry object as is
      link_id: updatedEvent.link_id || [], // Ensure link_id array is passed
      from_node: updatedEvent.from_node || "",
      to_node: updatedEvent.to_node || "",
      description: updatedEvent.description || "",
    };
    
    // console.log("updateEventData", updateEventData);

    try {
      const eventID = updatedEvent.id;

      const response = await axios.put(
        `${API_URL}/events/${updatedEvent.id}`,
        updateEventData,
        {
          headers: {
            "X-API-Key": API_TOKEN,
          },
        }
      );
        // Update displayedEvents without refreshing
        setDisplayedEvents((prevEvents) =>
          prevEvents.map((ev) =>
            ev.id === eventID ? { ...ev, ...updatedEvent } : ev
          )
        );

        // setOriginalEvents((prevEvents) =>
        //   prevEvents.map((ev) =>
        //     ev.id === eventID ? { ...ev, ...updatedEvent } : ev
        //   )
        // );

        setUpdateEvent(null); // Reset update state
        onClose(); // Close modal after success

      // Fetch update events after saving
    } catch (err) {
      console.error("Error fetching data:", err);
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <MDBox sx={modalStyle}>
        <UpdateForm
          updateEvent={updateEvent}
          setUpdateEvent={setUpdateEvent}
          onSubmit={handleSubmit}
          onCancel={onClose}
        />
      </MDBox>
    </Modal>
  );
}

export default UpdateEvent;
