import React from 'react';
import { useEffect, useState , useRef} from "react";
import { Grid } from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDBox from "components/MDBox";
import start from "assets/images/Synchro/smart_display.svg";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ListItemText from '@mui/material/ListItemText';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Icon from "@mui/material/Icon";
import Tooltip from '@mui/material/Tooltip';
import MDSnackbar from "components/MDSnackbar";


const CreateSumo = () => {


    const ITEM_HEIGHT = 70;
    const ITEM_PADDING_TOP = 2;
    const MenuProps = {
        PaperProps: {
            style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 300,
            },
        },
    };

    const names = [
    'Baseline',
    'Acyclic',
    'Nema'
    ];
    const videoRef = useRef(null);

    const [selectedStimulation, setSelectedStimulation] = useState(0);
    const [scenario, setScenario] = useState([]);
    const [live, setLive] = useState(false);
    const [successSB, setSuccessSB] = useState(false);
    const [errorSB, setErrorSB] = useState(false);
    const openSuccessSB = () => setSuccessSB(true);
    const closeSuccessSB = () => setSuccessSB(false);
    const openErrorSB = () => setErrorSB(true);
    const closeErrorSB = () => setErrorSB(false);
  
    const handleChange = (event) => {
      const {
        target: { value },
      } = event;
      setScenario(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value,
      );
    };
    const handlePickStimulation = (event) => {
        setSelectedStimulation(event.target.value);
    }
    const applyEnabled = () => {
        if(scenario.length > 1 || selectedStimulation === 'all'){
            return false;
        }
        return true;
    }

    useEffect(() => {
        if (!live) return;

        const ws = new WebSocket("ws://localhost:8765");
        ws.binaryType = "arraybuffer";
        const mediaSource = new MediaSource();
        
        const videoElement = videoRef.current;
        videoElement.src = URL.createObjectURL(mediaSource);

        mediaSource.addEventListener("sourceopen", () => {
            const mimeCodec = 'video/webm; codecs="vp8"';
            if (!MediaSource.isTypeSupported(mimeCodec)) {
                console.error("Codec not supported:", mimeCodec);
                return;
            }

            const sourceBuffer = mediaSource.addSourceBuffer(mimeCodec);
            let queue = [];

            ws.onmessage = (event) => {
                if (sourceBuffer.updating || queue.length > 0) {
                    queue.push(event.data);
                } else {
                    sourceBuffer.appendBuffer(event.data);
                }
            };

            sourceBuffer.addEventListener("updateend", () => {
                if (queue.length > 0) {
                    sourceBuffer.appendBuffer(queue.shift());
                }
            });
        });

        // ws.onclose = () => {
        //     console.log("WebSocket closed");
        //     if (mediaSource.readyState === "open") {
        //         mediaSource.endOfStream();
        //     }
        //     videoElement.src = "";
        // };

        // return () => {
        //     ws.close();
        //     if (mediaSource.readyState === "open") {
        //         mediaSource.endOfStream();
        //     }
        // };
    }, [live]);


    const sumoStart = () => {
        console.log("Start SUMO");
        try{
            setSuccessSB(true);
        }catch(err){
            setErrorSB(true);
        }
    }

    function bringSuccessNotification(){
        return (
            <MDSnackbar
              color="success"
              icon="check"
              title="Sumo notification"
              content="Hello from sumo! This is a notification message"
              dateTime={new Date().toLocaleTimeString()}
              open={successSB}
              onClose={closeSuccessSB}
              close={closeSuccessSB}
              bgWhite
            />
          );
    }

    function bringErrorNotification(){  
        return (
            <MDSnackbar
              color="error"
              icon="warning"
              title="Sumo notification"
              content="Hello from sumo! This is an error message"
              dateTime={new Date().toLocaleTimeString()}
              open={errorSB}
              onClose={closeErrorSB}
              close={closeErrorSB}
              bgWhite
            />
          );
    }


    return (

        <DashboardLayout>
            <MDBox mt={4} p={0}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                        <div className="notifications-container">
                            <div className="page-title" >Start SUMO</div>
                            <div className="create-container">
                                <div className="startsumo-container">
                                    <div className="sumofilters">
                                        <FormControl sx={{ m: 1, width: 250 }}>
                                            <InputLabel id="sumo-scenario">Select Scenario</InputLabel>
                                                <Select
                                                labelId="sumo-scenario"
                                                id="sumo-scenario"
                                                multiple
                                                value={scenario}
                                                onChange={handleChange}
                                                input={<OutlinedInput label="Select Scenario" />}
                                                renderValue={(selected) => selected.join(', ')}
                                                MenuProps={MenuProps}
                                                style={{ paddingTop: '12px', paddingBottom: '12px', paddingRight: '7px' }}
                                            >
                                            {names.map((name) => (
                                                <MenuItem key={name} value={name}>
                                                <Checkbox checked={scenario.includes(name)} />
                                                <ListItemText primary={name} />
                                                </MenuItem>
                                            ))}
                                            </Select>
                                        </FormControl>
                                        <FormControl style={{ marginLeft: '10px', width: '140px'}}>
                                            <InputLabel id="select-stimulation">Select stimulation</InputLabel>
                                            <Select
                                                key={"stimulation"}  // Force re-render when value changes
                                                labelId="select-stimulation"
                                                id="select-stimulation"
                                                label="Select stimulation" 
                                                value={selectedStimulation}
                                                onChange={handlePickStimulation}
                                                style={{ paddingTop: '12px', paddingBottom: '12px', paddingRight: '7px' }}
                                            >
                                                <MenuItem value={'0'}>0</MenuItem>
                                                <MenuItem value={'1'}>1</MenuItem>
                                                <MenuItem value={'2'}>2</MenuItem>
                                                <MenuItem value={'3'}>3</MenuItem>
                                                <MenuItem value={'4'}>4</MenuItem>
                                                <MenuItem value={'5'}>5</MenuItem>
                                                <MenuItem value={'6'}>6</MenuItem>
                                                <MenuItem value={'7'}>7</MenuItem>
                                                <MenuItem value={'8'}>8</MenuItem>
                                                <MenuItem value={'9'}>9</MenuItem>
                                                <MenuItem value={'all'}>All</MenuItem>
                                            </Select>
                                        </FormControl>
                                        {
                                            applyEnabled() 
                                            ?<FormControlLabel style={{width:"130px", marginLeft:'15px'}} control={<Checkbox checked={live} onClick={()=>{setLive(!live)}} />} label="Live Video" />
                                            :<></>
                                        }
                                        <Tooltip title="Live video isn't available in case of multiple scenarios or 'all' stimulation selected" placement="top">
                                            <Icon fontSize="medium" style={{marginLeft:"15px"}}>info</Icon>
                                        </Tooltip>
                                    </div>
                                    <img src={start} alt="start" style={{ cursor: "pointer", width: "45px", marginRight:"15px" }} onClick={sumoStart}/>
                                    {successSB && bringSuccessNotification()}
                                    {errorSB && bringErrorNotification()}
                                </div>
                            </div>
                            {
                                (applyEnabled() && live) && 
                                <div className="remote-display" >
                                    <div className="remote-display-title">Remote Display</div>
                                    <div className='remote-display-body'>
                                        { videoRef && <video ref={videoRef} style={{width:"100%", height:"100%"}} controls autoPlay></video>}

                                    </div>
                                </div>
                            }

                        </div>
                    </Grid>
                </Grid>
            </MDBox>
        </DashboardLayout>
    );
};

export default CreateSumo;