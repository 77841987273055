import React from 'react';
import { Line } from 'react-chartjs-2';
import zoomPlugin from 'chartjs-plugin-zoom';
import { Chart } from 'chart.js';

Chart.register(zoomPlugin);

const DecimationTimeValue = ({ chartData, title }) => {
    const decimation = {
        enabled: true,
        algorithm: 'min-max',
      };
      const options = {
          // Turn off animations and data parsing for performance
          animation: false,
          parsing: false,
      
          interaction: {
            mode: 'nearest',
            axis: 'x',
            intersect: false
          },
          plugins: {
            decimation: decimation,
            legend: {
                position: "top",
              },
              title: {
                display: true,
                text: title,
              },
              zoom: {
                pan: {
                  enabled: true,
                  mode: "x", // Allow only horizontal panning
                },
                zoom: {
                  wheel: {
                    enabled: true, // Enable zooming with scroll wheel
                  },
                  pinch: {
                    enabled: true, // Enable pinch-to-zoom for touch devices
                  },
                  drag:{
                    enabled: true,
                  },
                  mode: "x", // Allow zooming only on the X-axis
                }
              }
          },
          scales: {
            x: {
              type: 'time',
              ticks: {
                source: 'auto',
                // Disabled rotation for performance
                maxRotation: 0,
                autoSkip: true,
              },
              title: {
                display: true,
                text: "Timestamps",
              },
            },
            y: {
                type: "linear",
                title: {
                  display: true,
                  text: "Completeness (%)",
                },
                beginAtZero: true,
              },
          }
        };
        console.log(chartData, options)
    return (
        <div>
            <Line data={chartData} options={options} />
        </div>
    );
};

export default DecimationTimeValue;