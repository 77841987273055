import React from "react";
import PropTypes from "prop-types";
import DataTable from "examples/Tables/DataTable";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import useDataQualityDetectorsOverviewTable from "./useDataQualityDetectorsOverviewTable";
import CircularProgress from '@mui/material/CircularProgress';

function DetectorsOverview({params}) {
  const { tableData, loading, error } = useDataQualityDetectorsOverviewTable(params);

  if (loading) {
    return (
      <div className="rawContainerCenteredH">
          <CircularProgress />
      </div>
    );
  }

  if (error) {
    return (
      <MDBox p={3}>
        <MDTypography variant="h6" color="error" gutterBottom>
          Error: {error}
        </MDTypography>
      </MDBox>
    );
  }

  return (
    <div className="diagramContainer">
      <div className="scrollable-100">
        <DataTable
          table={tableData}
          canSearch
        />
      </div>
    </div>
  );
}


export default DetectorsOverview;
