import axios from "axios";

import listJunctionDataJSON from "../jsonData/list_junction.json";
import junctionDataJSON from "../jsonData/intersection_data.json";

// Group data by `junction_id`
const getUniqueIntersections = (data) => {
  const uniqueIntersections = {};
  data.forEach((item) => {
    if (!uniqueIntersections[item.junction_id]) {
      uniqueIntersections[item.junction_id] = item;
    }
  });
  const groupData = Object.values(uniqueIntersections);

  return groupData.map((d) => ({
    id: d.id,
    match_id: d.junction_id,
    junction_id: d.junction_id,
    name: d.name_junction,
    name_en: d.name_junction_en,
    geom: d.centroid,
  }));
};


const colorSourceType = (data) => {
  if (data === "turn_ratios") {
    return "#ffff00";
  } else if (data === "approaches") {
    return "#00ffff";
  } else {
    return "#ffffff";
  }
};


const convertToGeoJSON = (data) => {
  return {
    type: "FeatureCollection",
    features: data.flatMap((feature) => [
      // ✅ Line Feature
      {
        type: "Feature",
        properties: {
          id: feature.id,
          match_id: feature.junction_id,
          junction_id: feature.junction_id,
          name: feature.name,
          source_type: feature.source_type,
          metric_value: feature.metric_value,
          type: "line",
          color: colorSourceType(feature.source_type),
        },
        geometry: feature.geometry, // Line geometry
      },

      // ✅ Text Feature (Label)
      {
        type: "Feature",
        properties: {
          id: feature.id,
          match_id: feature.junction_id,
          junction_id: feature.junction_id,
          name: feature.name,
          value: feature.source_type === "approaches" ? `${feature.metric_value} vehicles/hr` : `${feature.metric_value} %` , 
          source_type: feature.source_type,
          type: "text",
          color: "#ffffff",
        },
        geometry: feature.centroid, // Place text at centroid
      },
    ]),
  };
};
// source_type === "approaches"  -- > metric_value %
// else  metric_value  vehicles/hr
  
// find the center
const getMidpoint = (coordinates) => {
  const midIndex = Math.floor(coordinates.length / 2);
  return coordinates[midIndex]; // Returns [lng, lat]
};

export const fetchJunctionsData = async () => {
  try {
    // Use direct imports instead of `axios.get()`
    const listJunctions = getUniqueIntersections(listJunctionDataJSON);
    const intersectionData = convertToGeoJSON(junctionDataJSON);

    console.log("listJunctions", listJunctions);

    return [listJunctions, intersectionData];
  } catch (error) {
    console.error("Error fetching Junctions Data:", error);
    return [[], []];
  }
};
