import { useState, useEffect } from "react";
import axios from "axios";
import config from "../../../config";

const API_TOKEN = config.MAP_MATCHING_API_TOKEN;
const API_URL = config.MAP_MATCHING_API_URL;

const ReportsData = (userId) => {
   
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        setError(null);
        //console.log(userId);
        //console.log(`${API_URL}/reports/${userId}`);
        const response = await axios.get(`${API_URL}/reports/${userId}`, {
            headers: {
              'X-API-Key': API_TOKEN
            }
          });
        setData(response.data); // Save API data to state
        setLoading(false); // Data is loaded
      } catch (err) {
        setError("Error loading events data.");
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return { data, loading, error };
};

export default ReportsData;
