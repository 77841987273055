import { useState, useEffect } from "react";
import axios from "axios";
import { globalUrls } from "modules/globals";
import { adjustDatesLast24Table } from "modules/formatDates";
import config from "../../../config";

const useDataQualityData = (params) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        setError(null);
        const formatedDates = adjustDatesLast24Table(params.dates.from, params.dates.to)
        console.log(formatedDates);
        const response = await axios.get(config.ENDPOINTS_URL+`/rpc/th_detectors_overview?start_date=${formatedDates.from}&end_date=${formatedDates.to}`, {
          headers: {
            "Accept-Profile": "detectors",
          },
        });
        setData(response.data); // Save API data to state
        console.log(response.data)
        setLoading(false); // Data is loaded
      } catch (err) {
        setError("Error loading events data.");
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return { data, loading, error };
};

export default useDataQualityData;
