class SelectionService {
  constructor() {
    this.selectedDateIndex = null;
    this.dates = []; // Store the list of dates

    this.selectedTimeIndex = null;
    this.times = []; // Store the list of times

    this.selectedLink = null; // Store the selected link

    this.reportName = ""; // Store the selected link
    this.response =  null;
  }

  resetSelection(){

    this.selectedDateIndex = null;
    this.dates = []; // Store the list of dates

    this.selectedTimeIndex = null;
    this.times = []; // Store the list of times

    this.selectedLink = null; // Store the selected link

    this.reportName = ""; // Store the selected link
    this.response =  null;

  }

  // Date Methods
  setSelectedDate(index) {
    this.selectedDateIndex = index;
  }

  getSelectedDate() {
    return this.selectedDateIndex;
  }

  setDates(dates) {
    this.dates = dates;
  }

  getDates() {
    return this.dates;
  }

  addDate(date) {
    this.dates.push(date);
  }

  deleteDate(index) {
    this.dates = this.dates.filter((_, i) => i !== index);
    if (this.selectedDateIndex === index) this.selectedDateIndex = null;
  }

  // Time Methods
  setSelectedTime(index) {
    this.selectedTimeIndex = index;
  }

  getSelectedTime() {
    return this.selectedTimeIndex;
  }

  setTimes(times) {
    this.times = times;
  }

  getTimes() {
    return this.times;
  }

  addTime(time) {
    this.times.push(time);
  }

  deleteTime(index) {
    this.times = this.times.filter((_, i) => i !== index);
    if (this.selectedTimeIndex === index) this.selectedTimeIndex = null;
  }

  // Link Methods
  setSelectedLink(link) {
    this.selectedLink = link;
  }

  getSelectedLink() {
    return this.selectedLink;
  }

  // name Methods
  setReportName(name) {
    this.reportName = name;
  }

  getReportName() {
    return this.reportName;
  }

  setResponse(incomingResponse) {

    this.response = incomingResponse;
  }

  getResponse() {
    return this.response;
  }

  getReportData(){
    console.log(this.response);
    console.log(this.selectedLink);
    console.log(this.reportName);
    return {
      features: this.response.features.map(feature => ({
          type: "Feature",
          properties: {
              wayId: feature.properties.wayId,
              count: feature.properties.count,
              summary: feature.properties.count, // Assuming summary should be the same as count
              percentage: feature.properties.percentage
          }
      })),
      summary: Math.max(...this.response.features.map(f => f.properties.count), 0), // Taking summary from the first feature
      primaryWayId: this.selectedLink.properties.wayId || null, // Taking wayId from the first feature
      userId: "6a5780b6-dac6-4f32-8f22-1f65009d7adc", // Static userId
      name: this.reportName
  };
}

}

export default new SelectionService();
