// src/layouts/events/create/components/EventForm.js
import { useNavigate, useLocation } from "react-router-dom";
import React, { useState, useEffect } from "react";
import axios from "axios";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";

// Otis Admin PRO React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";

// Settings page components
import FormField from "layouts/pages/account/components/FormField";
import MDEditor from "components/MDEditor";
import MDButton from "components/MDButton";
import useFieldsData from "./data/useFieldsData";
import MDDatePicker from "components/MDDatePicker";
import config from "../../../../../config";

const API_TOKEN = config.MAP_MATCHING_API_TOKEN;
const API_URL = config.MAP_MATCHING_API_URL;

function EventForm({ setDataPreview }) {
  const navigate = useNavigate();
  const location = useLocation();
  const { fields, loading, error } = useFieldsData(); // Fetch fields data

  // Default form data if location.state or formData is not provided
  const initialFormData = {
    type_of_event: null,
    category: null,
    subcategory: null,
    valid_from: null,
    valid_until: null,
    verification: null,
    user_id: "",
    geometry: {
      type: "", // e.g., "Point" or "LineString"
      coordinates: [], // Array of coordinates
    },
    link_id: [], // Array of IDs corresponding to the geometry
    from_node: "",
    to_node: "",
    description: "",
    source_id: null,
  };

  // Use the provided formData or fallback to the initial default
  const [formData, setFormData] = useState(
    location.state?.formData || initialFormData
  );
  const [errors, setErrors] = useState({});
  const [filteredSubcategories, setFilteredSubcategories] = useState([]);

  // Update formData if new data is passed via location.state
  useEffect(() => {
    if (location.state?.formData) {
      const { locations } = location.state.formData; // Extract locations
      if (locations) {
        setFormData((prev) => ({
          ...prev,
          geometry: locations.geometry, // Extract geometry from locations
          link_id: locations.PathID, // Extract PathID as link_id
        }));
      }
    }
  }, [location.state]);

  // Handle Date Selection
  const handleDateChange = (date, field) => {
    if (date && date[0]) {
      setFormData((prevData) => ({
        ...prevData,
        [field]: date[0].toISOString(), // Stores ISO format: YYYY-MM-DDTHH:mm:ss.sssZ
      }));

      setErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        delete newErrors[field];
        return newErrors;
      });
    }
  };

  // Handle input changes, including datetime-local
  const handleInputChange = (eventOrFieldName, newValue) => {
    let name, value;

    if (eventOrFieldName.target) {
      name = eventOrFieldName.target.name;
      value = eventOrFieldName.target.value;

      if (name === "valid_from" || name === "valid_until") {
        if (value) {
          const dateObj = new Date(value);
          if (!isNaN(dateObj.getTime())) {
            value = dateObj.toISOString().slice(0, 16); // Format for datetime-local
          }
        }
      }
    } else {
      name = eventOrFieldName;
      value = newValue?.id || null;
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      if (value) delete newErrors[name];
      return newErrors;
    });
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.type_of_event)
      newErrors.type_of_event = "Type of Event is required.";
    if (!formData.category) newErrors.category = "Category is required.";
    if (!formData.geometry.coordinates.length)
      newErrors.geometry = "Event location is required.";

    if (!formData.valid_from)
      newErrors.valid_from = "Start date-time is required.";

    if (formData.valid_until) {
      const startTime = new Date(formData.valid_from);
      const endTime = new Date(formData.valid_until);

      if (startTime >= endTime) {
        newErrors.valid_until =
          "End date-time must be later than start date-time.";
      }
    }

    // // If event type is "Planned", valid_until is required
    const plannedEvent = formData.type_of_event === 1;

    if (plannedEvent && !formData.valid_until) {
      // console.log("Valid until is empty", plannedEvent);
      newErrors.valid_until = "End date-time is required for Planned events.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleDescription = (value) => {
    setTimeout(() => {
      setFormData((prev) => ({ ...prev, description: value }));
    }, 0);
  };

  const handleOpenMap = () => {
    // console.log("Navigating to MapCreateEvent with formData:", formData);
    navigate("/events/create/map-create-event", { state: { formData } });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    console.log("handleSubmit formData", formData);

    // Ensure `link_id` is an array
    const linkIdArray = Array.isArray(formData.link_id)
      ? formData.link_id
      : [formData.link_id]; // Convert to array if it's not already
    // Normalize geometry coordinates

    // Normalize geometry coordinates
    const normalizedGeometry = { ...formData.geometry };

    if (formData.geometry.type === "Point") {
      // Wrap `Point` coordinates in an array to make it consistent
      normalizedGeometry.coordinates = [formData.geometry.coordinates];
      normalizedGeometry.type = "Point";
    }

    const { locations, ...filteredFormData } = formData;

    const submitFormData = {
      ...filteredFormData,
      link_id: linkIdArray,
      geometry: normalizedGeometry,
      status: 1,
      verification: 1,
      source_id: 3,
    };

     try {
      // Corrected Axios POST request
      await axios.post(
        `${API_URL}/events`, 
        submitFormData, 
        {
          headers: {
            "Content-Type": "application/json",
            "X-API-Key": API_TOKEN,
          },
        }
      );
  

      // **Navigate to /events/show after successful post**
      navigate("/events/show");
    } catch (error) {
      console.error("Error submitting form:", error);
      if (error.response) {
        console.error("Response status:", error.response.status);
        console.error("Response data:", error.response.data);
      } else {
        console.error("Request error:", error.message);
      }
      alert("Error submitting event. Please try again.");
    }
  };

  const handlePreview = (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    // Get Category Name
    const category = fields.categories.find(
      (cat) => cat.id === formData.category
    );
    const categoryName = category ? category.name : "";

    // Get Subcategory Name
    const subcategory = fields.subCategories.find(
      (sub) => sub.id === formData.subcategory
    );
    const subcategoryName = subcategory ? subcategory.name : "";

    // Determine Planned or Unplanned
    const isPlanned = formData.type_of_event === 1;
    const eventType = isPlanned ? "Planned" : "Unplanned";

    // Create enhanced preview data object
    const previewData = {
      ...formData,
      categoryName,
      subcategoryName,
      eventType,
    };

    console.log("Preview Data:", previewData);
    setDataPreview(previewData);
  };

  if (loading)
    return <MDTypography variant="h6">Loading fields...</MDTypography>;
  if (error)
    return (
      <MDTypography variant="h6" color="error">
        {error}
      </MDTypography>
    );

  return (
    <Card id="event-form" sx={{ overflow: "visible" }}>
      <MDBox p={3}>
        <MDTypography variant="h5">Entry of events & incidents</MDTypography>
      </MDBox>
      <MDBox compoent="form" pb={3} px={3}>
        <Grid container spacing={3}>
          {/* Type of Events */}
          <Grid item xs={12}>
            <MDTypography
              component="label"
              variant="button"
              fontWeight="regular"
              color="text"
            >
              Type of Event
            </MDTypography>
            <Autocomplete
              value={
                fields.typeOfEvents.find(
                  (type) => type.id === formData.type_of_event
                ) || null
              }
              options={fields.typeOfEvents}
              getOptionLabel={(option) => option.name}
              onChange={(e, selectedOption) =>
                handleInputChange("type_of_event", selectedOption)
              }
              renderInput={(params) => (
                <MDInput
                  {...params}
                  variant="standard"
                  error={!!errors.type_of_event}
                />
              )}
            />
            {errors.type_of_event && (
              <MDTypography variant="caption" color="error">
                {errors.type_of_event}
              </MDTypography>
            )}
          </Grid>

          {/* Category */}
          <Grid item xs={12}>
            <MDTypography
              component="label"
              variant="button"
              fontWeight="regular"
              color="text"
            >
              Category
            </MDTypography>
            <Autocomplete
              value={
                fields.categories.find((cat) => cat.id === formData.category) ||
                null
              }
              options={fields.categories}
              getOptionLabel={(option) => option.name}
              onChange={(e, selectedOption) => {
                handleInputChange("category", selectedOption);
                setFilteredSubcategories(
                  fields.subCategories.filter(
                    (sub) => sub.category_id === selectedOption?.id
                  ) || []
                );
              }}
              renderInput={(params) => (
                <MDInput
                  {...params}
                  variant="standard"
                  error={!!errors.category}
                />
              )}
            />
            {errors.category && (
              <MDTypography variant="caption" color="error">
                {errors.category}
              </MDTypography>
            )}
          </Grid>

          {/* Subcategory */}
          <Grid item xs={12} sm={12}>
            <MDTypography
              compoent="label"
              variant="button"
              fontWeight="regular"
              color="text"
              textTransform="capitalize"
            >
              Subcategory
            </MDTypography>
            <Autocomplete
              value={
                filteredSubcategories.find(
                  (sub) => sub.id === formData.subcategory
                ) || null
              }
              options={filteredSubcategories}
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(e, selectedOption) =>
                setFormData({
                  ...formData,
                  subcategory: selectedOption?.id || -1,
                })
              }
              renderInput={(params) => (
                <MDInput {...params} variant="standard" />
              )}
            />
          </Grid>

          {/* Valid_from */}
          <Grid item xs={12} sm={6} container direction="column">
            <MDTypography
              component="label"
              variant="button"
              fontWeight="regular"
              color="text"
            >
              Start date-time
            </MDTypography>
            <MDDatePicker
              input={{ placeholder: "dd/mm/yyyy HH:mm" }}
              value={formData.valid_from ? new Date(formData.valid_from) : null} // Ensures it's either a Date object or null
              options={{
                enableTime: true,
                dateFormat: "d/m/Y H:i",
                altFormat: "d/m/Y H:i",
                time_24hr: true,
              }}
              onChange={(date) => handleDateChange(date, "valid_from")}
            />
          </Grid>

          {/* Valid_until */}
          <Grid item xs={12} sm={6} container direction="column">
            <MDTypography
              component="label"
              variant="button"
              fontWeight="regular"
              color="text"
            >
              End date-time
            </MDTypography>
            <MDDatePicker
              input={{ placeholder: "dd/mm/yyyy HH:mm" }}
              value={
                formData.valid_until ? new Date(formData.valid_until) : null
              } // Ensures it's either a Date object or null
              options={{
                enableTime: true,
                dateFormat: "d/m/Y H:i",
                altFormat: "d/m/Y H:i",
                time_24hr: true,
              }}
              onChange={(date) => handleDateChange(date, "valid_until")}
            />
            {errors.valid_until && (
              <MDTypography variant="caption" color="error">
                {errors.valid_until}
              </MDTypography>
            )}
          </Grid>

          {/* Select Geometry */}
          <Grid item xs={12} sm={12}>
            <MDButton
              variant="gradient"
              color="secondary"
              onClick={handleOpenMap}
            >
              Select Event location
            </MDButton>
            {errors.geometry && (
              <MDTypography variant="caption" color="error">
                {errors.geometry}
              </MDTypography>
            )}
            {formData.geometry.coordinates.length > 0 && (
              <MDBox mt={2}>
                <MDTypography
                  variant="button"
                  fontWeight="light"
                  color="success"
                >
                  Geometry successfully selected!
                </MDTypography>
              </MDBox>
            )}
          </Grid>

          {/* From_node */}
          <Grid item xs={12} sm={6}>
            <FormField
              name="from_node"
              label="From node"
              value={formData.from_node}
              onChange={handleInputChange}
            />
          </Grid>

          {/* To_node */}
          <Grid item xs={12} sm={6}>
            <FormField
              name="to_node"
              label="To node"
              value={formData.to_node}
              onChange={handleInputChange}
            />
          </Grid>

          {/* Description */}
          <Grid item xs={12} sm={12}>
            <MDBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <MDTypography
                component="label"
                variant="button"
                fontWeight="regular"
                color="text"
              >
                Description&nbsp;&nbsp;
              </MDTypography>
            </MDBox>
            {/* <MDEditor
              value={formData.description}
              onChange={(value) =>
                handleInputChange({ target: { name: "description", value } })
              }
            /> */}
            <MDEditor
              value={formData.description || ""} // Prevents undefined issues
              onChange={handleDescription} // Uses the new function
            />
          </Grid>

          {/* Submit */}
          <Grid mt={3} item xs={12} sm={12}>
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <MDBox>
                <MDButton
                  variant="gradient"
                  color="secondary"
                  onClick={handlePreview}
                  sx={{ mr: 2 }} // Add right margin for spacing
                >
                  Preview
                </MDButton>

                <MDButton variant="contained" color="warning">
                  Import CSV
                </MDButton>
              </MDBox>

              <MDButton variant="gradient" color="info" onClick={handleSubmit}>
                Submit
              </MDButton>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}

export default EventForm;
