import { useState } from "react";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Card from "@mui/material/Card";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import NetworkView from "./components/NetworkView";
import RouteView from "./components/RouteView";
import AreaView from "./components/AreaView";

function TrafficMonitoring() {
  // Manage actvie tab state
  const [activeTab, setActiveTab] = useState("network");

  // Handle tab change
  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <DashboardLayout>
      <Card>
        <MDBox p={3} lineHeight={1}>
          <MDTypography variant="h5" fontWeight="medium">
            Traffic Monitoring
          </MDTypography>
          <MDTypography variant="button" color="text">
            Real-time collection and analysis of data
          </MDTypography>
        </MDBox>
      </Card>
      <Card sx={{ marginTop: "1rem", padding: "1rem" }}>
        <MDBox>
          {/* Material-UI Tabs */}
          <Tabs value={activeTab} onChange={handleChange} centered>
            <Tab label="Network" value="network" />
            <Tab label="Area" value="area" />
            <Tab label="Route" value="route" />
          </Tabs>

          {/* Render corresponding view based on active tab */}
          <MDBox  sx={{ marginTop: "3rem", display: "flex", height: "calc(100% - 50px)" }}>
            {activeTab === "network" && <NetworkView />}
            {activeTab === "area" && <AreaView />}
            {activeTab === "route" && <RouteView />}
          </MDBox>
        </MDBox>
      </Card>

      {/* container */}
    </DashboardLayout>
  );
}

export default TrafficMonitoring;
