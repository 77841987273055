import "../trajectories.css";
import * as React from 'react';
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import MDButton from "components/MDButton";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDSlider from "components/MDSlider";
import {
  Box,
  Grid,
  Typography,
  TextField,
  Card,
  CardContent,
  CardHeader,
} from "@mui/material";
import MapView from "../map/MapView";
import SelectionService from "../../../services/SelectionService";
import StepperNav from "../StepperNav";


function Link() {
  const [selectedSegment, setSelectedSegment] = useState(SelectionService.getSelectedLink());
  const [radius, setRadius] = useState(200);
  const [showRadius, setShowRadius] = useState(false);
  const navigate = useNavigate();

  const handleSelectSegment = (segment) => {
    setSelectedSegment(segment);
    SelectionService.setSelectedLink(segment);
  };

  const handleProceedToNextStep = () => {
    navigate("/trajectories/summary"); // Proceed to the next step
  };

  const handleProceedToPreviousStep = () => {
    navigate("/trajectories/times"); // Go back to the previous step
  };

  
  return (
<DashboardLayout>
  <Grid container direction="row" sx={{ alignItems: "stretch", height: "95vh" }}>
    {/* Sidebar */}
    <Box sx={{ width: "30%", height: "95vh", display: "flex", flexDirection: "column" }}>
      <Grid container direction="column" sx={{ flex: 1 }}>
        <Grid item xs={8} sx={{ flexGrow: 1 }}>
          <Box>
            <Typography variant="body1" color="HighlightText" sx={{ mt: 1 }}>Select Link </Typography>
            <Typography variant="body1" color="HighlightText" sx={{ mt: 7}}> </Typography>
          </Box>
          <Card sx={{ mb: 4 }}>
            <CardHeader title="Details" />
            <CardContent>
              {selectedSegment && (
                <Box>
                  <Typography color="HighlightText">Way ID: {selectedSegment.properties.wayId}</Typography>
                  <Typography color="HighlightText">Name: {selectedSegment.properties.name}</Typography>
                </Box>
              )}
            </CardContent>
          </Card>

          {/* <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <MDSlider
              value={radius}
              onChange={(e, newValue) => setRadius(newValue)}
              max={5000}
              defaultValue={200}
              min={100}
              step={20}
              primaryColor="#1A73E8" // Custom primary color
              thumbColor="#1A73E8" // Custom thumb color
            />
            <TextField
              value={radius}
              onChange={(e) => setRadius(Number(e.target.value))}
              type="number"
              inputProps={{ min: 100, max: 5000 }}
              sx={{ width: 100 }}
            />
            <Typography>m</Typography>
          </Box> */}
        </Grid>
        <Grid
          item
          xs="auto"
          sx={{
            mt: "auto", // Pushes the grid to the bottom of the container
            textAlign: "right", // Aligns buttons to the right
          }}
        >
         <Grid container justifyContent="flex-end" spacing={2}>
         <Grid item>
              <MDButton 
                variant="outlined"
                color="info"
                size="medium"  onClick={handleProceedToPreviousStep}>
                Back
              </MDButton>
              </Grid>
              <Grid item>
              <MDButton
                variant="contained"
                color="info"
                size="medium"
                disabled={selectedSegment === null} 
                onClick={handleProceedToNextStep}>
                Next
              </MDButton>
              </Grid>
              </Grid>
        </Grid>
      </Grid>
    </Box>

    {/* Map and Navigation */}
    <Grid container item sx={{ width: "70%", height: "95vh", padding: 0.5 }} direction="column">
      <Grid item>
        <StepperNav />
      </Grid>
      <Grid item sx={{ flex: 1 }}>
        <MapView onSelect={handleSelectSegment} />
      </Grid>
    </Grid>
  </Grid>
</DashboardLayout>
  );
}

export default Link;
