import React from 'react';
import { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDBox from "components/MDBox";


const LibrarySumo = () => {
    return (
        <DashboardLayout>
            <MDBox mt={4} p={0}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                        <div className="notifications-container">
                            <div className="page-title" >SUMO Library</div>

                        </div>
                    </Grid>
                </Grid>
            </MDBox>
        </DashboardLayout>
    );
};

export default LibrarySumo;