import React, { useState, useEffect } from 'react';
import Card from "@mui/material/Card";
import axios from "axios";
import { CircularProgress } from "@mui/material";
import Grid from "@mui/material/Grid";
import DetectorsOverview from '../tables/DetectorsOverview';
import config from '../../../../config';
import BarTimeValue from '../Charts/BarTimeValue';
import { detectorModelCodes } from 'modules/globals';
import ReactSpeedometer from "react-d3-speedometer";

const DetectorNetwork = ({ params }) => {

    const [chartData, setChartData] = useState()
    const [chartDataScae, setChartDataScae] = useState()
    const [chartDataSiemens, setChartDataSiemens] = useState()
    const [chartDataSwarco, setChartDataSwarco] = useState()

    const [error, setError] = useState(false)
    const [validityGause, setValidityGause] = useState()
    const [invalidScae, setInvalidScae] = useState()
    const [invalidSwarco, setInvalidSwarco] = useState()
    const [invalidSiemens, setInvalidSiemens] = useState()


    const flagsTitle = {
        "1": "occupancy=255",
        "2": "flow<=-1",
        "3": "speed<=-1",
        "4": "occupancy<=-1",
        "5": "flow=0 & occupancy>0",
        "6": "flow>0 & occupancy=0",
    }

    useEffect(() => {
        async function fetchData() {
            try {
                let getUrl = "";
                if (params.detector === "all") {
                    getUrl = config.ENDPOINTS_URL + `/rpc/th_completeness_per_provider_materialized_dynamic?start_time=${params.dates.from}&end_time=${params.dates.to}&&provider_filter={Siemens,SCAE,Swarco}`
                } else if (params.detector === "yunex") {
                    getUrl = config.ENDPOINTS_URL + `/rpc/th_completeness_per_provider_materialized_dynamic?start_time=${params.dates.from}&end_time=${params.dates.to}&&provider_filter={Siemens}`
                } else if (params.detector === "scae") {
                    getUrl = config.ENDPOINTS_URL + `/rpc/th_completeness_per_provider_materialized_dynamic?start_time=${params.dates.from}&end_time=${params.dates.to}&&provider_filter={SCAE}`
                } else {
                    getUrl = config.ENDPOINTS_URL + `/rpc/th_completeness_per_provider_materialized_dynamic?start_time=${params.dates.from}&end_time=${params.dates.to}&&provider_filter={Swarco}`
                }
                const response = await axios(getUrl, {
                    headers: {
                        "Accept-Profile": "detectors"
                    }
                });
                if (params.detector === "all") {
                    const Siemens = response.data.Siemens.map((item) => ({
                        x: new Date(item.ets).toLocaleString('el-GR'), // Format date to be readable
                        y: item.completeness,
                    }));

                    const SCAE = response.data.SCAE.map((item) => ({
                        x: new Date(item.ets).toLocaleString('el-GR'),
                        y: item.completeness,
                    }));
                    const Swarco = response.data.Swarco.map((item) => ({
                        x: new Date(item.ets).toLocaleString('el-GR'), // Format date to be readable
                        y: item.completeness,
                    }));

                    const allTimestampsSet = new Set([
                        ...SCAE.map(item => item.x),
                        ...Siemens.map(item => item.x),
                        ...Swarco.map(item => item.x)
                    ]);
                    const allTimestamps = Array.from(allTimestampsSet).sort();

                    const normalizeData = (dataset, allTimestamps) => {
                        const map = new Map(dataset.map(item => [item.x, item.y]));

                        return allTimestamps.map(timestamp => ({
                            x: timestamp,
                            y: map.has(timestamp) ? map.get(timestamp) : null // or 0 if you prefer
                        }));
                    };
                    const normalizedSCAE = normalizeData(SCAE, allTimestamps);
                    const normalizedSiemens = normalizeData(Siemens, allTimestamps);
                    const normalizedSwarco = normalizeData(Swarco, allTimestamps);

                    const chartD = {
                        datasets: [
                            {
                                label: "SCAE Completeness",
                                data: normalizedSCAE,
                                //borderWidth: 0, // Removes the connecting line                            
                                backgroundColor: "rgba(255, 99, 132, 1)", // Point fill color
                                //tension: 0.4, // Line smoothing
                            },

                            {
                                label: "Siemens Completeness",
                                data: normalizedSiemens,
                                //borderWidth: 0, // Removes the connecting line                            
                                backgroundColor: "rgba(194, 91, 235, 0.85)", // Point fill color
                                //tension: 0.4, // Line smoothing
                            },
                            {
                                label: "Swarco Completeness",
                                data: normalizedSwarco,
                                //borderWidth: 0, // Removes the connecting line                            
                                backgroundColor: "rgba(75, 192, 192, 1)", // Point fill color
                                //tension: 0.4, // Line smoothing
                            }
                        ],
                    };

                    const chartSiemens = {
                        datasets: [
                            {
                                label: "Siemens Completeness",
                                data: Siemens,
                                borderWidth: 0, // Removes the connecting line                            
                                backgroundColor: "rgba(194, 91, 235, 0.85)", // Point fill color
                                tension: 0.4, // Line smoothing
                            }
                        ]
                    };
                    const chartScae = {
                        datasets: [
                            {
                                label: "SCAE Completeness",
                                data: SCAE,
                                borderWidth: 0, // Removes the connecting line                            
                                backgroundColor: "rgba(255, 99, 132, 1)", // Point fill color
                                tension: 0.4, // Line smoothing
                            }
                        ],
                    };
                    const chartSwarco = {
                        datasets: [
                            {
                                label: "Swarco Completeness",
                                data: Swarco,
                                borderWidth: 0, // Removes the connecting line                            
                                backgroundColor: "rgba(75, 192, 192, 1)", // Point fill color
                                tension: 0.4, // Line smoothing
                            }
                        ],
                    };
                    setChartData(chartD)
                    setChartDataSiemens(chartSiemens)
                    setChartDataScae(chartScae)
                    setChartDataSwarco(chartSwarco)

                } else if (params.detector === "yunex") {
                    const Siemens = response.data.Siemens.map((item) => ({
                        x: new Date(item.ets).toLocaleString('el-GR'), // Format date to be readable
                        y: item.completeness,
                    }));
                    const chartD = {
                        datasets: [
                            {
                                label: "Siemens Completeness",
                                data: Siemens,
                                borderWidth: 0, // Removes the connecting line                            
                                backgroundColor: "rgba(75, 192, 192, 0.2)", // Point fill color
                                tension: 0.4, // Line smoothing
                            }
                        ]
                    };
                    setChartData(chartD)
                } else if (params.detector === "scae") {
                    const SCAE = response.data.SCAE.map((item) => ({
                        x: new Date(item.ets).toLocaleString('el-GR'), // Format date to be readable
                        y: item.completeness,
                    }));
                    const chartD = {
                        datasets: [
                            {
                                label: "SCAE Completeness",
                                data: SCAE,
                                borderWidth: 0, // Removes the connecting line                            
                                backgroundColor: "rgba(75, 192, 192, 0.2)", // Point fill color
                                tension: 0.4, // Line smoothing
                            }
                        ]
                    };
                    setChartData(chartD)
                    setChartDataScae(chartD)
                } else {
                    const Swarco = response.data.Swarco.map((item) => ({
                        x: new Date(item.ets).toLocaleString('el-GR'), // Format date to be readable
                        y: item.completeness,
                    }));
                    const chartD = {
                        datasets: [
                            {
                                label: "Swarco Completeness",
                                data: Swarco,
                                borderWidth: 0, // Removes the connecting line                            
                                backgroundColor: "rgba(75, 192, 192, 0.2)", // Point fill color
                                tension: 0.4, // Line smoothing
                            }
                        ]
                    };
                    setChartData(chartD)
                    setChartDataSwarco(chartD)
                }
            } catch (err) {
                console.log(err)
                setError(true)
            }
        }

        async function fetchValidityData(detectorModel) {
            try {
                let getUrl = "";
                const modelCode = detectorModelCodes[detectorModel];
                getUrl = config.ENDPOINTS_URL + `/rpc/th_validity_per_provider_materialized?in_start_timestamp=${params.dates.from}&in_end_timestamp=${params.dates.to}&in_detector_model_id=${modelCode}`
                const response = await axios(getUrl, {
                    headers: {
                        "Accept-Profile": "detectors"
                    }
                });
                setValidityGause(response.data)
            } catch (err) {
                console.log(err)
            }
        }

        async function fetchPercentageInvalid() {
            try {
                let getUrl = "";
                getUrl = config.ENDPOINTS_URL + `/rpc/th_validity_per_provider_materialized_all?in_start_timestamp=${params.dates.from}&in_end_timestamp=${params.dates.to}&in_detector_model_id=1`
                const response1 = await axios(getUrl, {
                    headers: {
                        "Accept-Profile": "detectors"
                    }
                });
                setInvalidScae(response1.data)
                getUrl = config.ENDPOINTS_URL + `/rpc/th_validity_per_provider_materialized_all?in_start_timestamp=${params.dates.from}&in_end_timestamp=${params.dates.to}&in_detector_model_id=2`
                const response2 = await axios(getUrl, {
                    headers: {
                        "Accept-Profile": "detectors"
                    }
                });
                setInvalidSiemens(response2.data)
                getUrl = config.ENDPOINTS_URL + `/rpc/th_validity_per_provider_materialized_all?in_start_timestamp=${params.dates.from}&in_end_timestamp=${params.dates.to}&in_detector_model_id=3`
                const response3 = await axios(getUrl, {
                    headers: {
                        "Accept-Profile": "detectors"
                    }
                });
                setInvalidSwarco(response3.data)
            } catch (err) {
                console.log(err)
            }
        }
        fetchData();
        if (params.detector !== "all") {
            fetchValidityData(params.detector)
        } else {
            fetchPercentageInvalid()
        }
    }, [])

    return (
        <Card style={{ marginTop: '3px', padding: '12px', height: '100%' }}>
            <Grid container item xs={12} md={12}>
                <Grid container={true} p={2}>
                    {
                        (params.detector === "all" || params.location === "Network") &&
                        <div className='rawContainerDiagrams'>
                            <div className='diagrammHeadTitle'>Completeness per Data Source</div>
                            {chartData ? <div className='detectorDiagramsContainer'><BarTimeValue chartData={chartData} title="Completeness over Time" /></div> : <div className='rawContainerCenteredH'><CircularProgress /></div>}
                        </div>
                    }
                    <div className='rawContainerDiagrams'>
                        <div className='diagrammHeadTitle'>Coverage on Network</div>
                        <div className="percentage-container-VT">
                            <span className="percentage-value-VT">{43.33}%</span>
                            <span className="percentage-label-VT">We are monitoring through installed loop detectors in real-time 78 signalized intersections out of 180 (critical) signalized intersections. The total number of signalized intersections in the city of Thessaloniki are 350.</span>
                        </div>
                    </div>

                    {
                        params.detector !== "all" &&
                        <div className='rawContainerDiagrams'>
                            <div className='diagrammHeadTitle'>Validity of Network</div>
                            {(validityGause && validityGause.length > 0)
                                ? <div className='rawContainerDiagrams'>
                                    {
                                        validityGause.map((item, key) => (
                                            <div className='gauseContainer'>
                                                <span className='gauseTitle'>{flagsTitle[String(item.flag)]}</span>
                                                <ReactSpeedometer
                                                    maxValue={100}
                                                    minValue={0}
                                                    value={item.percentage_invalid.toFixed(3)}
                                                    needleColor="red"
                                                    startColor="green"
                                                    segments={10}
                                                    endColor="blue"
                                                    key={key}
                                                    paddingHorizontal={5}
                                                    paddingVertical={5}
                                                    height={150}
                                                    width={200}
                                                />
                                            </div>
                                        ))
                                    }
                                </div>
                                : (validityGause && validityGause.length === 0) ? <span>No data</span> : <div className='rawContainerCenteredH'><CircularProgress /></div>}
                        </div>
                    }
                    {
                        params.detector === "all" &&
                        <div className='rawContainerDiagrams'>
                            <div className='diagrammHeadTitle'>Completeness per Data Source</div>
                            {chartDataScae ? <div className='detectorDiagramsContainerRaw'><BarTimeValue chartData={chartDataScae} title="Completeness over Time" /></div> : <div className='rawContainerCenteredH'><CircularProgress /></div>}
                            {chartDataSiemens ? <div className='detectorDiagramsContainerRaw'><BarTimeValue chartData={chartDataSiemens} title="Completeness over Time" /></div> : <div className='rawContainerCenteredH'><CircularProgress /></div>}
                            {chartDataSwarco ? <div className='detectorDiagramsContainerRaw'><BarTimeValue chartData={chartDataSwarco} title="Completeness over Time" /></div> : <div className='rawContainerCenteredH'><CircularProgress /></div>}
                        </div>
                    }
                    {
                        params.detector === "all" &&
                        <div className='rawContainerDiagrams'>
                            <div className='diagrammHeadTitle'>Invalid percentages</div>
                            <div className='rawContainerDiagrams'>
                                {
                                    invalidScae &&
                                    <div className="containerDetStatsAll">
                                        <span className='containerDetStatsTitleAll'>Invalid Scae</span>
                                        <div className='containerDetStatsBodyAll'>
                                            <div className='statrowAll'>
                                                <span className="valueDetStats">{invalidScae[0].total_records}</span>
                                                <span className="labelDetStatsAll">Total records</span>
                                            </div>
                                            <div className='statrowAll'>
                                                <span className="valueDetStats">{invalidScae[0].invalid_count}</span>
                                                <span className="labelDetStatsAll">Invalid count</span>
                                            </div>
                                            <div className='statrowAll'>
                                                <span className="valueDetStats">{invalidScae[0].percentage_invalid}%</span>
                                                <span className="labelDetStatsAll">Percentage Invalid</span>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {
                                    invalidSiemens &&
                                    <div className="containerDetStatsAll">
                                        <span className='containerDetStatsTitleAll'>Invalid Siemens</span>
                                        <div className='containerDetStatsBodyAll'>
                                            <div className='statrowAll'>
                                                <span className="valueDetStats">{invalidSiemens[0].total_records}</span>
                                                <span className="labelDetStatsAll">Total records</span>
                                            </div>
                                            <div className='statrowAll'>
                                                <span className="valueDetStats">{invalidSiemens[0].invalid_count}</span>
                                                <span className="labelDetStatsAll">Invalid count</span>
                                            </div>
                                            <div className='statrowAll'>
                                                <span className="valueDetStats">{invalidSiemens[0].percentage_invalid}%</span>
                                                <span className="labelDetStatsAll">Percentage Invalid</span>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {
                                    invalidSwarco &&
                                    <div className="containerDetStatsAll">
                                        <span className='containerDetStatsTitleAll'>Invalid Swarco</span>
                                        <div className='containerDetStatsBodyAll'>
                                            <div className='statrowAll'>
                                                <span className="valueDetStats">{invalidSwarco[0].total_records}</span>
                                                <span className="labelDetStatsAll">Total records</span>
                                            </div>
                                            <div className='statrowAll'>
                                                <span className="valueDetStats">{invalidSwarco[0].invalid_count}</span>
                                                <span className="labelDetStatsAll">Invalid count</span>
                                            </div>
                                            <div className='statrowAll'>
                                                <span className="valueDetStats">{invalidSwarco[0].percentage_invalid}%</span>
                                                <span className="labelDetStatsAll">Percentage Invalid</span>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    }
                    {
                        (params.detector === "all" || params.location === "Network") &&
                        <div className='rawContainerDiagrams'>
                            <div className='diagrammHeadTitle'>Detectors List</div>
                            <DetectorsOverview params={params} />
                        </div>
                    }
                </Grid>
            </Grid>
        </Card>
    );
};

export default DetectorNetwork;


/*
<div className='rawContainerDiagrams'>
    <div className='diagrammHeadTitle'>Accuracy per Provider</div>
    <div className="percentage-container">
        <span className="percentage-value">{5}%</span>
        <span className="percentage-label">The value from the detectors deviated by approximately 5% from the ground truth value (TomTom data) at that specific time interval</span>
    </div>
</div>
*/