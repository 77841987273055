import { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDBox from "components/MDBox";
import Icon from "@mui/material/Icon";
import { alpha, styled } from '@mui/material/styles';
import { pink } from '@mui/material/colors';
import Switch from '@mui/material/Switch';
import create from "assets/images/Synchro/add_box.svg";
import CreateAlertPanel from "modals/CreateAlertPanel";


function CreateNotification() {
  const [dataPreview, setDataPreview] = useState(null);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const label = { inputProps: { 'aria-label': 'Color switch demo' } };
  return (

    <DashboardLayout>
      <MDBox mt={4} p={0}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <div className="notifications-container">
              <div className="page-title" >Create alert</div>
              <div className="create-container">
                <div className="create-input">
                  <div className="input-label">Traffic Monitoring <Switch {...label} defaultChecked /></div>
                  <img src={create} alt="create" style={{ cursor: "pointer", width: "30px" }} onClick={handleOpen} />
                </div>
                <div className="create-input">
                  <div className="input-label">Data Quality <Switch {...label} defaultChecked /></div>
                  <img src={create} alt="create" style={{ cursor: "pointer", width: "30px" }} onClick={handleOpen} />
                </div>
                <div className="create-input">
                  <div className="input-label">Identification and verification of events <Switch {...label} defaultChecked /></div>
                  <img src={create} alt="create" style={{ cursor: "pointer", width: "30px" }} onClick={handleOpen} />
                </div>
                <div className="create-input">
                  <div className="input-label">Recommendation of Traffic Management Scenario<Switch {...label} defaultChecked /></div>
                  <img src={create} alt="create" style={{ cursor: "pointer", width: "30px" }} onClick={handleOpen} />
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </MDBox>
      <CreateAlertPanel open={open} handleClose={handleClose} />
    </DashboardLayout>
  );
}

export default CreateNotification;