import React, { useEffect, useState } from "react";
import { Autocomplete, Divider, Grid, Modal } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import useFieldsData from "layouts/events/create/components/EventForm/data/useFieldsData";
import MDDatePicker from "components/MDDatePicker";

function UpdateForm({ updateEvent, setUpdateEvent, onSubmit, onCancel }) {
  const { fields, loading, error } = useFieldsData(); // Fetch options for type, category, subcategory
  
  useEffect(() => {
    if (updateEvent) {
      setUpdateEvent(updateEvent);
    }
  }, [updateEvent]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdateEvent((prev) => ({ ...prev, [name]: value }));
  };

  const handleDateChange = (date, field) => {
    setUpdateEvent((prev) => ({
      ...prev,
      [field]: date && date[0] ? date[0].toISOString() : null, // Stores as ISO string
    }));
  };

  if (loading) return <MDTypography variant="h6">Loading...</MDTypography>;

  // console.log("updateEvent", updateEvent);

  return (
    <MDBox bgColor="dark">
      <MDTypography variant="h6" color="secondary">
        {updateEvent.category.name}
      </MDTypography>
      <Divider sx={{ my: 2 }} />

      {/* Start Date */}
      <Grid item xs={12} sm={12}>
        <MDTypography
          component="label"
          variant="button"
          fontWeight="regular"
          color="text"
          sx={{ display: "block", width: "100%" }} // Ensures full width
        >
          Start date-time
        </MDTypography>
        <MDDatePicker
          input={{ placeholder: "dd/mm/yyyy HH:mm" }}
          value={
            updateEvent.valid_from ? new Date(updateEvent.valid_from) : null
          } // Ensures it's either a Date object or null
          options={{
            enableTime: true,
            dateFormat: "d/m/Y H:i",
            altFormat: "d/m/Y H:i",
            time_24hr: true,
          }}
          onChange={(date) => handleDateChange(date, "valid_from")}
        />
      </Grid>

      {/* End Date */}
      <Grid item xs={12} sm={12}>
        <MDTypography
          component="label"
          variant="button"
          fontWeight="regular"
          color="text"
          sx={{ display: "block", width: "100%" }} // Ensures full width
        >
          End date-time
        </MDTypography>
        <MDDatePicker
          input={{ placeholder: "dd/mm/yyyy HH:mm" }}
          value={
            updateEvent.valid_until ? new Date(updateEvent.valid_until) : null
          } // Ensures it's either a Date object or null
          options={{
            enableTime: true,
            dateFormat: "d/m/Y H:i",
            altFormat: "d/m/Y H:i",
            time_24hr: true,
          }}
          onChange={(date) => handleDateChange(date, "valid_until")}
        />
      </Grid>

      {/* Action Buttons */}
      <MDBox display="flex" justifyContent="flex-end" mt={3}>
        <MDButton onClick={onCancel} variant="text" color="primary">
          Cancel
        </MDButton>
        <MDButton
          color="secondary"
          onClick={() => onSubmit(updateEvent)}
          variant="contained"
        >
          Save
        </MDButton>
      </MDBox>
    </MDBox>
  );
}

export default UpdateForm;
