import Keycloak from "keycloak-js";

// Configure Keycloak instance
const keycloak = new Keycloak({
  url: "https://auth.synchromode.eu",
  realm: "synchro",
  clientId: "gui_client",
});

export default keycloak;
