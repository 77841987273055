import TrafficListItem from "./TrafficListItem";

function TrafficList({ data }) {

  if (!Array.isArray(data) || data.length === 0)
    return <p>No Data Available</p>; // ✅ Handle empty data

  return (
    <>
      {data.map((data, index) => (
        <TrafficListItem
          key={index}
          title={data.name}
          values={data.values}
        />
      ))}
    </>
  );
}

export default TrafficList;
