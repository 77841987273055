import ReportsData from "./ReportsData";
const ReportsOverviewTable = (userId) => {
    //console.log(userId);
    const { data, loading, error } = ReportsData(userId);
  
    // Define the table headers
    const columns = [
      { Header: "Id", accessor: "index" },
      { Header: "Name", accessor: "name" },
      { Header: "User Id", accessor: "userId" },
      { Header: "Created Date", accessor: "createdDate" },
    ];
  

    let showData = [];

    showData = data;
    
  
    const rows = showData.map((item, index) => ({
      index: index+1,
      primaryWayId: item.primaryWayId,
      name: item.name,
      createdDate: item.createdDate,
      userId: item.userId,
      reportId:item.id,
      reportName:item.name
    }));
  
    // Return the table-ready data
    return {
      tableData: { columns, rows },
      loading,
      error,
    };
  };
  
  export default ReportsOverviewTable;
  