import React from 'react';
import { Box, Typography } from '@mui/material';

function GradientLegend() {
  const gradientColors = {
    startColor: 'rgb(213, 216, 232)',
    endColor: 'rgb(42, 57, 144)',
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', mt: 3 }}>
      <Box sx={{ position: 'relative', height: 61, width: '100%', maxWidth: 600 }}>
        <Box
          sx={{
            height: '100%',
            width: '100%',
            background: `linear-gradient(to right, ${gradientColors.startColor}, ${gradientColors.endColor})`,
            border: '1px solid #000',
          }}
        />
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
          {[10, 20, 30, 40, 50, 60, 70, 80, 90].map((value) => (
            <Box key={value} sx={{ position: 'absolute', left: `${value}%`, bottom: -5, transform: 'translateX(-20%)' }}>
              <Typography variant="caption" color="HighlightText">{value}</Typography>
            </Box>
          ))}
        </Box>
      </Box>
      <Typography variant="body1" sx={{ mt: 1 }} color="HighlightText">
        Percentage
      </Typography>
    </Box>
  );
}

export default GradientLegend;