//import { MultiCascader } from 'rsuite';
//import { TreePicker, MultiCascader, Modal, Button } from 'rsuite';
// import 'rsuite/MultiCascader/styles/index.css';
// import 'rsuite/TreePicker/styles/index.css';
//import 'rsuite/SelectPicker/styles/index.css';
// import 'rsuite/dist/rsuite.min.css';
import MDBox from "components/MDBox";
import "../../../assets/Css/QDfilters.css";
import "../../../assets/Css/general.css";
import { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Card from "@mui/material/Card";
import MDInput from "components/MDInput";
import TextField from '@mui/material/TextField';
import MDButton from "components/MDButton";
import { formatDateDQ } from 'modules/formatDates';
import HandlerComp from "./HandlerComp";
import { getUTCDatesFromTo } from "modules/formatDates";
import { getUTCDatesFromParams } from "modules/formatDates";
import axios from "axios";
import { CircularProgress } from "@mui/material";
import OutlinedInput from '@mui/material/OutlinedInput';
import SelectFromMap from "modals/SelectFromMap";
import Icon from "@mui/material/Icon";
import { globalUrls } from "modules/globals";
import config from "../../../config";
import SpeedTravelNetwork from "./diagrams/SpeedTravelNetwork";
import DetectorNetwork from "./diagrams/DetectorNetwork";


const dqCorrelations = [
    {
        label: 'Detectors Data',
        value: 'detectors',
        subs: [
            { label: 'All', value: 'all', dqualities: ["Overview", "Completeness", "Validity", "Accuracy", "Coverage"] },
            { label: 'SIEMENS', value: 'yunex', dqualities: ["Overview", "Completeness", "Validity", "Accuracy", "Coverage"] },
            { label: 'SWARCO', value: 'swarco', dqualities: ["Overview", "Completeness", "Validity", "Accuracy", "Coverage"] },
            { label: 'SCAE', value: 'scae', dqualities: ["Overview", "Completeness", "Validity", "Accuracy", "Coverage"] }
        ]
    },
    {
        label: 'Speed  - Travel times data',
        value: 'speedTravel',
        subs: [
            { label: 'TomTom Data', value: 'tomtomfcd', dqualities: ["Overview", "Completeness", "Coverage"] },
            { label: 'Taxi Data', value: 'taxifcd', dqualities: ["Overview", "Completeness", "Accuracy", "Coverage"] },
            { label: 'Bluetooth Data', value: 'bluetooth', dqualities: ["Overview", "Completeness", "Accuracy", "Coverage"] },
        ]
    },
    {
        label: 'Public transport data',
        value: 'publicTransport',
        subs: [
            { label: 'OASTH Data', value: 'oasth', dqualities: ["Overview", "Completeness", "Accuracy", "Coverage"] },
        ]
    },
    {
        label: 'Events',
        value: 'events',
        subs: [
            { label: 'TomTom Traffic', value: 'tomtomtfc', dqualities: ["Overview", "Accuracy"] },
            { label: 'Police Traffic', value: 'policetfc', dqualities: ["Overview", "Accuracy"] }
        ]
    }
]

const spartialList = {
    detectors: ["None", "Network", "Routes", "Intersections", "Detectors"],
    speedTravel: ["None", "Network", "Routes"],
    publicTransport: ["None", "Network", "Routes"],
    events: ["None"]
}
function Filters() {
    //modal
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [showType, setShowType] = useState("");
    const [showParams, setShowParams] = useState(null);
    const [hideBody, setHideBody] = useState(true);

    const [dataSource, setDataSource] = useState("detectors");
    const [dataSubSource, setDataSubSource] = useState("all");
    const [location, setlocation] = useState("None");

    const [timeType, setTimeType] = useState("24h");
    const now = new Date();
    const [customDates, setCustomDates] = useState({
        from: formatDateDQ(new Date(now.getTime() - 24 * 60 * 60 * 1000)),
        to: formatDateDQ(now)
    });
    const [customHours, setCustomHours] = useState(2);
    const [detectors, setDetectors] = useState([]);
    const [selectedDetectors, setSelectedDetectors] = useState([-1]);
    const [routes, setRoutes] = useState([]);
    const [selectedRoutes, setSelectedRoutes] = useState([-1]);
    const [sdetectors, setSDetectors] = useState([]);
    const [selectedSDetectors, setSelectedSDetectors] = useState([-1]);
    const [detectorsMatch, setDetectorsMatch] = useState();
    const [bttaxiPaths, setBttaxiPaths] = useState([]);
    const [tomtomPaths, setTomtomPaths] = useState([]);
    const [selectedSpeedTravel, setSelectedSpeedTravel] = useState([-1]);
    const [oasthPaths, setOasthPaths] = useState([]);
    const [selectedPublic, setSelectedPublic] = useState([-1]);

    const ITEM_HEIGHT = 70;
    const ITEM_PADDING_TOP = 2;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 300,
            },
        },
    };

    useEffect(() => {

    }, [selectedDetectors, selectedRoutes, selectedSDetectors, selectedSpeedTravel]);

    useEffect(() => {
        async function fetchData() {
            try {
                console.log(config.ENDPOINTS_URL + "/detectors_info_metadata")
                const dData = await axios.get(config.ENDPOINTS_URL + "/detectors_info_metadata", {
                    headers: {
                        "Accept-Profile": "detectors"
                    }
                });

                setDetectors(dData.data.filter((item, index, self) => {
                    const combination = `${item.intersection_name}-${item.intersection_id}`;
                    return self.findIndex(obj => `${obj.intersection_name}-${obj.intersection_id}` === combination) === index;
                }));
                setRoutes(dData.data.filter((item, index, self) => {
                    const combination = `${item.route_name_en}-${item.route_id}`;
                    return self.findIndex(obj => `${obj.route_name_en}-${obj.route_id}` === combination) === index;
                }));
                setSDetectors(dData.data);

                setDetectorsMatch(dData.data.reduce((acc, item) => {
                    acc[item.id] = item.detector_name;
                    return acc;
                }, {}));

                const bttaxiData = await axios.get(config.ENDPOINTS_URL + "/bt_taxi_paths", {
                    headers: {
                        "Accept-Profile": "open_imet"
                    }
                });
                setBttaxiPaths(bttaxiData.data);
                const tomtomData = await axios.get(config.ENDPOINTS_URL + "/materialized_tomtom_routes", {
                    headers: {
                        "Accept-Profile": "route_monitoring"
                    }
                });
                setTomtomPaths(tomtomData.data);


                const publicData = await axios.get(config.ENDPOINTS_URL + "/bus_stop_geom", {
                    headers: {
                        "Accept-Profile": "oasth"
                    }
                });
                setOasthPaths(publicData.data);
            } catch (e) {
                console.log(e)
            }
        }
        fetchData();
    }, []);


    function getSubSourceValues(targetValue) {
        const target = dqCorrelations.find(item => item.value === targetValue);
        return target.subs; // Return an empty array if the target value is not found
    }


    const handleChangeSource = (event, newValue) => {
        //Go to Detectors Overview show
        setSelectedDetectors([-1]);
        setSelectedRoutes([-1]);
        setSelectedSDetectors([-1]);
        setSelectedSpeedTravel([-1]);
        setSelectedPublic([-1]);
        setlocation("None");
        setHideBody(true);
        setShowType("");
        setShowParams(null);
        const newDef = getSubSourceValues(newValue)[0].value;
        setDataSubSource(newDef);
        setDataSource(newValue);
        setTimeType("24h")
    }
    const handleChangeSubSource = (event, newValue) => {
        //check if overview and show
        setSelectedDetectors([-1]);
        setSelectedRoutes([-1]);
        setSelectedSDetectors([-1]);
        setSelectedSpeedTravel([-1]);
        setSelectedPublic([-1]);
        setlocation("None");
        setHideBody(true);
        setShowType("");
        setShowParams(null);
        setDataSubSource(newValue);
        setTimeType("24h")
    }

    const handleCustomDates = (event, newValue) => {
        setCustomDates({ ...customDates, [event.target.name]: event.target.value });
    }
    const handleChangeTimeType = (e) => {
        setHideBody(true);
        setTimeType(e.target.value);
    }
    const handleChangeLocation = (e) => {
        setHideBody(true);
        setSelectedDetectors([-1]);
        setSelectedRoutes([-1]);
        setSelectedSDetectors([-1]);
        setSelectedSpeedTravel([-1]);
        setSelectedPublic([-1]);
        setlocation(e.target.value);
        setTimeType("24h")
    }
    const handlePickDetectors = (e) => {
        setHideBody(true);
        setSelectedDetectors([e.target.value]);
    }
    const handlePickRoutes = (e) => {
        setHideBody(true);
        setSelectedRoutes([e.target.value]);
    }
    const handlePickSDetectors = (e) => {
        setHideBody(true);
        setSelectedSDetectors([e.target.value]);
    }
    const handlePickSpeedTravel = (e) => {
        setHideBody(true);
        setSelectedSpeedTravel([e.target.value]);
    }
    const handlePickPublic = (e) => {
        setHideBody(true);
        setSelectedPublic([e.target.value]);
    }
    const apply = () => {
        setHideBody(false);
        if (dataSource === "detectors") {
            setShowType("detectorComp")
            let dates = {
                from: "",
                to: ""
            }
            let casted
            switch (timeType) {
                case "12h":
                    casted = getUTCDatesFromTo(12)
                    dates.from = casted.hourAgoUTC
                    dates.to = casted.nowUTC
                    break;
                case "24h":
                    casted = getUTCDatesFromTo(24)
                    dates.from = casted.hourAgoUTC
                    dates.to = casted.nowUTC
                    break;
                case "customH":
                    casted = getUTCDatesFromTo(customHours)
                    dates.from = casted.hourAgoUTC
                    dates.to = casted.nowUTC
                    break;
                case "customD":
                    casted = getUTCDatesFromParams(customDates.from, customDates.to)
                    dates.from = casted.date1UTC
                    dates.to = casted.date2UTC
                    break;
                default:
                    casted = getUTCDatesFromTo(1)
                    dates.from = casted.hourAgoUTC
                    dates.to = casted.nowUTC
                    break;
            }
            setShowParams({
                detector: dataSubSource,
                dates: dates,
                location: location,
                selectedDetectors: []
            })
            if (location === "Intersections") {
                const castedSelected = selectedDetectors.map(id => {
                    const matchedItem = detectors.find(item => item.intersection_id === id);
                    return matchedItem ? { id, name: matchedItem.intersection_name } : { id, name: null }; // Handle missing names
                });
                setShowParams({
                    detector: dataSubSource,
                    dates: dates,
                    location: location,
                    selectedDetectors: castedSelected
                })
            }
            if (location === "Routes") {
                const castedSelected = selectedRoutes.map(id => {
                    const matchedItem = detectors.find(item => item.route_id === id);
                    return matchedItem ? { id, name: matchedItem.route_name_el } : { id, name: null }; // Handle missing names
                });
                setShowParams({
                    detector: dataSubSource,
                    dates: dates,
                    location: location,
                    selectedDetectors: castedSelected
                })
            }
            if (location === "Detectors") {
                // Map through selectedSDetectors using the Map
                const castedSelected = selectedSDetectors.map((id) => {
                    const matchedItem = detectorsMatch[id];
                    return matchedItem
                        ? { id, name: matchedItem }
                        : { id, name: id }; // Handle missing names
                });
                setShowParams({
                    detector: dataSubSource,
                    dates: dates,
                    location: location,
                    selectedDetectors: castedSelected
                })
            }
        }
        if (dataSource === "speedTravel") {
            setShowType("speedTravelComp")
            let dates = {
                from: "",
                to: ""
            }
            let casted
            switch (timeType) {
                case "12h":
                    casted = getUTCDatesFromTo(12)
                    dates.from = casted.hourAgoUTC
                    dates.to = casted.nowUTC
                    break;
                case "24h":
                    casted = getUTCDatesFromTo(24)
                    dates.from = casted.hourAgoUTC
                    dates.to = casted.nowUTC
                    break;
                case "customH":
                    casted = getUTCDatesFromTo(customHours)
                    dates.from = casted.hourAgoUTC
                    dates.to = casted.nowUTC
                    break;
                case "customD":
                    casted = getUTCDatesFromParams(customDates.from, customDates.to)
                    dates.from = casted.date1UTC
                    dates.to = casted.date2UTC
                    break;
                default:
                    casted = getUTCDatesFromTo(1)
                    dates.from = casted.hourAgoUTC
                    dates.to = casted.nowUTC
                    break;
            }
            setShowParams({
                detector: dataSubSource,
                dates: dates,
                location: location,
                selectedDetectors: []
            })
            if (location === "Routes") {
                let castedSelected
                if (dataSubSource === "taxifcd" || dataSubSource === "bluetooth") {
                    castedSelected = selectedSpeedTravel.map(id => {
                        const matchedItem = bttaxiPaths.find(item => item.id_path === id);
                        return matchedItem ? { id, name: matchedItem.path_name } : { id, name: null }; // Handle missing names
                    });
                } else {
                    castedSelected = selectedSpeedTravel.map(id => {
                        const matchedItem = tomtomPaths.find(item => item.id_long_paths === id);
                        return matchedItem ? { id, name: matchedItem.name } : { id, name: null }; // Handle missing names
                    });
                }
                setShowParams({
                    detector: dataSubSource,
                    dates: dates,
                    location: location,
                    selectedDetectors: castedSelected
                })
            }
        }
        if (dataSource === "publicTransport") {
            setShowType("publicTransportComp")
            let dates = {
                from: "",
                to: ""
            }
            let casted
            switch (timeType) {
                case "12h":
                    casted = getUTCDatesFromTo(12)
                    dates.from = casted.hourAgoUTC
                    dates.to = casted.nowUTC
                    break;
                case "24h":
                    casted = getUTCDatesFromTo(24)
                    dates.from = casted.hourAgoUTC
                    dates.to = casted.nowUTC
                    break;
                case "customH":
                    casted = getUTCDatesFromTo(customHours)
                    dates.from = casted.hourAgoUTC
                    dates.to = casted.nowUTC
                    break;
                case "customD":
                    casted = getUTCDatesFromParams(customDates.from, customDates.to)
                    dates.from = casted.date1UTC
                    dates.to = casted.date2UTC
                    break;
                default:
                    casted = getUTCDatesFromTo(1)
                    dates.from = casted.hourAgoUTC
                    dates.to = casted.nowUTC
                    break;
            }
            setShowParams({
                detector: dataSubSource,
                dates: dates,
                location: location,
                selectedDetectors: []
            })
            if (location === "Routes") {
                let castedSelected = selectedPublic.map(id => {
                    const matchedItem = oasthPaths.find(item => item.bus_stop_id === id);
                    return matchedItem ? { id, name: matchedItem.busstop } : { id, name: null }; // Handle missing names
                });
                setShowParams({
                    detector: dataSubSource,
                    dates: dates,
                    location: location,
                    selectedDetectors: castedSelected
                })
            }
        }
    }

    function applyEnabled() {
        console.log(location , dataSubSource, timeType, selectedDetectors[0], selectedRoutes[0], selectedSDetectors[0], selectedSpeedTravel[0], selectedPublic[0]) 
        if (location === "None" && dataSubSource !== "all") {
            return false;
        }
        if (dataSubSource === "all") {
            return true;
        }
        if (location === "Network") {
            return true;
        }
        if (selectedDetectors[0] === -1 && selectedRoutes[0] === -1 && selectedSDetectors[0] === -1 && selectedSpeedTravel[0] === -1 && selectedPublic[0] === -1) {
            return false;
        }
        if (timeType === "customH") {
            if (customHours > 0) {
                return true;
            } else {
                return false;
            }
        }
        if (timeType === "customD") {
            const fromDate = new Date(customDates.from);
            const toDate = new Date(customDates.to);
            if (customDates.from !== "" && customDates.to !== "" && (fromDate < toDate)) {
                return true;
            } else {
                return false;
            }
        }
        return true;
    }

    const returnBody = () => {
        if (dataSource === "detectors") {
            if (location === 'Network' || dataSubSource === 'all') {
                return  <DetectorNetwork params={showParams} />
            } else {
                return <HandlerComp type={showType} params={showParams} />
            }
        }
        if (dataSource === "speedTravel") {
            if (location === 'Network') {
                return <SpeedTravelNetwork params={showParams} />
            } else {
                return <HandlerComp type={showType} params={showParams} />
            }
        }
        if (dataSource === "publicTransport") {
            if (location === 'Network') {
                return <>Network</>
            } else {
                return <HandlerComp type={showType} params={showParams} />
            }
        }
    }


    return (
        <>
            <Card style={{ marginTop: '3px' }}>
                <div className='rawContainer' >
                    {
                        detectors.length > 0
                            ? <>
                                <MDBox p={3} lineHeight={1} style={{ minWidth: '500px', width: '50%' }}>
                                    <div className="colContainer" >
                                        <Tabs orientation={"horizontal"} value={dataSource} onChange={handleChangeSource} style={{ marginTop: '1px' }}>
                                            {dqCorrelations.map((item, index) => {
                                                return <Tab key={index} label={item.label} value={item.value} style={{ padding: '15px' }} />
                                            })}
                                        </Tabs>
                                        <Tabs orientation={"horizontal"} value={dataSubSource} onChange={handleChangeSubSource} style={{ marginTop: '1px' }}>
                                            {getSubSourceValues(dataSource).map((item, index) => {
                                                return <Tab key={index} label={item.label} value={item.value} style={{ padding: '15px' }} />
                                            })}
                                        </Tabs>
                                    </div>
                                </MDBox >
                                <MDBox p={3} lineHeight={1} style={{ width: '50%' }}>
                                    <div className="colContainer vline" >
                                        <div className="colContainer" >
                                            <Box sx={{ minWidth: 160, marginTop: '5px' }}>
                                                <div className='rawContainer'>
                                                    {
                                                        dataSubSource === "all"
                                                            ? <FormControl disabled>
                                                                <InputLabel id="select">Spatial Level</InputLabel>
                                                                <Select
                                                                    labelId="select-location"
                                                                    id="select-location"
                                                                    label="Select Location type"
                                                                    style={{ paddingTop: '12px', paddingBottom: '12px', paddingLeft: '7px', paddingRight: '7px', width: '140px' }}
                                                                >
                                                                    <MenuItem value={"None"}>None</MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                            : <FormControl>
                                                                <InputLabel id="select">Spatial Level</InputLabel>
                                                                <Select
                                                                    key={location}  // Force re-render when value changes
                                                                    labelId="select-location"
                                                                    id="select-location"
                                                                    value={location}
                                                                    label="Select Location type"
                                                                    onChange={handleChangeLocation}
                                                                    style={{ paddingTop: '12px', paddingBottom: '12px', paddingLeft: '7px', paddingRight: '7px', width: '140px' }}
                                                                >
                                                                    {
                                                                        spartialList[dataSource].map((item, key) => {
                                                                            return <MenuItem key={key} value={item}>{item}</MenuItem>
                                                                        })
                                                                    }
                                                                </Select>
                                                            </FormControl>
                                                    }

                                                    {
                                                        (dataSource === "detectors" && location === "Intersections") &&
                                                        <>
                                                            <FormControl style={{ marginLeft: '10px', width: '400px' }}>
                                                                <InputLabel id="select-detectors-label">List of Intersections</InputLabel>
                                                                <Select
                                                                    key={selectedDetectors.join(",")}  // Force re-render when value changes
                                                                    labelId="select-detectors-label"
                                                                    id="select-detector"
                                                                    value={selectedDetectors[0]}
                                                                    label="List of Intersections "
                                                                    onChange={handlePickDetectors}
                                                                    style={{ paddingTop: '12px', paddingBottom: '12px', paddingRight: '7px' }}
                                                                >
                                                                    <MenuItem value={-1}>None</MenuItem>
                                                                    {
                                                                        detectors.map((item, key) => {
                                                                            if (item.provider_name.toUpperCase() === dataSubSource.toUpperCase()) {
                                                                                return <MenuItem key={key} value={item.intersection_id}>
                                                                                    <span style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>{`${item.intersection_name}-${item.intersection_id}`}</span>
                                                                                </MenuItem>
                                                                            } else if (item.provider_name.toUpperCase() === "SIEMENS" && dataSubSource.toUpperCase() === "YUNEX") {
                                                                                return <MenuItem key={key} value={item.intersection_id}>
                                                                                    <span style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>{`${item.intersection_name}-${item.intersection_id}`}</span>
                                                                                </MenuItem>
                                                                            }
                                                                        })
                                                                    }
                                                                </Select>
                                                            </FormControl>
                                                            <Icon onClick={handleOpen} fontSize="large" sx={{ color: "white" }} style={{ cursor: 'pointer', marginLeft: '10px', marginTop: '5px', marginBottom: "5px" }}>map</Icon>
                                                        </>
                                                    }
                                                    {
                                                        (dataSource === "detectors" && location === "Routes") &&
                                                        <>
                                                            <FormControl style={{ marginLeft: '10px', width: '400px' }}>
                                                                <InputLabel id="select-routes-label">List of Routes</InputLabel>
                                                                <Select
                                                                    key={selectedRoutes.join(",")}  // Force re-render when value changes
                                                                    labelId="select-routes-label"
                                                                    id="select-routes"
                                                                    label="List of Routes "
                                                                    value={selectedRoutes[0]}
                                                                    onChange={handlePickRoutes}
                                                                    style={{ paddingTop: '12px', paddingBottom: '12px', paddingRight: '7px' }}
                                                                >
                                                                    <MenuItem value={-1}>None</MenuItem>
                                                                    {
                                                                        routes.map((item, key) => {
                                                                            if (item.provider_name.toUpperCase() === dataSubSource.toUpperCase()) {
                                                                                return <MenuItem key={key} value={item.route_id}>
                                                                                    <span style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>{`${item.route_name_el}-${item.route_id}`}</span>
                                                                                </MenuItem>
                                                                            } else if (item.provider_name.toUpperCase() === "SIEMENS" && dataSubSource.toUpperCase() === "YUNEX") {
                                                                                return <MenuItem key={key} value={item.route_id}>
                                                                                    <span style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>{`${item.route_name_el}-${item.route_id}`}</span>
                                                                                </MenuItem>
                                                                            }
                                                                        })
                                                                    }
                                                                </Select>
                                                            </FormControl>
                                                            <Icon onClick={handleOpen} fontSize="large" sx={{ color: "white" }} style={{ cursor: 'pointer', marginLeft: '10px', marginTop: '5px', marginBottom: "5px" }}>map</Icon>
                                                        </>
                                                    }
                                                    {
                                                        (dataSource === "detectors" && location === "Detectors") &&
                                                        <>
                                                            <FormControl style={{ marginLeft: '10px', width: '400px' }}>
                                                                <InputLabel id="select-det-label">List of Detectors </InputLabel>
                                                                <Select
                                                                    key={selectedSDetectors.join(",")}  // Force re-render when value changes
                                                                    labelId="select-det-label"
                                                                    id="select-det"
                                                                    value={selectedSDetectors[0]}
                                                                    label="List of Detectors "
                                                                    onChange={handlePickSDetectors}
                                                                    style={{ paddingTop: '12px', paddingBottom: '12px', paddingRight: '7px' }}
                                                                >
                                                                    <MenuItem value={-1}>None</MenuItem>
                                                                    {
                                                                        sdetectors.map((item, key) => {
                                                                            if (item.provider_name.toUpperCase() === dataSubSource.toUpperCase()) {
                                                                                return <MenuItem key={key} value={item.id}>
                                                                                    <span style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>{`${item.detector_name}`}</span>
                                                                                </MenuItem>
                                                                            } else if (item.provider_name.toUpperCase() === "SIEMENS" && dataSubSource.toUpperCase() === "YUNEX") {
                                                                                return <MenuItem key={key} value={item.id}>
                                                                                    <span style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>{`${item.detector_name}`}</span>
                                                                                </MenuItem>
                                                                            }
                                                                        })
                                                                    }
                                                                </Select>
                                                            </FormControl>
                                                            <Icon onClick={handleOpen} fontSize="large" sx={{ color: "white" }} style={{ cursor: 'pointer', marginLeft: '10px', marginTop: '5px', marginBottom: "5px" }}>map</Icon>
                                                        </>
                                                    }
                                                    {
                                                        (dataSource === "speedTravel" && (dataSubSource === "taxifcd" || dataSubSource === "bluetooth") && location === "Routes") &&
                                                        <>
                                                            <FormControl style={{ marginLeft: '10px', width: '400px' }}>
                                                                <InputLabel id="select-routes-label-bttaxi">List of Routes</InputLabel>
                                                                <Select
                                                                    key={selectedRoutes.join(",")}  // Force re-render when value changes
                                                                    labelId="select-routes-label-bttaxi"
                                                                    id="select-routes-bttaxi"
                                                                    label="List of Routes "
                                                                    value={selectedSpeedTravel[0]}
                                                                    onChange={handlePickSpeedTravel}
                                                                    style={{ paddingTop: '12px', paddingBottom: '12px', paddingRight: '7px' }}
                                                                >
                                                                    <MenuItem value={-1}>None</MenuItem>
                                                                    {
                                                                        bttaxiPaths.map((item, key) => {
                                                                            return <MenuItem key={key} value={item.id_path}>
                                                                                <span style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>{`${item.path_name}`}</span>
                                                                            </MenuItem>
                                                                        })
                                                                    }
                                                                </Select>
                                                            </FormControl>
                                                            <Icon onClick={handleOpen} fontSize="large" sx={{ color: "white" }} style={{ cursor: 'pointer', marginLeft: '10px', marginTop: '5px', marginBottom: "5px" }}>map</Icon>
                                                        </>
                                                    }
                                                    {
                                                        (dataSource === "speedTravel" && dataSubSource === "tomtomfcd" && location === "Routes") &&
                                                        <>
                                                            <FormControl style={{ marginLeft: '10px', width: '400px' }}>
                                                                <InputLabel id="select-routes-label-tomtom">List of Routes</InputLabel>
                                                                <Select
                                                                    key={selectedRoutes.join(",")}  // Force re-render when value changes
                                                                    labelId="select-routes-label-tomtom"
                                                                    id="select-routes-tomtom"
                                                                    label="List of Routes "
                                                                    value={selectedSpeedTravel[0]}
                                                                    onChange={handlePickSpeedTravel}
                                                                    style={{ paddingTop: '12px', paddingBottom: '12px', paddingRight: '7px' }}
                                                                >
                                                                    <MenuItem value={-1}>None</MenuItem>
                                                                    {
                                                                        tomtomPaths.map((item, key) => {
                                                                            return <MenuItem key={key} value={item.id_long_paths}>
                                                                                <span style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>{`${item.name}`}</span>
                                                                            </MenuItem>
                                                                        })
                                                                    }
                                                                </Select>
                                                            </FormControl>
                                                            <Icon onClick={handleOpen} fontSize="large" sx={{ color: "white" }} style={{ cursor: 'pointer', marginLeft: '10px', marginTop: '5px', marginBottom: "5px" }}>map</Icon>
                                                        </>
                                                    }
                                                    {
                                                        (dataSource === "publicTransport" && dataSubSource === "oasth" && location === "Routes") &&
                                                        <>
                                                            <FormControl style={{ marginLeft: '10px', width: '400px' }}>
                                                                <InputLabel id="select-busstop-label">List of bus stops</InputLabel>
                                                                <Select
                                                                    key={selectedRoutes.join(",")}  // Force re-render when value changes
                                                                    labelId="select-busstop-label"
                                                                    id="select-busstop"
                                                                    label="List of bus stops "
                                                                    value={selectedPublic[0]}
                                                                    onChange={handlePickPublic}
                                                                    style={{ paddingTop: '12px', paddingBottom: '12px', paddingRight: '7px' }}
                                                                >
                                                                    <MenuItem value={-1}>None</MenuItem>
                                                                    {
                                                                        oasthPaths.map((item, key) => {
                                                                            return <MenuItem key={key} value={item.bus_stop_id}>
                                                                                <span style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>{`${item.busstop}-${item.bus_stop_id}`}</span>
                                                                            </MenuItem>
                                                                        })
                                                                    }
                                                                </Select>
                                                            </FormControl>
                                                            <Icon onClick={handleOpen} fontSize="large" sx={{ color: "white" }} style={{ cursor: 'pointer', marginLeft: '10px', marginTop: '5px', marginBottom: "5px" }}>map</Icon>
                                                        </>
                                                    }
                                                </div>
                                            </Box>
                                            <Box sx={{ minWidth: 160, marginTop: '20px' }}>
                                                <div className='rawContainer'>
                                                    <FormControl >
                                                        <InputLabel id="select">Timeframe</InputLabel>
                                                        <Select
                                                            labelId="select-time"
                                                            id="select-time"
                                                            value={timeType}
                                                            label="Select timeframe type"
                                                            onChange={handleChangeTimeType}
                                                            style={{ paddingTop: '12px', paddingBottom: '12px', paddingLeft: '7px', paddingRight: '7px', width: '140px' }}
                                                        >
                                                            <MenuItem value={"1h"}>Last 1 hour</MenuItem>
                                                            <MenuItem value={"12h"}>Last 12 hours</MenuItem>
                                                            <MenuItem value={"24h"}>Last 24 hours</MenuItem>
                                                            <MenuItem value={"customH"}>Custom hours</MenuItem>
                                                            <MenuItem value={"customD"}>Custom dates</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                    {
                                                        timeType === "customD" &&
                                                        <Box sx={{ minWidth: 160, marginLeft: '10px' }}>
                                                            <div className='rawDPicker'>
                                                                <div className='fromtodp'>
                                                                    <MDInput
                                                                        type="datetime-local"
                                                                        name="from"
                                                                        value={customDates.from}
                                                                        onChange={handleCustomDates}
                                                                        label="Start date"
                                                                    />
                                                                </div>
                                                                <div className='fromtodp'>
                                                                    <MDInput
                                                                        type="datetime-local"
                                                                        name="to"
                                                                        value={customDates.to}
                                                                        onChange={handleCustomDates}
                                                                        label="End date"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </Box>
                                                    }
                                                    {
                                                        timeType === "customH" &&
                                                        <Box sx={{ minWidth: 160, marginLeft: '10px' }}>
                                                            <TextField
                                                                id="outlined-number"
                                                                label="Last hours"
                                                                type="number"
                                                                value={customHours}
                                                                onChange={(e) => (setCustomHours(parseInt(e.target.value)))}
                                                                style={{ width: '100px', height: '40px' }}
                                                            />
                                                        </Box>
                                                    }
                                                </div>
                                            </Box>
                                            {
                                                applyEnabled()
                                                    ? <MDButton variant="gradient" color="info" style={{ marginTop: '10px' }} onClick={apply}>Apply</MDButton>
                                                    : (location === "None" || (selectedDetectors[0] === -1 && selectedRoutes[0] === -1 && selectedSDetectors[0] === -1))
                                                        ? <MDButton variant="gradient" color="info" style={{ marginTop: '10px' }} disabled>Apply</MDButton>
                                                        : <div className='rawContainerCenteredV'>
                                                            <MDButton variant="gradient" color="info" style={{ marginTop: '10px' }} disabled>Apply</MDButton>
                                                            {
                                                                timeType === 'customH'
                                                                    ? <div className="errorBTNContainer"><span className='errortxtBTN'>The entered hours cannot be negative.</span><span className='errortxtBTN'> Please provide a valid value greater than zero.</span></div>
                                                                    : <div className="errorBTNContainer"><span className="errortxtBTN">The end date cannot be earlier than the start date.</span><span className="errortxtBTN">Please adjust the dates to ensure the end date is on or after the start date.</span></div>
                                                            }
                                                        </div>
                                            }
                                        </div>
                                    </div>
                                </MDBox>
                            </>
                            : <div className="centerdMargin"><CircularProgress /></div>
                    }
                </div>
            </Card>
            {
                !hideBody
                    ? returnBody()
                    : <></>
            }
            {dataSource === "detectors" && location === "Routes" && <SelectFromMap open={open} handleClose={handleClose} title={location} dataSubSource={dataSubSource} type={"route"} selected={selectedRoutes} setSelected={setSelectedRoutes} />}
            {dataSource === "detectors" && location === "Intersections" && <SelectFromMap open={open} handleClose={handleClose} title={location} dataSubSource={dataSubSource} type={"intersection"} selected={selectedDetectors} setSelected={setSelectedDetectors} />}
            {dataSource === "detectors" && location === "Detectors" && <SelectFromMap open={open} handleClose={handleClose} title={location} dataSubSource={dataSubSource} type={"detector"} selected={selectedSDetectors} setSelected={setSelectedSDetectors} />}
            {dataSubSource === "tomtomfcd" && location === "Routes" && <SelectFromMap open={open} handleClose={handleClose} title={location} dataSubSource={dataSubSource} type={"speedRoutesTomTom"} selected={selectedSpeedTravel} setSelected={setSelectedSpeedTravel} fetchedData={tomtomPaths} />}
            {(dataSubSource === "taxifcd" || dataSubSource === "bluetooth") && location === "Routes" && <SelectFromMap open={open} handleClose={handleClose} title={location} dataSubSource={dataSubSource} type={"speedRoutesBTTaxi"} selected={selectedSpeedTravel} setSelected={setSelectedSpeedTravel} fetchedData={bttaxiPaths} />}
            {dataSubSource === "oasth" && location === "Routes" && <SelectFromMap open={open} handleClose={handleClose} title={location} dataSubSource={dataSubSource} type={"oasthRoutes"} selected={selectedPublic} setSelected={setSelectedPublic} fetchedData={oasthPaths} />}

        </>


    )
}

export default Filters;

/*
                <div className='filterNtw'>
                    <MultiCascader data={output.spatial_filter_th_detectors}
                        block
                        columnWidth={220}
                        renderTreeNode={(label, node) => {
                            return (
                                <div>
                                    {label}
                                </div>
                            );
                        }}
                        renderColumn={(childNodes, { layer }) => {
                            return <Column header={headers[layer]}> {childNodes}</Column>;
                        }}
                        placeholder={
                            <span>
                                Select Locations
                            </span>
                        }
                        renderValue={(value, selectedItems, selectedElement) => (
                            <span>
                                <span style={{ color: '#575757' }}>
                                    Locations :
                                </span>{' '}
                                {selectedItems.map(item => item.label).join(' , ')}
                            </span>
                        )}
                    />
                    <MapSelective />
                </div>

*/