import { useEffect, useState } from "react";
import { CircularProgress, Grid } from "@mui/material";
import MDBox from "components/MDBox";

import TrafficList from "./TrafficList";
import TrafficBar from "./TrafficBar";

import useRouteData from "../data/useRouteData";
import TrafficMonitoringMap from "./trafficMonitoringMap/TrafficMonitoringMap";
import useTrafficMonitoringGeom from "../data/useTrafficMonitoringGeom";
import useEventsData from "layouts/events/show/data/useEventsData";

function RouteView() {
  const { data, loading } = useRouteData(); // Routes List
  const { events, loading: eventsLoading } = useEventsData(); // Events
  const { data: networkData, loading: networkLoading } = useTrafficMonitoringGeom(); // Network

  // Fix: Don't assume data is ready at first
  const [selectedData, setSelectedData] = useState(null);

  useEffect(() => {
    // Fix: Wait until `data` is available before setting `selectedData`
    if (!selectedData && data.length > 0) {
      setSelectedData(data[0]); // Set first route as default
      console.log("Default Route Selected:", data[0]);
    }
  }, [data]);

  // Compute `networkSource` directly without `useEffect`
  const networkSource = networkData?.features
    ? {
        type: "FeatureCollection",
        features: networkData.features.filter(
          (network) => network?.properties?.route_id === selectedData?.route_id
        ),
      }
    : null;

  // Compute active/jam events dynamically (no useEffect needed) I search for the selectedData.id
  const onlyActive = events?.filter(
    (event) =>
      event.check_status?.id === 1 &&
      (Array.isArray(event.link_id) ? event.link_id.includes(selectedData?.id) : event.link_id === selectedData?.id)
  ) || [];

  const onlyJam = onlyActive.filter((event) => event.category?.id === 10);



  if (loading || eventsLoading || networkLoading) {
    return (
      <Grid container justifyContent="center" alignItems="center" sx={{ height: "100%" }}>
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <Grid container spacing={2} sx={{ flexGrow: 1 }}>
      {/* Statistic List */}
      <Grid item xs={12} md={3}>
        <TrafficList data={selectedData?.metrics || []} />
        <TrafficList
          data={[
            { name: "No. of Events", values: [{ label: "Total", value: onlyActive.length, status: "neutral" }] },
            { name: "No. of Traffic Jams", values: [{ label: "Total", value: onlyJam.length, status: "neutral" }] },
          ]}
        />
      </Grid>

      {/* Map Display */}
      <Grid item xs={12} md={9}>
        <MDBox sx={{ height: "100%" }}>
          {/* Route Selection Bar */}
          <TrafficBar
            data={data}
            selectedData={selectedData}
            setSelectedData={setSelectedData}
          />
          {/* Pass dynamic data to the map */}
          <TrafficMonitoringMap 
            onlyActive={onlyActive} 
            networkSource={networkSource} 
            areaRectSource={[]} 
          />
        </MDBox>
      </Grid>
    </Grid>
  );
}

export default RouteView;
