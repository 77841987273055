import { useEffect, useState } from "react";
import { Divider, Modal } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { CheckTree } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import axios from "axios";
import config from "../../../../../../config";

const API_TOKEN = config.MAP_MATCHING_API_TOKEN;
const API_URL = config.MAP_MATCHING_API_URL

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 920,
  bgcolor: "#fff",
  boxShadow:
    "0rem 0.25rem 0.375rem -0.0625rem rgba(0, 0, 0, 0.1), 0rem 0.125rem 0.25rem -0.0625rem rgba(0, 0, 0, 0.06)",
  p: 4,
  border: "0 solid rgba(0, 0, 0, 0.125)",
  borderRadius: "0.75rem",
};

/**
 * Filtering logic for events based on selected filters
 */
function filterEvents(events, selectedFilters) {
  console.log("filterEvents selectedFilters", selectedFilters);

  // Normalize filters to lowercase for case-insensitive comparison
  const normalizedFilters = selectedFilters.map((filter) =>
    filter.toLowerCase().trim()
  );

  console.log("check filters", normalizedFilters);
  console.log("filterEvents Events", events);

  return events.filter((event) => {
    // Extract and normalize event properties
    const eventStatus = event.check_status?.toLowerCase().trim() || "";
    const eventType = event.type_of_event?.name?.toLowerCase().trim() || "";
    const eventCategory = event.category?.name?.toLowerCase().trim() || "";
    const eventSubcategory =
      event.subcategory?.name?.toLowerCase().trim() || "";

    console.log(`Checking event ${event.id}:`, {
      eventStatus,
      eventType,
      eventCategory,
      eventSubcategory,
    });

    // Match filters
    const check_statusMatch = normalizedFilters.includes(eventStatus);
    const eventTypeMatch = normalizedFilters.includes(eventType);
    const categoryMatch = normalizedFilters.includes(eventCategory);
    const subcategoryMatch = normalizedFilters.includes(eventSubcategory);

    console.log(
      `Event ${event.id} - Status Match: ${check_statusMatch}, Type Match: ${eventTypeMatch}, Category Match: ${categoryMatch}, Subcategory Match: ${subcategoryMatch}`
    );

    // Ensure all selected filters match
    return (categoryMatch || subcategoryMatch) && eventTypeMatch;
  });
}

const checkEventStatus = (validFrom, validUntil) => {
  // Current, Future, Past
  const currentDate = new Date();
  const startDate = new Date(validFrom);
  const endDate = validUntil ? new Date(validUntil) : null;

  if (endDate && endDate < currentDate) {
    return {
      id: 3,
      name: "Archived",
    };
  }

  if (startDate > currentDate) {
    return {
      id: 2,
      name: "Upcoming",
    };
  }
  return {
    id: 1,
    name: "Active",
  };
};

/**
 * Modal component for filtering events
 */
function FilterModal({
  displayedEvents,
  setDisplayedEvents,
  // originalEvents,
  onClose,
  onFiltersUpdate,
  initialSelectedFilters = ["check_status-1"], // Default filters
  selectedEvent,
  setSelectedEvent,
  filterTreeData,
}) {
  const [selectedFilters, setSelectedFilters] = useState(
    initialSelectedFilters
  );

  // Sync selectedFilters with initialSelectedFilters when the modal opens
  useEffect(() => {
    setSelectedFilters(initialSelectedFilters);
  }, [initialSelectedFilters]);

  /**
   * Apply filters and update displayed events
   */
  const handleConfirm = async () => {
    if (!selectedFilters || selectedFilters.length === 0) {
      console.log("No filters selected, showing all events.");
      // setDisplayedEvents(originalEvents);
      if (onFiltersUpdate) onFiltersUpdate([]);
      setSelectedEvent(null);
      onClose();
      return;
    }

    try {
      const queryFilters = {
        filters: selectedFilters,
      };

      const response = await axios.post(
        `${API_URL}/filtered-events`,
        queryFilters,
        {
          headers: {
            "X-API-Key": API_TOKEN,
            "Content-Type": "application/json",
          },
        }
      );


      setDisplayedEvents(response.data); // ✅ Now updates state correctly

      if (onFiltersUpdate) onFiltersUpdate(selectedFilters);
    } catch (error) {
      console.error("Error occurred:", error);
      if (error.response) {
        console.error("Response status:", error.response.status);
        console.error("Response data:", error.response.data);
      }
    }

    setSelectedEvent(null);
    onClose();
  };

  /**
   * Clear filters and restore original event list
   */
  const handleClearFilters = async () => {
    setSelectedFilters([]); // Ensure it's an empty array, not [""]

    setSelectedEvent(null);
    console.log(
      `clear filters, selected filters${selectedFilters}, selected event${selectedEvent}`
    );
  };

  return (
    <Modal open onClose={onClose}>
      <MDBox sx={modalStyle}>
        <MDTypography color="secondary" variant="h6">
          Filter Events
        </MDTypography>
        <Divider />
        <CheckTree
          data={filterTreeData}
          value={selectedFilters}
          onChange={(value) => setSelectedFilters(value)}
          defaultExpandAll
        />
        <Divider />

        <MDBox mt={3} display="flex" justifyContent="flex-end">
          <MDButton
            onClick={handleClearFilters}
            variant="outlined"
            color="warning"
          >
            Clear Filters
          </MDButton>
          <MDButton onClick={onClose} variant="text" color="primary">
            Cancel
          </MDButton>
          <MDButton onClick={handleConfirm}>Apply</MDButton>
        </MDBox>
      </MDBox>
    </Modal>
  );
}

export default FilterModal;
