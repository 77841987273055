

// @mui material components


// Otis Admin PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";


function Analytics() {

  return (
    <DashboardLayout>
      
    </DashboardLayout>
  );
}

export default Analytics;
