import React from 'react';
import { Line } from 'react-chartjs-2';
import zoomPlugin from 'chartjs-plugin-zoom';
import { Chart } from 'chart.js';

Chart.register(zoomPlugin);

const ChartTimeValue = ({ chartData, title }) => {
    const options = {
        responsive: true,
        plugins: {
          legend: {
            position: "top",
          },
          title: {
            display: true,
            text: title,
          },
          zoom: {
            pan: {
              enabled: true,
              mode: "x", // Allow only horizontal panning
            },
            zoom: {
              wheel: {
                enabled: true, // Enable zooming with scroll wheel
              },
              pinch: {
                enabled: true, // Enable pinch-to-zoom for touch devices
              },
              drag:{
                enabled: true,
              },
              mode: "x", // Allow zooming only on the X-axis
            }
          }
        },
        scales: {
          x: {
            type: "category", // Use the formatted dates as categories
            title: {
              display: true,
              text: "Timestamps",
            },
            ticks: {
                maxTicksLimit: 10, // Limits the number of labels shown on the x-axis
            },
          },
          y: {
            type: "linear",
            title: {
              display: true,
              text: "Completeness (%)",
            },
            beginAtZero: true,
          },
        }
    }
    return (
        <div>
            <Line data={chartData} options={options} />
        </div>
    );
};

export default ChartTimeValue;