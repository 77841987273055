import { useEffect, useRef, useState } from "react";
import Card from "@mui/material/Card";
import {
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
} from "@mui/material";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import config from "config";

import TrafficList from "../monitoring/components/TrafficList";

import { fetchJunctionsData } from "./data/fetchJunctionsData"; // Import the fetch function
import { fetchRcmData } from "./data/fetchRcmData";

mapboxgl.accessToken = config.MAPBOX_ACCESS_TOKEN_3D;

function IntersectionMonitoring() {
  const mapContainerRef = useRef(null);
  const mapRef = useRef(null);


  const [selectedDataType, setSelectedDataType] = useState("junctions"); // select data to show
  const [listIntersection, setListIntersection] = useState([]); // all intersection
  const [selectedIntersection, setSelectedIntersection] = useState(null); // Currently selected intersection
  const [mapData, setMapData] = useState({
    type: "FeatureCollection",
    features: [],
  });

  // Fetch and store junctions/rcm data
  useEffect(() => {
    const fetchData = async () => {
      try {
        setMapData({ type: "FeatureCollection", features: [] });

        let listData, intersectionData;

        if (selectedDataType === "junctions") {
          [listData, intersectionData] = await fetchJunctionsData();
        } else if (selectedDataType === "rcm") {
          [listData, intersectionData] = await fetchRcmData();
        }

        if (listData.length > 0) {
          setListIntersection(listData);
          setSelectedIntersection(listData[0]); // Set first item as selected
        }
        setMapData(
          intersectionData || { type: "FeatureCollection", features: [] }
        );
      } catch (error) {
        console.error("Error fetching data:", error);        
      }
    };

    fetchData();
  }, [selectedDataType]);

  //Initialize the map on first render & update when selectedIntersection changes
  useEffect(() => {
    if (!mapRef.current && selectedIntersection) {
      console.log("selectedIntersection", selectedIntersection);
      mapRef.current = new mapboxgl.Map({
        container: mapContainerRef.current,
        style: "mapbox://styles/psonis/cm7uavmzr00f501qu1ocmfmev",
        center: selectedIntersection.geom?.coordinates || [22.94893, 40.62635],
        zoom: selectedIntersection?.zoom || 18,
        pitch: selectedIntersection?.pitch || 0,
        minZoom: 14,
      });
    } else if (mapRef.current && selectedIntersection) {
      // Update map view when selectedIntersection changes
      mapRef.current.flyTo({
        center: selectedIntersection.geom?.coordinates || [22.94893, 40.62635],
        zoom: 18,
        minZoom: 14,
      });
    }
  }, [selectedIntersection]); // Run when selectedIntersection changes

  const flyToIntersection = (intersection) => {
    if (!mapRef.current || !intersection?.geom) return;

    setTimeout(() => {
      mapRef.current.flyTo({
        center: intersection.geom.coordinates,
        zoom: 18,
      });
    }, 1500);
  };

  const handleClearMap = () => {
    console.log("Call handleClearMap method");
    const map = mapRef.current;
    if (!map) {
      console.warn("No map instance available to clear.");
      return;
    }

    // Remove layers
    ["solid-line", "line-dashed", "point-text-labels"].forEach((id) => {
      if (map.getLayer(id)) {
        map.removeLayer(id);
        console.log(`Removed layer: ${id}`);
      }
    });

    // Remove source
    if (map.getSource("multi-data")) {
      map.removeSource("multi-data");
      console.log("Removed source: multi-data");
    } else {
      console.log(" No source found with id: multi-data");
    }
  };

  // Update map layers when mapData changes
  useEffect(() => {
    const map = mapRef.current;
    if (!map || mapData.features.length === 0) return;

    // Clear old layers/sources
    handleClearMap();

    const updateMap = () => {
      // Add new source
      map.addSource("multi-data", {
        type: "geojson",
        data: mapData,
      });

      // Add layers
      map.addLayer({
        id: "solid-line",
        type: "line",
        source: "multi-data",
        filter: ["==", "$type", "LineString"],
        layout: {},
        paint: {
          "line-color": ["get", "color"],
          "line-opacity": 0.4,
          "line-emissive-strength": 1.0,
          "line-width": 6,
        },
      });

      map.addLayer({
        type: "line",
        source: "multi-data",
        id: "line-dashed",
        filter: ["==", "$type", "LineString"],
        layout: {},
        paint: {
          "line-color": ["get", "color"],
          "line-width": 6,
          "line-emissive-strength": 1.0,
          "line-dasharray": [0, 4, 3],
        },
      });

      map.addLayer({
        id: "point-text-labels",
        type: "symbol",
        source: "multi-data",
        filter: ["==", "$type", "Point"],
        layout: {
          "text-field": ["get", "value"],
          "text-size": ["interpolate", ["linear"], ["zoom"], 10, 12, 18, 16],
          "text-anchor": "center",
          "text-offset": [0, 0],
          "text-font": ["Open Sans Bold"],
          "text-translate-anchor": "map",
        },
        paint: {
          "text-color": ["get", "color"],
          "text-halo-color": "#000000",
          "text-halo-width": 1.5,
        },
      });

      // Animate dashed line
      const dashArraySequence = [
        [0, 4, 3],
        [0.5, 4, 2.5],
        [1, 4, 2],
        [1.5, 4, 1.5],
        [2, 4, 1],
        [2.5, 4, 0.5],
        [3, 4, 0],
        [0, 0.5, 3, 3.5],
        [0, 1, 3, 3],
        [0, 1.5, 3, 2.5],
        [0, 2, 3, 2],
        [0, 2.5, 3, 1.5],
        [0, 3, 3, 1],
        [0, 3.5, 3, 0.5],
      ];

      let step = 0;

      function animate(timestamp) {
        const newStep = parseInt((timestamp / 50) % dashArraySequence.length);
        if (newStep !== step && map.getLayer("line-dashed")) {
          map.setPaintProperty(
            "line-dashed",
            "line-dasharray",
            dashArraySequence[step]
          );
          step = newStep;
        }
        requestAnimationFrame(animate);
      }
      animate(0);
    };

    if (map.isStyleLoaded()) {
      updateMap();
    } else {
      map.once("style.load", updateMap);
    }
  }, [mapData]);

  // handle change select data type
  const handleSelectDataTypeChanged = (event) => {
    const newType = event.target.value;
    console.log(" Changed selectedDataType to:", newType);
    setSelectedDataType(newType); // This triggers useEffect for fetching data
  };

  // // Actions on map
  // useEffect(() => {
  //   if (!mapRef.current) return;

  //   function logMapState() {
  //     const zoom = mapRef.current.getZoom();
  //     const pitch = mapRef.current.getPitch();
  //     const center = mapRef.current.getCenter(); // Get map center coordinates

  //     console.log(`Zoom Level: ${zoom}`);
  //     console.log(`Pitch: ${pitch}`);
  //     console.log(`Coordinates: [${center.lng}, ${center.lat}]`); // Log updated center
  //   }

  //   // Listen for movement, zoom, and rotation changes
  //   mapRef.current.on("move", logMapState);
  //   mapRef.current.on("zoom", logMapState);
  //   mapRef.current.on("rotate", logMapState);

  //   return () => {
  //     // Cleanup event listeners when component unmounts
  //     mapRef.current.off("move", logMapState);
  //     mapRef.current.off("zoom", logMapState);
  //     mapRef.current.off("rotate", logMapState);
  //   };
  // }, []);

  // Traffic Statistics Data

  // handel change intersetion
  const handleSelectedIntersectionChanged = (event) => {
    // find intersection from list to fly on that point
    const intersection = listIntersection.find(
      (item) => item.match_id === event.target.value
    );

    if (intersection) {
      console.log("✅ Selected Intersection:", intersection);
      setSelectedIntersection(intersection);
      flyToIntersection(intersection); // Move map immediately
    }
  };

  const dataOfJams = selectedIntersection
    ? [
        {
          name: `${selectedIntersection?.name_en || ""}`,
          values: [
            {
              label: "Current",
              value: selectedIntersection?.current || "",
              status: "neutral",
            },
            {
              label: "Typical",
              value: selectedIntersection?.typical || "",
              status: "neutral",
            },
            {
              label: "Forecasted",
              value: selectedIntersection?.forecasted || "",
              status: "neutral",
            },
          ],
        },
      ]
    : [];

  // if (loading) {
  //   return (
  //     <DashboardLayout>
  //       <MDBox
  //         display="flex"
  //         justifyContent="center"
  //         alignItems="center"
  //         height="80vh"
  //       >
  //         <CircularProgress size={50} color="primary" />
  //       </MDBox>
  //     </DashboardLayout>
  //   );
  // }

  return (
    <DashboardLayout>
      <Card>
        <MDBox p={3} lineHeight={1}>
          <MDTypography variant="h5" fontWeight="medium">
            Intersection
          </MDTypography>
          <MDTypography variant="button" color="text"></MDTypography>
        </MDBox>
      </Card>
      {/* Map */}

      <Card sx={{ marginTop: "1rem", padding: "1rem" }}>
        <MDBox>
          <Grid container spacing={2} sx={{ flexGrow: 1 }}>
            {/* Statistic List */}
            <Grid item xs={12} md={3}>
              {/* Render all staistics */}
              <TrafficList data={dataOfJams} />
            </Grid>

            {/* Map Display */}
            <Grid item xs={12} md={9} sx={{ position: "relative" }}>
              {/* Select Intersection */}

              <MDBox sx={{ height: "100%" }}>
                <div
                  id="map"
                  ref={mapContainerRef}
                  style={{ width: "100%", height: "84vh" }}
                ></div>

                {/* Select Intersection - Positioned at Bottom Left */}
                <MDBox
                  sx={{
                    width: "200px",
                    position: "absolute",
                    top: 25,
                    right: 10,
                    backgroundColor: "rgba(32, 41, 64,1)",
                    padding: "1rem",
                    borderRadius: "5px",
                  }}
                >
                  {/* select data to show */}
                  <FormControl
                    sx={{
                      width: "100%",
                    }}
                  >
                    <Select
                      sx={{ padding: "5px 0" }}
                      value={selectedDataType}
                      onChange={handleSelectDataTypeChanged}
                    >
                      <MenuItem value="junctions">Junctions</MenuItem>
                      <MenuItem value="rcm">Rcm</MenuItem>
                    </Select>
                  </FormControl>

                  <FormControl
                    sx={{
                      width: "100%",
                      marginTop: "10px",
                    }}
                  >
                    <Select
                      sx={{ padding: "5px 0" }}
                      value={
                        selectedIntersection
                          ? selectedIntersection.match_id
                          : ""
                      }
                      onChange={handleSelectedIntersectionChanged}
                      displayEmpty
                    >
                      {listIntersection.length > 0 ? (
                        listIntersection.map((item) => (
                          <MenuItem key={item.match_id} value={item.match_id}>
                            {item.name_en || `Intersection ${item.match_id}`}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem disabled value="">
                          No intersections available
                        </MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </MDBox>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </Card>
    </DashboardLayout>
  );
}

export default IntersectionMonitoring;
