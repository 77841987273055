import { useEffect, useState } from "react";

// Otis Admin PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Grid from "@mui/material/Grid";
import BookingCard from "examples/Cards/BookingCard";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
// Images
import trans1 from "assets/images/Synchro/trans1.jpeg";
import trans2 from "assets/images/Synchro/trans2.jpeg";
import trans3 from "assets/images/Synchro/trans3.jpeg";


function LandingLayout() {
  const navigate = useNavigate();

  const handleOpen = () => {
    // console.log("Navigating to MapCreateEvent with formData:", formData);
    navigate("/dashboards/tools");
  };

    //const [dropItems, setDropItems] = useState(null); // State for selected event
    // useEffect(() => {    
    //     setDropItems(filter.dataSourceMapping);
    // }, []);
    
    return (
        <DashboardLayout>
            <Card>
                <MDBox p={3} lineHeight={1} textAlign="center">
                    <MDTypography variant="h1" fontWeight="large">
                        Syncromode
                    </MDTypography>
                </MDBox>
            </Card>
            <Grid container spacing={3} marginTop={"20px"}>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mt={3}>
                <BookingCard
                  image={trans1}
                  title="Tool 1"
                  description='Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. I'
                  price=""
                  location="Madrid, Spain"
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mt={3}>
                <BookingCard
                  image={trans2}
                  title="Tool 2"
                  description="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. I"
                  price=""
                  location="South Holland, Netherlands"
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mt={3}>
                <BookingCard
                  image={trans3}
                  title="Tool 3"
                  description="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. I"
                  price=""
                  location="Thessaloniki, Greece"
                />
              </MDBox>
            </Grid>
          </Grid>
          <Grid container spacing={3} marginTop={"20px"}>
          <Grid item xs={12} md={6} lg={4}>
          <MDBox mt={3}>
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
          <MDBox mt={3} display="flex" justifyContent="center"> 
          <MDButton variant="contained" color="info" onClick={handleOpen}>login
          </MDButton>
            
             </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
          <MDBox mt={3}></MDBox>
          </Grid>
                      
            </Grid>
        </DashboardLayout>
    );
}

export default LandingLayout;