import React, { useEffect, useState, useRef } from "react";
import Keycloak from "keycloak-js";
import { isTokenExpiringSoon } from "./authModules";
import SignInBasic from "../layouts/authentication/sign-in/basic"; // Import custom login screen
import config from "../config";

const KEYCLOAK_URL = config.KEYCLOAK_URL;
const KEYCLOAK_REALM = config.KEYCLOAK_REALM;
const KEYCLOAK_CLIENT_ID = config.KEYCLOAK_CLIENT_ID;

const keycloakConfig = {
  url: KEYCLOAK_URL,
  realm: KEYCLOAK_REALM,
  clientId: KEYCLOAK_CLIENT_ID,
};

const AuthWrapper = ({ children }) => {
  const [keycloak, setKeycloak] = useState(null);
  const keycloakRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const storedToken = localStorage.getItem("keycloak-token");
    if (storedToken) {
      const tokenExp = getTokenExpiration(storedToken);
      if (tokenExp && Date.now() / 1000 < tokenExp) {
        setIsAuthenticated(true);
        return;
      }
    }

    const keycloakInstance = new Keycloak(keycloakConfig);
    keycloakRef.current = keycloakInstance;
    setKeycloak(keycloakInstance);

    keycloakInstance.init({ onLoad: "check-sso", checkLoginIframe: false })
      .then((auth) => {
        setIsAuthenticated(auth);
        if (auth && keycloakInstance.token) {
          localStorage.setItem("keycloak-token", keycloakInstance.token);
        }
      })
      .catch((error) => {
        console.error("Keycloak authentication error", error);
      });

    setLoading(false);
  }, []);

  const getTokenExpiration = (token) => {
    try {
      const decodedToken = JSON.parse(atob(token.split(".")[1]));
      return decodedToken.exp;
    } catch (error) {
      console.error("Error decoding token:", error);
      return null;
    }
  };


  if (!isAuthenticated) {
    return <SignInBasic />;
  }

  return <>{children}</>;
};

export default AuthWrapper;